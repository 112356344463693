import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  position: absolute;
  bottom: 0px;
  overflow: hidden;

  .other-reaction-container {
    height: 26px;
    min-width: 26px;
    background-color: ${(props) => props.theme.colors.geekblue1};
    border: 1px solid ${(props) => props.theme.colors.gray5};
    font-size: 16px;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    padding: 4px;

    .reaction-emoji {
      width: 18px;
      height: 18px;
      image-rendering: auto;
      pointer-events: initial;
    }
  }

  .user-reactions-container {
    height: 34px;
    width: 14px;
    background-color: ${(props) => props.theme.colors.geekblue1};
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 0px 10px;
    transition: all 0.15s;

    &.has-reaction {
      border-color: ${(props) => props.theme.colors.geekblue5};
    }

    .activity-reaction {
      width: 18px;
      height: 18px;
      font-size: 12px;
      text-align: center;
      filter: grayscale(1);
      display: none;
      align-items: center;
      justify-content: center;
      transition: all 0.3s;
      cursor: pointer;

      .reaction-emoji {
        width: 14px;
        height: 14px;
        image-rendering: auto;
      }

      &.selected {
        display: flex;
        filter: none;
        transform: scale(1.3);
      }

      &:hover {
        display: flex;
        filter: none;
        transform: scale(1.3);
      }
    }

    .default-reaction {
      color: ${(props) => props.theme.colors.gray6};
      min-width: 20px;
      height: 20px;
      opacity: 0.8;
      cursor: pointer;
    }

    .close-button {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 12px;
      display: none;
      transition: all 0.3s;

      &:hover {
        transform: translateX(3px);
      }
    }

    &.is-reacting {
      width: auto;
      border-color: ${(props) => props.theme.colors.geekblue5};

      .activity-reaction {
        display: flex;
      }

      .default-reaction {
        display: none;
      }

      .close-button {
        display: block;
      }
    }
  }

  @media ${device.mdDown} {
    bottom: 20px;
    left: 20px;
  }
`
