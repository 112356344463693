import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;

  > .loading-content {
    padding: 30px 28px;
  }

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 10px;
    padding: 30px 28px;

    > .header {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;

      .title-container {
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 0px;

        .title {
          font-size: 16px;
          margin: 0px;
        }
      }

      .more-licenses-box {
        .text {
          font-size: 12px;
          line-height: 16px;
          margin: 0px;

          .small {
            font-size: 11px;
          }
        }
      }
    }

    .license-usage-info {
      background-color: ${(props) => props.theme.colors.gray2};
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 16px;
      border-radius: 8px;
      padding: 10px 14px;

      .title-container {
        display: flex;
        align-items: center;
        gap: 7px;

        .title {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 400;
          margin: 0px;
        }

        .icon svg {
          height: 20px;
          width: 20px;
          color: ${(props) => props.theme.colors.gray7};
        }
      }

      .value {
        margin: 0px;
      }
    }

    .license-info {
      background: linear-gradient(90deg, #222249 0%, #4d6ddf 47.5%, #afd9d8 100%);
      border-radius: 8px;
      padding: 10px 14px;

      .text {
        color: ${(props) => props.theme.colors.gray1};
        display: flex;
        align-items: center;
        gap: 10px;
        margin: 0px;

        .pro-badge {
          width: 20px;
        }

        .small {
          font-size: 12px;

          > a {
            color: ${(props) => props.theme.colors.gray1};
            text-decoration: underline;
          }
        }
      }
    }
  }
`
