import { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { Avatar, Rate, Dropdown, Tag, Skeleton } from 'antd'
import { UserOutlined, LineChartOutlined, CopyOutlined } from '@ant-design/icons'
import { MoreVertical24Filled, Share24Regular, PersonArrowRight24Regular } from '@fluentui/react-icons'
import { getPlatformURL, domain, shortEnv } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import { capitalize } from '@/utils/functions'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import LearnIcon from '@/assets/images/learn.svg?react'
import { setAssignModalOpen } from '@/store/modules/actions'
import { Container, Content, ExtraContent, Modal } from './styles'

const ModuleBox = ({
  module = {},
  size,
  border,
  showSkillTrack,
  disableActions,
  disableDetailModal,
  onClick,
  onClickText,
  isLoading,
}) => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  const { currentAccount } = useSelector((state) => state.accounts)

  const [isModalVisible, setModalIsVisible] = useState(false)

  const isAssessment = module?.module_type === 'assessment'
  const contentUrl = `${getPlatformURL(currentAccount?.slug)}${isAssessment ? 'assessment' : 'project'}/${module?.id}`

  const selfLaunchIsEnabled = currentAccount?.config?.launch?.self_launch
  // const isLTIEnabled = true

  const displayModuleMainActions = () => {
    return (
      <div className="actions">
        {!disableActions && (
          <Button
            icon={<PersonArrowRight24Regular />}
            onClick={() => {
              setModalIsVisible(false)
              dispatch(setAssignModalOpen(module))
            }}
          >
            Assign
          </Button>
        )}

        {!disableActions && (
          <Link to={`/accounts/${accountId}/analytics?tab=projects&project=${module?.id}`}>
            <Button icon={<LineChartOutlined />}>Analytics</Button>
          </Link>
        )}

        <Link to={contentUrl} target="_blank" onClick={() => setModalIsVisible(false)}>
          <Button type={onClick ? 'default' : 'primary'}>Go to {isAssessment ? 'assessment' : 'project'}</Button>
        </Link>

        {onClick && onClickText && (
          <Button
            type="primary"
            onClick={() => {
              onClick(module)
              setModalIsVisible(false)
            }}
          >
            {onClickText}
          </Button>
        )}
      </div>
    )
  }

  const renderTags = () => {
    return (
      <div className="tags-container">
        {module?.module_type === 'learn' && (
          <Tag className={`tag module-type`}>
            <LearnIcon />
            Learn
          </Tag>
        )}

        {isAssessment ? (
          <Tag className="tag">Assessment</Tag>
        ) : (
          <Tag className={`tag difficulty-${module?.difficulty}`}>{module?.difficulty}</Tag>
        )}
      </div>
    )
  }

  const menuItems = [
    {
      key: 'assign',
      label: (
        <a onClick={() => dispatch(setAssignModalOpen(module))} className="menu-item">
          <PersonArrowRight24Regular /> Assign
        </a>
      ),
    },
    {
      key: 'analytics',
      label: (
        <Link to={`/accounts/${accountId}/analytics?tab=projects&project=${module?.id}`} className="menu-item">
          <LineChartOutlined /> View Analytics
        </Link>
      ),
    },
    {
      key: 'copy-id',
      label: (
        <span
          className="menu-item"
          onClick={() => {
            navigator.clipboard.writeText(module?.id)
            showToast('Project ID copied to clipboard')
          }}
        >
          <CopyOutlined /> Copy {isAssessment ? 'assessment' : 'project'} ID
        </span>
      ),
    },
    ...(selfLaunchIsEnabled
      ? [
          {
            key: 'share',
            label: (
              <a
                onClick={() => {
                  navigator?.clipboard?.writeText(contentUrl)
                  showToast('Link copied to clipboard')
                }}
                className="menu-item"
              >
                <Share24Regular /> Copy {isAssessment ? 'assessment' : 'project'} link
              </a>
            ),
          },
        ]
      : []),
    // ...(isLTIEnabled
    //   ? [
    //       {
    //         key: 'copy-lti-url',
    //         label: (
    //           <span
    //             className="menu-item"
    //             onClick={() => {
    //               let url = `https://api.${shortEnv}.${domain}/lti/launch/?content_type=project&content_id=${module?.id}`
    //               navigator?.clipboard?.writeText(url)
    //               showToast('Project LTI URL copied to clipboard')
    //             }}
    //           >
    //             <CopyOutlined /> Copy LTI URL
    //           </span>
    //         ),
    //       },
    //     ]
    //   : []),
  ]

  if (isLoading || !module?.name)
    return (
      <Container className="is-loading" $size={size} $border={border}>
        <Content className="main-content">
          <Skeleton className="left-side" active avatar title={false} paragraph={{ rows: 0 }} />
          <Skeleton className="right-side" active title={false} paragraph={{ rows: size === 'small' ? 1 : 2 }} />
        </Content>
      </Container>
    )

  return (
    <>
      <Container
        className="module-box"
        $size={size}
        $border={border}
        $hasCustomOnClick={!!onClick}
        onClick={onClick ? () => setModalIsVisible(true) : null}
      >
        <FadeIn>
          <Content
            className="main-content"
            $size={size}
            $hasCustomOnClick={!!onClick}
            $disableDetailModal={disableDetailModal}
          >
            {renderModuleImage(module)}

            <div className="module-info">
              <div className="title-container">
                <h5
                  className="module-title"
                  onClick={onClick || disableDetailModal ? null : () => setModalIsVisible(true)}
                >
                  {module?.name}
                </h5>
              </div>

              <div className="info-box">{renderTags()}</div>
            </div>

            <div className="extra-container">
              {!disableActions && (
                <Dropdown
                  overlayClassName={'dropdown-menu'}
                  trigger={['click']}
                  menu={{
                    items: menuItems,
                    onClick: ({ domEvent }) => domEvent.stopPropagation(),
                  }}
                  onClick={(e) => e.stopPropagation()}
                >
                  <Button className="open-options-button" type="text" shape="circle" icon={<MoreVertical24Filled />} />
                </Dropdown>
              )}
            </div>
          </Content>
        </FadeIn>
      </Container>

      <Modal title={null} open={isModalVisible} onCancel={() => setModalIsVisible(false)} footer={null}>
        <Content className="main-content modal">
          {renderModuleImage(module)}

          <div className="module-info">
            {showSkillTrack && (
              <div className="pre-title">
                <span className="la-bullet" style={{ backgroundColor: module?.learning_area?.color }} />

                <Link
                  to={`${getPlatformURL(currentAccount?.slug)}skill-track/${module?.skill_track?.id}`}
                  target="_blank"
                >
                  <p className="text">{module?.skill_track?.name}</p>
                </Link>
              </div>
            )}

            <h4 className="module-title">{module?.name}</h4>

            {renderTags(true)}

            <div className="rating">
              <Rate disabled allowHalf defaultValue={module?.rating?.avg} />{' '}
              {module?.rating?.avg ? (
                Math.round(module?.rating?.avg * 100) / 100
              ) : (
                <span className="subtext">Not enough data</span>
              )}
            </div>
          </div>
        </Content>

        <ExtraContent>
          <p className="module-description">{module?.description}</p>

          {!!module?.tags?.length && (
            <div className="tags-container">
              {module?.tags?.map((t) => (
                <Tag key={t} className="module-tag">
                  {t}
                </Tag>
              ))}
            </div>
          )}

          <div className="module-bottom">
            <div className="module-author">
              <Avatar src={module?.author?.avatar_url} icon={<UserOutlined />} />

              <p className="name">
                {module?.author?.first_name
                  ? `${capitalize(module?.author?.first_name)} ${capitalize(module?.author?.last_name)}`
                  : 'Anonymous'}
              </p>
            </div>

            {displayModuleMainActions()}
          </div>
        </ExtraContent>
      </Modal>
    </>
  )
}

export default ModuleBox
