import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import CustomProjectsEmptyState from '@/assets/images/empty-state-custom-projects.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;

  .empty-state {
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};

    .empty-state-content {
      background: url(${CustomProjectsEmptyState}) no-repeat center center;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 20px;
      padding: 0px 60px;
      animation: 1s ${fadeInAnimation};

      > .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-end;
        text-align: center;
        flex: 1 0 50%;
        padding: 24px 36px;

        .container {
          .icon {
            font-size: 24px;
            color: ${(props) => props.theme.colors.geekblue5};
            margin-bottom: 10px;
          }

          .title {
            margin: 0px;
            line-height: 28px;
            margin-bottom: 0px;
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            font-weight: 400;
            margin: 6px 0px 0px;
          }

          > button {
            margin-top: 16px;
          }
        }
      }

      .teams-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        gap: 16px;
        flex: 1 0 50%;
        padding: 24px;

        > .row {
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 22px;
        }

        .team-card {
          background-color: ${(props) => props.theme.colors.gray1};
          border-radius: 10px;
          padding: 16px;
        }
      }
    }
  }

  > .header {
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto hidden;
    padding: 12px 24px;

    .search-container {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      flex: 1 1 100%;

      .user-search {
        min-width: 200px;
        max-width: 300px;
        width: 100%;

        .ant-input-search-button {
          cursor: default;

          &:hover {
            border-color: ${(props) => props.theme.colors.gray5};
          }

          > div {
            display: none;
          }
        }
      }
    }

    .status-filter  {
    }
  }

  > .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;
    margin: 0px;

    .empty-state-container {
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: auto;
      animation: 1s ${fadeInAnimation};

      .empty-state-content {
        height: 100%;
        width: 100%;
        background: url(${CustomProjectsEmptyState}) no-repeat center center;

        > .header {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 24px 36px;

          .container {
            max-width: 240px;
            display: flex;
            flex-direction: column;
            align-items: center;

            .title-container {
              max-width: 230px;
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;

              .icon {
                min-width: 24px;
                font-size: 24px;
                color: ${(props) => props.theme.colors.geekblue5};
              }

              .title {
                margin: 0px 0px 12px;
              }
            }

            .text {
              color: ${(props) => props.theme.colors.gray7};
              margin: 0px 0px 20px;
            }

            .link {
              color: ${(props) => props.theme.colors.gray7};
              text-decoration: underline;
            }

            > button {
              margin-top: 18px;
            }
          }
        }
      }
    }

    > .content {
      max-height: calc(100% - 72px);
      max-width: 1440px;
      width: 100%;
      margin: 0px;

      .collections-container {
        display: flex;
        flex-wrap: wrap;
        justify-content: flex-start;
        gap: 4px;

        > .collection-box-container {
          width: 100%;
        }

        .collections-content {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 20px;
          margin-bottom: 60px;

          > div > .ant-collapse-header {
            .ant-collapse-expand-icon svg {
              height: 18px;
              width: 18px;
            }

            .title {
              margin: 0px;
            }
          }

          .collection-container {
            background-color: ${(props) => props.theme.colors.gray1};
            border: 1px solid ${(props) => props.theme.colors.gray5};
            border-radius: 0px;

            .ant-collapse-content-box {
              padding: 0px;
            }

            .collection-content {
              .section-container {
                .ant-collapse-item {
                  .ant-collapse-header {
                    background-color: ${(props) => props.theme.colors.gray2};
                    border-top: 1px solid ${(props) => props.theme.colors.gray5};
                    border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
                    border-radius: 0px;
                    margin-bottom: -1px;

                    .ant-collapse-expand-icon svg {
                      height: 12px;
                      width: 12px;
                    }

                    .level-title {
                      color: ${(props) => props.theme.colors.gray10};
                      margin: 0px;
                    }
                  }

                  &.ant-collapse-item-active {
                    margin-bottom: 0px;
                  }
                }

                .ant-collapse-content-box {
                  padding: 12px;

                  .content-container {
                    border-bottom: 1px solid ${(props) => props.theme.colors.gray4};

                    &:last-child {
                      border-bottom: none;
                    }
                  }
                }
              }
            }
          }
        }
      }

      .no-results-box {
        height: 382px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        padding: 20px;
        margin-top: 10px;

        & > img {
          user-select: none;
          pointer-events: none;
        }

        .title {
          margin-bottom: 6px;
        }

        .text {
          color: ${(props) => props.theme.colors.gray7};
          font-weight: 500;
        }
      }

      .pagination-container {
        width: 100%;
        bottom: 0px;
        z-index: 1;
        margin-top: 40px;
      }
    }
  }

  @media ${device.mdDown} {
    padding: 0px;
  }
`
