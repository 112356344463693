import { useState, useEffect, useRef } from 'react'
import { Tooltip } from 'antd'
import { Info24Regular } from '@fluentui/react-icons'
import {
  MDXEditor,
  // toolbar components
  Separator,
  UndoRedo,
  BlockTypeSelect,
  BoldItalicUnderlineToggles,
  CreateLink,
  ListsToggle,
  InsertTable,
  CodeToggle,
  InsertCodeBlock,
  InsertThematicBreak,
  // plugins
  toolbarPlugin,
  headingsPlugin,
  listsPlugin,
  quotePlugin,
  thematicBreakPlugin,
  markdownShortcutPlugin,
  linkPlugin,
  linkDialogPlugin,
  tablePlugin,
  codeBlockPlugin,
  codeMirrorPlugin,
  // directivesPlugin,
} from '@mdxeditor/editor'
import { Container } from './styles'

import '@mdxeditor/editor/style.css'

const MarkdownEditor = ({
  className = '',
  label = '',
  content = '',
  placeholder = 'Write something...',
  info = null,
  isToolbarFixed = false,
  readOnly = false,
  onChange,
}) => {
  const editorRef = useRef(null)

  const [isFocused, setIsFocused] = useState(false)

  useEffect(() => {
    editorRef.current?.setMarkdown(content)
  }, [content])

  return (
    <Container
      className={`editor-container ${className}`}
      $hasContent={content !== ''}
      $hasInfo={!!info}
      $isToolbarFixed={isToolbarFixed}
      $readOnly={readOnly}
      $isFocused={isFocused}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
    >
      <div className="header">
        {label && <p className="label">{label}</p>}

        {info && (
          <Tooltip title={info}>
            <Info24Regular className="info-icon" />
          </Tooltip>
        )}
      </div>

      <MDXEditor
        ref={editorRef}
        plugins={[
          toolbarPlugin({
            toolbarClassName: 'toolbar',
            toolbarContents: () => (
              <>
                <UndoRedo />
                <Separator />
                <BlockTypeSelect />
                <BoldItalicUnderlineToggles />
                <CreateLink />
                <Separator />
                <CodeToggle />
                <InsertCodeBlock />
                <ListsToggle />
                <InsertTable />
                <InsertThematicBreak />
              </>
            ),
          }),
          headingsPlugin(),
          listsPlugin(),
          quotePlugin(),
          thematicBreakPlugin(),
          markdownShortcutPlugin(),
          linkPlugin(),
          linkDialogPlugin(),
          tablePlugin(),
          codeBlockPlugin({ defaultCodeBlockLanguage: 'python' }),
          codeMirrorPlugin({
            codeBlockLanguages: {
              python: 'Python',
              sql: 'SQL',
              r: 'R',
              julia: 'Julia',
              sh: 'Shell',
              json: 'JSON',
            },
          }),
          // directivesPlugin({
          //   directiveDescriptors: [AdmonitionDirectiveDescriptor],
          // }),
        ]}
        markdown={content}
        placeholder={placeholder}
        readOnly={readOnly}
        onChange={onChange}
      />
    </Container>
  )
}

export default MarkdownEditor
