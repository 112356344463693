import axios from 'axios'
import { apiURL } from '@/helpers/env'
import { postLogoutUser } from '@/services/users'

const API = axios.create({
  baseURL: apiURL,
  withCredentials: true,
  headers: {
    common: {
      'Content-Type': 'application/json',
    },
  },
  emulateJSON: true,
})

API.interceptors.response.use(
  (response) => {
    return response
  },
  async (error) => {
    if (error.response) {
      const skipRedirectUrls = [
        'users/signup',
        'users/login',
        'users/auth/google-oauth2',
        'users/me',
        'subscriptions/me',
      ]
      if ([401, 403].includes(error.response.status) && !skipRedirectUrls.includes(error.config.url)) {
        return window.location.replace(`/not-found`)
      }
    }

    return Promise.reject(error)
  },
)

export default API
