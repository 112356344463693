import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Menu, Tooltip } from 'antd'
import { HomeOutlined, UserOutlined, UsergroupAddOutlined, ProductOutlined, LineChartOutlined } from '@ant-design/icons'
import { ChannelArrowLeft24Regular, Book24Regular } from '@fluentui/react-icons'
import { isDataWarsHostName } from '@/helpers/env'
import { isDown } from '@/themes/breakpoints'
import Layout1 from '@/layouts/Layout1'
import GlobalLoader from '@/components/GlobalLoader'
import AssignModal from '@/components/AssignModal'
import EditAccountModal from '@/pages/AccountsPage/components/EditAccountModal'
import DashboardTab from './components/DashboardTab'
import MembersTab from './components/MembersTab'
import TeamsTab from './components/TeamsTab'
import CoursesTab from './components/CoursesTab'
import ContentTab from './components/ContentTab'
import AnalyticsTab from './components/AnalyticsTab'
import WelcomeModal from './components/WelcomeModal'
import TeamModal from './components/TeamModal'
import AddToTeamModal from './components/AddToTeamModal'
import InviteModal from './components/InviteModal'
import CustomModuleModal from './components/CustomModuleModal'
import ModuleDetailModal from './components/ModuleDetailModal'
import PlaygroundDetailModal from './components/PlaygroundDetailModal'
import { fetchAccount, fetchAccountUsers, setEditAccountModalOpen, setWelcomeModalOpen } from '@/store/accounts/actions'
import { fetchSubscriptionsMe } from '@/store/subscriptions/actions'
import { Container, MenuContainer } from './styles'

const MainPage = () => {
  const dispatch = useDispatch()
  const { accountId, tab } = useParams()
  const navigate = useNavigate()

  const { theme } = useSelector((state) => state.app)
  const { isAuthenticated, userProfile } = useSelector((state) => state.users)
  const { currentAccount, accountError } = useSelector((state) => state.accounts)

  const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')
  const isAccountOwner = userProfile?.accounts?.some((a) => a?.role === 'owner')

  const enableCollections = currentAccount?.config?.browse?.collections

  const items = [
    {
      label: 'Dashboard',
      key: 'dashboard',
      icon: <HomeOutlined />,
      title: '',
    },
    {
      label: 'Members',
      key: 'members',
      icon: <UserOutlined />,
      title: '',
    },
    {
      label: 'Teams',
      key: 'teams',
      icon: <UsergroupAddOutlined />,
      title: '',
    },
    ...(enableCollections
      ? [
          {
            label: 'Courses',
            key: 'courses',
            icon: <Book24Regular />,
            title: '',
          },
        ]
      : []),
    { label: 'Content', key: 'content', icon: <ProductOutlined />, title: '' },
    { label: 'Analytics', key: 'analytics', icon: <LineChartOutlined />, title: '' },
  ]

  const renderContent = (tab) => {
    if (tab === 'dashboard') return <DashboardTab />
    if (tab === 'members') return <MembersTab />
    if (tab === 'teams') return <TeamsTab />
    if (enableCollections && tab === 'courses') return <CoursesTab />
    if (tab === 'content') return <ContentTab />
    if (tab === 'analytics') return <AnalyticsTab />

    return <DashboardTab />
  }

  const handleOpenEditAccountModal = () => {
    dispatch(setEditAccountModalOpen(true))
  }

  useEffect(() => {
    if (!currentAccount) return

    const isWelcomeModalClosed = localStorage.getItem('dw-teams-welcome-modal-closed')
    if (!isWelcomeModalClosed && isDataWarsHostName) {
      dispatch(setWelcomeModalOpen(true))
      localStorage.setItem('dw-teams-welcome-modal-closed', true)
    }

    dispatch(fetchAccountUsers(currentAccount?.id, { role: 'member', page_size: 'None' }))
  }, [currentAccount])

  useEffect(() => {
    let validTabs = ['dashboard', 'members', 'teams', 'courses', 'content', 'analytics']

    const isValidTab = validTabs.includes(tab)

    if (!tab || !isValidTab) {
      navigate(`/accounts/${accountId}/dashboard`)
    }

    if (!currentAccount || tab === 'dashboard') {
      dispatch(fetchAccount(accountId))
    }
  }, [tab])

  useEffect(() => {
    if (!accountId || accountError) {
      navigate('/not-found')
    }
  }, [accountId, accountError])

  useEffect(() => {
    if (!accountId || !isAuthenticated) return

    dispatch(fetchSubscriptionsMe({ account_id: accountId }))
  }, [isAuthenticated, accountId])

  if (!currentAccount) return <GlobalLoader />

  return (
    <Layout1 navbar>
      <Container className="dashboard-page-container">
        <MenuContainer className="menu-container">
          <div className="top-actions">
            <div className="account-selector">
              <Tooltip title={`Edit ${currentAccount?.name} settings`}>
                <div className="account-image-container" onClick={handleOpenEditAccountModal}>
                  {currentAccount?.image_url && (
                    <img className="account-image" src={currentAccount?.image_url} alt={currentAccount?.name} />
                  )}
                </div>
              </Tooltip>
            </div>

            <Menu
              className="account-menu"
              inlineCollapsed={isDown('lg')}
              selectedKeys={[tab]}
              defaultOpenKeys={['sub1']}
              mode="inline"
              theme={theme === 'dark' ? 'dark' : 'light'}
              items={items}
              onClick={({ key }) => navigate(`/accounts/${accountId}/${key}`)}
            />
          </div>

          {isDataWarsHostName && (isSuperAccountOwner || isAccountOwner) && (
            <div className="bottom-actions">
              <div className="menu-item" onClick={() => navigate('/accounts')}>
                <ChannelArrowLeft24Regular className="icon" />
                <p className="text">Accounts</p>
              </div>
            </div>
          )}
        </MenuContainer>

        {renderContent(tab)}

        {isDataWarsHostName && <WelcomeModal />}
        <AssignModal />
        <TeamModal />
        <AddToTeamModal />
        <InviteModal />
        <CustomModuleModal />
        <ModuleDetailModal />
        <PlaygroundDetailModal />
        <EditAccountModal />
      </Container>
    </Layout1>
  )
}

export default MainPage
