import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Input, Pagination, Segmented } from 'antd'
import { CollectionsAdd24Regular } from '@fluentui/react-icons'
import CustomModuleBox from '@/components/CustomModuleBox'
import { setUserSearchText } from '@/store/accounts/actions'
import { fetchCustomModules, resetModulesState } from '@/store/modules/actions'
import NoResults from '@/assets/images/no-results.svg'
import { Container } from './styles'

const AccountCustomModules = ({ disableItemActions, onItemClick }) => {
  const dispatch = useDispatch()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const { currentAccount, userSearchText } = useSelector((state) => state.accounts)
  const { customModulesData, isModuleLoading } = useSelector((state) => state.modules)

  const hasResults = customModulesData?.count > 0

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState()

  const handleLoad = async () => {
    dispatch(fetchCustomModules({ account_id: currentAccount?.id }))
  }

  const handleSearchProjects = (newPage, newPageSize, search = userSearchText) => {
    setCurrentPage(newPage || 1)

    if (newPageSize) {
      setPageSize(newPageSize)
    }

    dispatch(
      fetchCustomModules({
        account_id: currentAccount?.id,
        status: statusFilter === 'all' ? null : statusFilter,
        search,
        page_size: newPageSize || pageSize,
        page: newPage || 1,
      }),
    )
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        handleSearchProjects(null, null, search)
      }, 300),
    [statusFilter],
  )

  const handleTableChange = (page, pageSize) => {
    handleSearchProjects(page, pageSize)
  }

  useEffect(() => {
    if (!statusFilter) return

    handleSearchProjects(currentPage, pageSize)
  }, [statusFilter])

  useEffect(() => {
    handleLoad()

    return () => {
      setStatusFilter('all')
      dispatch(resetModulesState())
    }
  }, [])

  return (
    <Container className="account-custom-modules">
      {(hasResults || (!hasResults && userSearchText) || (statusFilter && statusFilter !== 'all')) && (
        <div className="header">
          <div className="search-container">
            <Input.Search
              className="user-search"
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search projects here..."
              loading={isModuleLoading}
              disabled={isModuleLoading}
            />
          </div>

          <Segmented
            className="status-filter"
            options={[
              { value: 'all', label: 'All' },
              { value: 'published', label: 'Published' },
              // { value: 'archived', label: 'Archived' },
            ]}
            defaultValue={'all'}
            value={statusFilter}
            onChange={setStatusFilter}
          />
        </div>
      )}

      <div className="body">
        {isModuleLoading ? (
          <div className="content">
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
            <div className="custom-module-box-container">
              <CustomModuleBox isLoading />
            </div>
          </div>
        ) : hasResults || (!hasResults && userSearchText) || (statusFilter && statusFilter !== 'all') ? (
          <div className="content">
            {customModulesData?.results?.map((p) => (
              <div className="custom-module-box-container" key={p.id}>
                <CustomModuleBox customModule={p} disableActions={disableItemActions} onClick={onItemClick} />
              </div>
            ))}

            {hasResults ? (
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  onChange={handleTableChange}
                  total={customModulesData?.count}
                  pageSize={pageSize}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} projects`}
                />
              </div>
            ) : (
              <div className="no-results-box">
                <img src={NoResults} alt="no-results" />
                <h4 className="title">No projects found</h4>
                <h5 className="text">
                  {userSearchText
                    ? `“${userSearchText}” did not match any project.`
                    : `You don't have ${statusFilter} projects.`}
                  <br />

                  {userSearchText && 'Please try again.'}
                </h5>
              </div>
            )}
          </div>
        ) : (
          <div className="empty-state-container">
            <div className="empty-state-content">
              <div className="header">
                <div className="container">
                  <div className="title-container">
                    <CollectionsAdd24Regular className="icon" />

                    <h4 className="title">
                      Start creating your Custom projects featuring auto-grading and automated tests!
                    </h4>
                  </div>

                  <p className="text">
                    With auto-graded projects you can define your own automated tests that are run against your
                    users/students' submissions to validate their score.
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default AccountCustomModules
