import { useSelector } from 'react-redux'
import { Skeleton } from 'antd'
import { UserOutlined } from '@ant-design/icons'
import { ContactCard24Regular } from '@fluentui/react-icons'
import ProLabel from '@/components/ProLabel'
import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const AccountSubscriptionCard = () => {
  const { currentAccount, isLoading: isAccountsLoading } = useSelector((state) => state.accounts)
  const { currentSubscription } = useSelector((state) => state.subscriptions)

  const orgConfig = currentAccount?.organization?.config

  const enrolledMembers = currentSubscription?.available_features?.account_members?.current_value
  let maxMembers = currentSubscription?.available_features?.account_members?.limit
  maxMembers = maxMembers === -1 ? '∞' : maxMembers

  return (
    <Container className="card account-subscription-card">
      {isAccountsLoading ? (
        <div className="loading-content">
          <Skeleton active paragraph={{ rows: 4 }} />
        </div>
      ) : (
        <div className="content">
          <div className="header">
            <div className="title-container">
              <ContactCard24Regular className="icon" />
              <p className="title">Subscription</p>
              {currentAccount?.is_pro && <ProLabel hideBadge />}
            </div>

            {!currentAccount?.is_pro && <ProLabel hideBadge isBasic />}

            {currentAccount?.is_pro && (
              <div className="more-licenses-box">
                <p className="text">
                  Need more licenses?
                  <br />
                  <span className="small">
                    Contact us at{' '}
                    <a href={`mailto:${orgConfig?.sales_email}?subject=Subscription upgrade`} target="_blank">
                      {orgConfig?.sales_email}
                    </a>
                    .
                  </span>
                </p>
              </div>
            )}
          </div>

          <div className="license-usage-info">
            <div className="title-container">
              <UserOutlined className="icon" />
              <h5 className="title">Enrolled members</h5>
            </div>

            <h4 className="value">
              {enrolledMembers}/{maxMembers}
            </h4>
          </div>

          <div className="license-info">
            {currentAccount?.is_pro ? (
              <p className="text">
                Your members will be able to access Pro modules, run multiple modules, and use all Pro features.
              </p>
            ) : (
              <>
                <p className="text">
                  <img className="icon pro-badge" src={ProBadge} alt="PRO user" />
                  Need more licenses?
                </p>

                <p className="text">
                  <span className="small">
                    Contact us at{' '}
                    <a href={`${orgConfig?.sales_email}?subject=Subscription upgrade`} target="_blank">
                      {orgConfig?.sales_email}
                    </a>
                    .
                  </span>
                </p>
              </>
            )}
          </div>
        </div>
      )}
    </Container>
  )
}

export default AccountSubscriptionCard
