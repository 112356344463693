import styled from 'styled-components'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  color: ${(props) => props.theme.colors.gray8};
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 12px;
  line-height: 22px;
  border: 1px solid ${(props) => props.theme.colors.gray4};
  border-radius: 10px;
  padding: 0px 8px;
  margin: 0px;

  .icon {
    height: 16px;
    width: 16px;
    display: none;
  }

  .indicator {
    height: 7px;
    width: 7px;
    background-color: ${(props) => props.theme.colors.gray5};
    border-radius: 50%;
  }

  &.draft .indicator {
    background-color: ${(props) => props.theme.colors.gold5};
  }

  &.published .indicator {
    background-color: ${(props) => props.theme.colors.geekblue5};
  }

  &.archived {
    color: ${(props) => props.theme.colors.red4};
    border-color: ${(props) => props.theme.colors.red2};

    .icon {
      color: ${(props) => props.theme.colors.red4};
      display: initial;
    }

    .indicator {
      display: none;
    }
  }
`
