import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 20px;

  > .content {
    max-width: 610px;
    width: 100%;
    min-height: 400px;

    .header {
      .title {
        text-align: center;
        margin: 0px;
      }
    }

    .accounts-container {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 18px;
      margin-top: 36px;
      padding-bottom: 36px;

      .loading-container {
        height: 200px;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 14px;
      }

      .account-item {
        min-height: 82px;
        background-color: ${(props) => props.theme.colors.gray1};
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        gap: 14px;
        border: 2px solid transparent;
        border-radius: 5px;
        padding: 16px 24px;
        cursor: pointer;
        animation: 1s ${fadeInAnimation};

        .account-image-container {
          max-width: 50px;
          min-width: 50px;
          max-height: 50px;
          min-height: 50px;
          background: linear-gradient(86deg, #39476d 8.49%, #4150d0 67.75%, rgba(179, 114, 244, 0.62) 97.13%);
          border-radius: 7px;
          overflow: hidden;

          .account-image {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
          }
        }

        .title-container {
          display: flex;
          align-items: center;
          gap: 6px;

          .title {
            font-weight: 700;
            margin: 0px;
          }

          .edit-button {
            color: ${(props) => props.theme.colors.gray7};
          }
        }

        .info {
          width: 100%;
          display: flex;
          flex-direction: column;

          .stats {
            display: flex;
            gap: 14px;

            .stat-item {
              color: ${(props) => props.theme.colors.gray8};
              font-weight: 500;
              display: flex;
              align-items: center;
              margin: 0px;

              .icon {
                height: 20px;
                width: 20px;
                color: ${(props) => props.theme.colors.gray6};
                margin-right: 3px;

                svg {
                  height: 16px;
                  width: 16px;
                }
              }
            }
          }
        }

        .actions {
          display: flex;
          align-items: center;
          gap: 10px;

          .pro-label {
            align-self: inherit;
          }

          .icon {
            color: ${(props) => props.theme.colors.gray8};
          }
        }

        &.new {
          background-color: ${(props) => props.theme.colors.geekblue2};
          align-items: center;
          justify-content: flex-start;
          padding: 0px 24px;

          .account-image-container {
            background: ${(props) => props.theme.colors.geekblue5};
            color: ${(props) => props.theme.colors.gray1};
            display: flex;
            align-items: center;
            justify-content: center;

            .icon {
              font-size: 24px;
            }
          }
        }

        &:hover {
          border: 2px solid ${(props) => props.theme.colors.geekblue5};
          box-shadow: 0px 2px 8px 0px ${(props) => props.theme.colors.gray8}22;
        }
      }

      .divider {
        display: flex;
        gap: 26px;
        align-items: center;

        .line {
          height: 1px;
          width: 100%;
          background-color: ${(props) => props.theme.colors.gray6};
        }

        .text {
          color: ${(props) => props.theme.colors.gray8};
          margin: 0px;
        }
      }
    }
  }

  @media ${device.mdDown} {
    justify-content: flex-start;

    > .content {
      min-height: auto;
    }
  }
`
