import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { debounce } from 'lodash'
import { Input, Pagination, Segmented } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { Book24Regular } from '@fluentui/react-icons'
import Button from '@/components/Button'
import CollectionBox from '@/components/CollectionBox'
import { setUserSearchText } from '@/store/accounts/actions'
import { fetchCollections, updateCollectionWizard } from '@/store/collections/actions'
import NoResults from '@/assets/images/no-results.svg'
import { Container } from './styles'

const CoursesList = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const { userSearchText } = useSelector((state) => state.accounts)
  const { collectionsData, isCollectionsLoading } = useSelector((state) => state.collections)

  const hasResults = collectionsData?.results?.length > 0

  const [pageSize, setPageSize] = useState(defaultPageSize)
  const [currentPage, setCurrentPage] = useState(1)
  const [statusFilter, setStatusFilter] = useState()

  const handleSearch = (newPage, newPageSize, search = userSearchText) => {
    setCurrentPage(newPage || 1)

    if (newPageSize) {
      setPageSize(newPageSize)
    }

    dispatch(
      fetchCollections({
        account_id: accountId,
        status: statusFilter === 'all' ? 'published' : statusFilter,
        search,
        page_size: newPageSize || pageSize,
        page: newPage || 1,
      }),
    )
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        handleSearch(null, null, search)
      }, 300),
    [statusFilter],
  )

  const handleTableChange = (page, pageSize) => {
    handleSearch(page, pageSize)
  }

  useEffect(() => {
    if (!statusFilter) return

    handleSearch(currentPage, pageSize)
  }, [statusFilter])

  useEffect(() => {
    dispatch(fetchCollections({ account_id: accountId, status: 'published' }))

    return () => {
      setStatusFilter('all')
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="courses-list">
      {(hasResults || (!hasResults && userSearchText) || (statusFilter && statusFilter !== 'all')) && (
        <div className="header">
          <div className="search-container">
            <Input.Search
              className="user-search"
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search courses here..."
              loading={isCollectionsLoading}
              disabled={isCollectionsLoading}
            />
          </div>

          <Segmented
            className="status-filter"
            options={[
              { value: 'all', label: 'Active' },
              { value: 'archived', label: 'Archived' },
            ]}
            defaultValue={'all'}
            value={statusFilter}
            onChange={setStatusFilter}
          />
        </div>
      )}

      <div className="body">
        {isCollectionsLoading ? (
          <div className="content">
            <div className="collections-container">
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
              <div className="collection-box-container">
                <CollectionBox isLoading />
              </div>
            </div>
          </div>
        ) : hasResults || (!hasResults && userSearchText) || (statusFilter && statusFilter !== 'all') ? (
          <div className="content">
            <div className="collections-container">
              {collectionsData?.results?.map((collection) => (
                <div key={collection?.id} className="collection-box-container">
                  <CollectionBox collection={collection} />
                </div>
              ))}
            </div>

            {hasResults ? (
              <div className="pagination-container">
                <Pagination
                  current={currentPage}
                  onChange={handleTableChange}
                  total={collectionsData?.count}
                  pageSize={pageSize}
                  showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} courses`}
                />
              </div>
            ) : (
              <div className="no-results-box">
                <img src={NoResults} alt="no-results" />
                <h4 className="title">No courses found</h4>
                <h5 className="text">
                  {userSearchText
                    ? `“${userSearchText}” did not match any course.`
                    : `You don't have ${statusFilter} courses.`}
                  <br />

                  {userSearchText && 'Please try again.'}
                </h5>
              </div>
            )}
          </div>
        ) : (
          <div className="empty-state-container">
            <div className="empty-state-content">
              <div className="header">
                <div className="container">
                  <div className="title-container">
                    <Book24Regular className="icon" />

                    <h4 className="title">Courses</h4>
                  </div>

                  <p className="text">
                    Create your courses with our simple tool or select from a wide variety of ready-to-use courses.
                  </p>

                  <Button
                    type="secondary"
                    icon={<PlusOutlined />}
                    onClick={() => dispatch(updateCollectionWizard({ isModalOpen: true }))}
                  >
                    Create course
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default CoursesList
