import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getSubscriptionsMe = async (queryParams) => {
  const route = 'subscriptions/me'
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getSubscriptionsMe }
