import styled from 'styled-components'

export const Container = styled.div`
  math-field {
    width: 100%;
    font-size: 18px;
    border: 1px solid ${(props) => props.theme.colors.gray5};
    border-radius: 2px;
    line-height: 42px;
    transition: 0.3s all;

    &:focus {
      box-shadow: 0 0 0 2px rgba(5, 145, 255, 0.1);
      outline: 0;
    }
  }
`
