import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray2};
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  .left-content,
  .right-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    padding: 20px;

    > .content {
      width: 100%;
      text-align: center;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      > .title {
        max-width: 330px;
        color: ${(props) => props.theme.colors.base.gray1};
        font-weight: 700;
        text-align: left;
        margin: 0px;
        text-shadow: 0px 0px 32px ${(props) => props.theme.colors.base.gray13};
      }
    }
  }

  .left-content {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    object-fit: cover;

    > .header {
      width: 100%;

      .organization-logo-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 9px;
        position: relative;
        padding: 0px 20px;
        margin-right: 10px;
        user-select: none;

        .organization-logo {
          height: 42px;
          filter: invert(1) grayscale(1) brightness(2);
          opacity: 0.6;
        }

        .divider {
          flex-grow: 1;
          border-bottom: 1px solid ${(props) => props.theme.colors.base.gray10};
          margin: 5px;
        }

        .logo-text {
          background: linear-gradient(
            90deg,
            ${(props) => props.theme.colors.base.gray5},
            ${(props) => props.theme.colors.base.geekblue5},
            ${(props) => props.theme.colors.base.purple4}
          );
          background-clip: text;
          -webkit-text-fill-color: transparent;
          text-transform: uppercase;
          font-size: 16px;
          font-weight: 700;
          letter-spacing: 2px;
          margin: 0px;
        }
      }
    }

    > .content {
      height: 100%;
      display: none;
    }
  }

  .right-content {
    height: 100%;
    gap: 40px;

    .account-info-container {
      max-width: 420px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
      padding: 20px;
      user-select: none;

      > .content {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 12px;
        overflow: hidden;

        .account-logo-container {
          height: 32px;

          .account-logo {
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
          }
        }

        .account-name {
          color: ${(props) => props.theme.colors.gray8};
          font-weight: 600;
          margin: 0px;
        }
      }
    }

    .content {
      max-width: 420px;
    }
  }

  @media ${device.lgUp} {
    flex-direction: row;

    .left-content,
    .right-content {
      min-height: 100vh;
      height: 100vh;
      padding: 60px 20px;
    }

    .left-content {
      width: 33%;
      background-position: top;

      > .content {
        display: flex;
      }
    }

    .right-content {
      width: 66%;
    }
  }
`
