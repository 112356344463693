import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Input, Table, Tag, Modal, Tooltip, Empty } from 'antd'
import { ExclamationCircleFilled, PlusOutlined, DeleteOutlined } from '@ant-design/icons'
import { debounce } from 'lodash'
import { capitalize } from '@/utils/functions'
import { isDown } from '@/themes/breakpoints'
import Button from '@/components/Button'
import MemberAvatar from '@/components/MemberAvatar'
import {
  fetchAccountUsers,
  removeAccountUsers,
  setSelectedUsers,
  setUserSearchText,
  setAddToTeamModalOpen,
} from '@/store/accounts/actions'
import { Container } from './styles'

const MembersTable = () => {
  const dispatch = useDispatch()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const {
    currentAccount: account,
    usersData,
    selectedUsers,
    userSearchText,
    filteredTeam,
    isLoading: isAccountsLoading,
  } = useSelector((state) => state.accounts)

  const teamId = filteredTeam === 'all' ? undefined : filteredTeam === 'not-in-teams' ? 'None' : filteredTeam
  const hasSelected = selectedUsers?.length > 0
  const selectedUsersData = usersData?.results?.filter((u) => selectedUsers?.includes(u?.user?.id))
  const hasTeams = selectedUsersData?.some((u) => u.teams?.length)
  const canRemoveMember = selectedUsers?.length === 1 && selectedUsersData?.[0]?.role !== 'owner'

  const [pageSize, setPageSize] = useState(defaultPageSize)

  const onSelectedMembersChange = (userIds) => {
    dispatch(setSelectedUsers(userIds))
  }

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      fixed: 'left',
    },
    {
      title: 'Email',
      dataIndex: 'email',
    },
    {
      title: 'Team',
      dataIndex: 'team',
    },
    {
      title: 'Last access',
      dataIndex: 'lastAccess',
    },
  ]

  const data = usersData?.results?.map((u) => {
    return {
      key: u?.user?.id,
      name: (
        <MemberAvatar
          lastName={u?.user?.last_name}
          firstName={u?.user?.first_name}
          avatarUrl={u?.user?.avatar_url}
          showName
          isOwner={u?.role === 'owner'}
          externalId={u?.user?.external_id}
        />
      ),
      email: u?.user?.email,
      team: u?.teams?.length
        ? u?.teams?.map((t) => (
            <Tag
              key={t.id}
              className="team-tag"
              style={{ backgroundColor: `${t.color}15`, color: `${t.color}cc`, borderColor: `${t.color}cc` }}
            >
              {t.name}
            </Tag>
          ))
        : '—',
      lastAccess: dayjs(u?.last_access).from(),
    }
  })

  const handleTableChange = (pagination) => {
    const { current: page, pageSize } = pagination

    setPageSize(pageSize)
    dispatch(fetchAccountUsers(account?.id, { search: userSearchText, team_id: teamId, page_size: pageSize, page }))
  }

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        dispatch(fetchAccountUsers(account?.id, { search, team_id: teamId, page_size: pageSize }))
      }, 300),
    [filteredTeam],
  )

  const handleRemoveMember = () => {
    const userData = selectedUsersData?.[0]

    Modal.confirm({
      title: `Are you sure you want to remove ${capitalize(userData?.user?.first_name)} from the account?`,
      content:
        "By removing this member, you won't be able to view their progress and statistics. Their license will be revoked, allowing you to assign it to another member.",
      icon: <ExclamationCircleFilled />,
      okText: 'Yes, remove member',
      cancelText: 'Cancel',
      onOk: () => dispatch(removeAccountUsers(account?.id, { user_ids: selectedUsers })),
      okButtonProps: {
        danger: true,
        type: 'primary',
        loading: isAccountsLoading,
      },
    })
  }

  useEffect(() => {
    if (userSearchText) return

    dispatch(fetchAccountUsers(account?.id, { team_id: teamId, page_size: pageSize }))
  }, [userSearchText, filteredTeam])

  return (
    <Container className="members-table-container">
      <div className="table-header">
        <div className="filters">
          <Input.Search
            className="user-search"
            value={userSearchText}
            onChange={(evt) => {
              dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
              handleUserSearch(evt)
            }}
            allowClear
            placeholder="Search member..."
            loading={isAccountsLoading}
          />

          {hasSelected && (
            <div className="actions">
              <Button
                type="secondary"
                onClick={() => dispatch(setAddToTeamModalOpen('add'))}
                icon={isDown('md') && <PlusOutlined />}
              >
                {isDown('md') ? '' : 'Add to team'}
              </Button>

              {hasTeams && (
                <Button
                  danger
                  onClick={() => dispatch(setAddToTeamModalOpen('remove'))}
                  icon={isDown('md') && <DeleteOutlined />}
                >
                  {isDown('md') ? '' : 'Remove from team'}
                </Button>
              )}

              {canRemoveMember && (
                <Tooltip title={hasTeams && 'Remove user from all teams before removing him from the account.'}>
                  <Button
                    type="primary"
                    danger
                    onClick={handleRemoveMember}
                    icon={isDown('md') && <DeleteOutlined />}
                    disabled={hasTeams}
                  >
                    {isDown('md') ? '' : 'Remove from account'}
                  </Button>
                </Tooltip>
              )}
            </div>
          )}
        </div>
      </div>

      <Table
        className="members-table"
        size="middle"
        rowSelection={{
          selectedRowKeys: selectedUsers,
          onChange: onSelectedMembersChange,
        }}
        columns={columns}
        dataSource={data}
        loading={!usersData || isAccountsLoading}
        onChange={handleTableChange}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No members found" /> }}
        pagination={{
          pageSize,
          total: usersData?.count,
          showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} members`,
        }}
        scroll={{
          y: true,
          x: true,
        }}
      />
    </Container>
  )
}
export default MembersTable
