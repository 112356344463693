import {
  PeopleAudience24Regular,
  CloudFlow24Regular,
  ChartMultiple24Regular,
  BrainCircuit24Regular,
  TargetArrow24Regular,
  Briefcase24Regular,
  Rocket24Regular,
  BotSparkle24Regular,
  Backpack24Regular,
  DatabaseSearch24Regular,
  DeveloperBoard24Regular,
  Beach24Regular,
} from '@fluentui/react-icons'
import { Container } from './styles'

export const courseIcons = [
  { name: 'fluent-PeopleAudience24Regular', icon: <PeopleAudience24Regular /> },
  { name: 'fluent-CloudFlow24Regular', icon: <CloudFlow24Regular /> },
  { name: 'fluent-ChartMultiple24Regular', icon: <ChartMultiple24Regular /> },
  { name: 'fluent-BrainCircuit24Regular', icon: <BrainCircuit24Regular /> },
  { name: 'fluent-TargetArrow24Regular', icon: <TargetArrow24Regular /> },
  { name: 'fluent-Briefcase24Regular', icon: <Briefcase24Regular /> },
  { name: 'fluent-Rocket24Regular', icon: <Rocket24Regular /> },
  { name: 'fluent-BotSparkle24Regular', icon: <BotSparkle24Regular /> },
  { name: 'fluent-Backpack24Regular', icon: <Backpack24Regular /> },
  { name: 'fluent-DatabaseSearch24Regular', icon: <DatabaseSearch24Regular /> },
  { name: 'fluent-DeveloperBoard24Regular', icon: <DeveloperBoard24Regular /> },
  { name: 'fluent-Beach24Regular', icon: <Beach24Regular /> },
]

const CourseIcon = ({ color, iconName, icon, size, isCurrent, onClick }) => {
  const getIcon = () => {
    const iconData = courseIcons?.find((i) => i.name === iconName)
    return iconData?.icon || icon || <></>
  }

  return (
    <Container className="team-icon" $color={color} $size={size} $isCurrent={isCurrent} onClick={onClick}>
      {getIcon()}
    </Container>
  )
}

export default CourseIcon
