import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 120px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;

  ${(props) =>
    props.$isClickable &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.colors.gray2};
      }
    `}

  .fade-in-mask {
    display: flex;
    flex-direction: column;
  }

  .main-content {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 18px 20px;

    .image-container {
      .module-image {
        min-width: 78px;
        width: 78px;
        height: auto;
        opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
        image-rendering: auto;
        user-select: none;
        pointer-events: none;
      }
    }

    > .content {
      width: 100%;

      .header {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;
        gap: 10px;

        .title-container {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .menu-item {
            > .title:hover {
              text-decoration: underline;
            }
          }

          .title {
            font-weight: 700;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0px;
          }

          .author-info {
            color: ${(props) => props.theme.colors.gray7};
            font-size: 12px;
            line-height: 20px;
            border: 1px solid ${(props) => props.theme.colors.gray4};
            border-radius: 8px;
            display: flex;
            align-items: center;
            align-self: flex-start;
            gap: 4px;
            white-space: nowrap;
            padding: 3px 6px;

            > span {
              color: ${(props) => props.theme.colors.gray13};
            }
          }
        }

        .info-box {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .version-item {
            display: flex;
            align-items: center;
            gap: 5px;

            .version-code {
              min-width: 24px;
              min-height: 24px;
              background-color: ${(props) => props.theme.colors.cyan2};
              color: ${(props) => props.theme.colors.cyan6};
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 12px;
              font-weight: 400;
              text-transform: uppercase;
              border-radius: 50%;
              margin: 0px;
            }
          }
        }
      }
    }

    .extra-container {
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: space-between;

      .open-options-button {
        color: ${(props) => props.theme.colors.gray8};
      }

      .stack-info {
        .image {
          height: 24px;
        }
      }
    }
  }

  &.is-loading {
    .main-content {
      display: flex;
      flex-direction: row;
      gap: 16px;
      padding: 0px 20px;
    }

    .left-side {
      width: 80px;
      display: flex;
      align-items: center;

      .ant-skeleton-avatar {
        width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }

    .right-side {
      display: flex;
      align-items: center;
    }
  }

  ${(props) =>
    props.$size === 'small' &&
    css`
      height: 70px;

      .main-content {
        gap: 12px;
        padding: 10px 15px;

        .image-container {
          .module-image {
            min-width: 48px;
            width: 48px;
          }
        }

        > .content {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;

          .header {
            flex-direction: row;

            .title-container {
              .title {
                font-size: 14px;
                font-weight: 600;
              }
            }

            .info-box {
              height: 100%;
              justify-content: flex-end;
            }
          }
        }

        .extra-container {
          justify-content: center;

          .stack-info {
            display: none;
          }
        }
      }

      &.is-loading {
        .left-side {
          width: 48px;
          display: flex;
          align-items: center;

          .ant-skeleton-avatar {
            width: 48px;
            height: 48px;
            border-radius: 16px;
          }
        }
      }
    `};

  @media ${device.smDown} {
    .main-content {
      gap: 14px;
      padding: 24px 16px 16px;

      .image-container {
        min-width: 54px;
        max-width: 54px;
        min-height: 54px;
        max-height: 54px;

        .main-icon {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      > .content {
        gap: 2px;

        .header {
          .title-container {
            .title {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
`
