import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getCustomModules = async (queryParams) => {
  const route = 'custom-modules'
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const postCustomModule = async (data, queryParams) => {
  const route = 'custom-modules'
  return API.post(route, data, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

const patchCustomModule = async (id, data, queryParams) => {
  const route = `custom-modules/${id}`
  return API.patch(route, data, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error?.response?.data)))
}

export { getCustomModules, postCustomModule, patchCustomModule }
