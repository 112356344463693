import styled, { css } from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 120px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  transition: 0.3s all;

  > .main-content {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 22px;
    padding: 18px 20px;

    .image-container {
      min-width: 78px;
      max-width: 78px;
      min-height: 78px;
      max-height: 78px;
      background-color: ${(props) => props.theme.colors.gray2};
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 2px solid ${(props) => props.theme.colors.gray7};
      border-radius: 10px;

      svg {
        width: 36px;
        height: 36px;
        color: ${(props) => props.theme.colors.gray7};
        font-size: 36px;
      }

      .template-tag {
        height: 30px;
        width: 30px;
        background-color: ${(props) => props.theme.colors.gray10};
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: absolute;
        right: -6px;
        bottom: -6px;

        .logo {
          width: 16px;
        }
      }
    }

    > .content {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 12px;

      .header {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;

        .title-container {
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 5px;

          .course-code {
            text-transform: uppercase;
          }

          .title {
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            margin: 0px;
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }

          .info {
            display: flex;
            align-items: center;
            gap: 10px;

            .description {
              color: ${(props) => props.theme.colors.gray7};
              margin: 0px;
            }
          }
        }

        .info-box {
          text-align: right;
          display: flex;
          flex-direction: column;
          white-space: nowrap;

          .metric-name {
            color: ${(props) => props.theme.colors.gray6};
            font-size: 12px;
            line-height: 20px;
            margin: 0px;
          }

          .value {
            color: ${(props) => props.theme.colors.gray10};
            font-size: 12px;
            line-height: 20px;
            margin: 0px;
          }
        }

        .open-options-button {
          color: ${(props) => props.theme.colors.gray10};
        }
      }
    }
  }

  &.is-loading {
    .left-side {
      width: 80px;
      display: flex;
      align-items: center;

      .ant-skeleton-avatar {
        width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }

    .right-side {
      display: flex;
      align-items: center;
    }
  }

  @media ${device.smDown} {
    > .main-content {
      gap: 14px;
      padding: 24px 16px 16px;

      .image-container {
        min-width: 54px;
        max-width: 54px;
        min-height: 54px;
        max-height: 54px;

        svg {
          width: 24px;
          height: 24px;
          font-size: 24px;
        }
      }

      > .content {
        gap: 2px;

        .header {
          .title-container {
            .title {
              font-size: 16px;
              line-height: 22px;
            }
          }
        }
      }
    }
  }
`
