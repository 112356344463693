import { Archive24Regular } from '@fluentui/react-icons'
import { Container } from './styles'

const StatusTag = ({ status }) => {
  return (
    <Container className={`status-tag ${status}`}>
      <Archive24Regular className="icon" />
      <span className="indicator" /> {status}
    </Container>
  )
}

export default StatusTag
