import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Tabs } from 'antd'
import ProLabel from '@/components/ProLabel'
import ModulesCatalog from '@/components/ModulesCatalog'
import AccountPlaygrounds from '@/components/AccountPlaygrounds'
import AccountCustomModules from '@/components/AccountCustomModules'
import { toggleContentSearchModal } from '@/store/app/actions'
import { setContentTab, setUserSearchText } from '@/store/accounts/actions'
import { addCollectionContent } from '@/store/collections/actions'
import { Modal } from './styles'

const ContentSearchModal = () => {
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const { isContentSearchModalOpen } = useSelector((state) => state.app)
  const { currentAccount, selectedContentTab } = useSelector((state) => state.accounts)

  const enableCatalog = currentAccount?.config?.browse?.catalog // DataWars projects
  const enablePlaygrounds = currentAccount?.config?.browse?.playgrounds
  const enableAutogradedCustomProjects = currentAccount?.config?.browse?.autograded_custom_projects

  const handleCloseModal = () => {
    dispatch(toggleContentSearchModal(false))
  }

  const tabItems = [
    ...(enableCatalog
      ? [
          {
            key: 'projects',
            label: 'Full catalog',
            children: (
              <div className="tab-content">
                {selectedContentTab === 'projects' && (
                  <ModulesCatalog
                    disableItemActions
                    onItemClick={(item) => {
                      const auxItem = { ...item, type: 'module' }
                      dispatch(addCollectionContent(auxItem))
                      handleCloseModal()
                    }}
                    onItemClickText="Add project"
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(enablePlaygrounds
      ? [
          {
            key: 'customProjects',
            label: 'Custom projects',
            children: (
              <div className="tab-content">
                {selectedContentTab === 'customProjects' && (
                  <AccountPlaygrounds
                    disableItemActions
                    onItemClick={(item) => {
                      const auxItem = { ...item, type: 'playground' }
                      dispatch(addCollectionContent(auxItem))
                      handleCloseModal()
                    }}
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
    ...(enableAutogradedCustomProjects
      ? [
          {
            key: 'autoGradedProjects',
            label: (
              <span style={{ display: 'flex', gap: 4 }}>
                Auto-graded projects <ProLabel hideBadge />
              </span>
            ),
            children: (
              <div className="tab-content">
                {selectedContentTab === 'autoGradedProjects' && (
                  <AccountCustomModules
                    disableItemActions
                    onItemClick={(item) => {
                      const auxItem = { ...item, type: 'custom_module' }
                      dispatch(addCollectionContent(auxItem))
                      handleCloseModal()
                    }}
                  />
                )}
              </div>
            ),
          },
        ]
      : []),
  ]

  const handleChangeTab = (tab) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tab })
  }

  useEffect(() => {
    dispatch(setUserSearchText(''))
    let validTabs = []

    if (enableCatalog) {
      validTabs.push('projects')
    }

    if (enablePlaygrounds) {
      validTabs.push('customProjects')
    }

    if (enableAutogradedCustomProjects) {
      validTabs.push('autoGradedProjects')
    }

    const isValidTab = validTabs.includes(tab)

    if (tab && tab !== selectedContentTab && isValidTab) {
      dispatch(setContentTab(tab))
      return
    }

    if (!tab || !isValidTab) {
      dispatch(setContentTab(validTabs[0]))
    }
  }, [tab])

  useEffect(() => {
    return () => {
      dispatch(setUserSearchText(''))
    }
  }, [])

  return (
    <Modal className="content-search-modal" open={!!isContentSearchModalOpen} onCancel={handleCloseModal} footer={null}>
      <div className="container">
        <div className="header">
          <h3 className="title">Add project</h3>
        </div>

        <div className="content">
          <Tabs activeKey={selectedContentTab} items={tabItems} onChange={handleChangeTab} />
        </div>
      </div>
    </Modal>
  )
}

export default ContentSearchModal
