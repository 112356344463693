import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Tooltip, Button, Dropdown, Skeleton } from 'antd'
import { LineChartOutlined, CopyOutlined } from '@ant-design/icons'
import {
  MoreVertical24Filled,
  Archive24Regular,
  DocumentCopy24Regular,
  Options24Regular,
  Share24Regular,
  PersonArrowRight24Regular,
} from '@fluentui/react-icons'
import { getPlatformURL, domain, shortEnv } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import { renderModuleImage } from '@/helpers/renderModuleImage'
import FadeIn from '@/components/FadeIn'
import StatusTag from '@/components/StatusTag'
import { updateCustomModuleWizard, setAssignModalOpen } from '@/store/modules/actions'
import { Container } from './styles'

const CustomModuleBox = ({ customModule, size, disableActions, onClick, isLoading }) => {
  const dispatch = useDispatch()

  const { currentAccount } = useSelector((state) => state.accounts)

  const lastVersion = customModule?.versions?.[customModule?.versions?.length - 1]
  const selfLaunchIsEnabled = currentAccount?.config?.launch?.self_launch
  const ltiLaunchIsEnabled = currentAccount?.config?.launch?.lti_launch

  const duplicateCustomModule = () => {}

  const archiveCustomModule = () => {}

  const unarchiveCustomModule = () => {}

  const getStatusDate = () => {
    if (lastVersion?.status === 'draft') return `Created ${dayjs(lastVersion?.created).from()}`
    if (lastVersion?.status === 'published') return `Published ${dayjs(lastVersion?.created).from()}`
  }

  const customModuleMenuItems = [
    ...(lastVersion?.status === 'published'
      ? [
          {
            key: 'assign',
            label: (
              <a
                onClick={() => {
                  dispatch(setAssignModalOpen(customModule))
                }}
                className="menu-item"
              >
                <PersonArrowRight24Regular /> Assign
              </a>
            ),
          },
          {
            key: 'analytics',
            label: (
              <Link
                className="menu-item"
                to={`/accounts/${currentAccount?.id}/analytics?tab=projects&project=${lastVersion?.id}`}
              >
                <LineChartOutlined /> View Analytics
              </Link>
            ),
          },
        ]
      : []),
    ...(lastVersion?.status !== 'archived'
      ? [
          {
            key: 'edit',
            label: (
              <a
                className="menu-item"
                onClick={() =>
                  dispatch(
                    updateCustomModuleWizard({
                      isModalOpen: true,
                      id: customModule?.id,
                      name: customModule?.name,
                      description: customModule?.description_md,
                      svgIconUrl: customModule?.svg_icon_url,
                      stack: customModule?.stack,
                      stackSize: customModule?.stack_size,
                      ...(customModule?.gpu_type && {
                        gpuType: customModule?.gpu_type,
                        gpuCount: customModule?.gpu_count,
                      }),
                      internetEdition: customModule?.internet_edition,
                      internetResolution: customModule?.internet_resolution,
                    }),
                  )
                }
              >
                <Options24Regular /> Settings
              </a>
            ),
          },
          // {
          //   key: 'duplicate',
          //   label: (
          //     <a onClick={duplicateCustomModule} className="menu-item">
          //       <DocumentCopy24Regular /> Duplicate
          //     </a>
          //   ),
          // },
          ...(lastVersion?.status === 'published'
            ? [
                {
                  key: 'copy-id',
                  label: (
                    <span
                      className="menu-item"
                      onClick={() => {
                        navigator.clipboard.writeText(lastVersion?.id)
                        showToast('Project ID copied to clipboard')
                      }}
                    >
                      <CopyOutlined /> Copy project ID
                    </span>
                  ),
                },
              ]
            : []),
          ...(lastVersion?.status === 'published' && selfLaunchIsEnabled
            ? [
                {
                  key: 'share',
                  label: (
                    <a
                      onClick={() => {
                        let url = `${getPlatformURL(currentAccount?.slug)}project/${lastVersion?.id}`
                        navigator?.clipboard?.writeText(url)
                        showToast('Link copied to clipboard')
                      }}
                      className="menu-item"
                    >
                      <Share24Regular /> Copy project link
                    </a>
                  ),
                },
              ]
            : []),
          ...(lastVersion?.status === 'published' && ltiLaunchIsEnabled
            ? [
                {
                  key: 'copy-lti-url',
                  label: (
                    <span
                      className="menu-item"
                      onClick={() => {
                        let url = `https://api.${shortEnv}.${domain}/lti/launch/?content_type=project&content_id=${lastVersion?.id}`
                        navigator?.clipboard?.writeText(url)
                        showToast('Project LTI URL copied to clipboard')
                      }}
                    >
                      <CopyOutlined /> Copy LTI URL
                    </span>
                  ),
                },
              ]
            : []),
          // {
          //   key: 'archive',
          //   label: (
          //     <a onClick={archiveCustomModule} className="menu-item">
          //       <Archive24Regular /> Archive
          //     </a>
          //   ),
          //   danger: true,
          // },
        ]
      : [
          // {
          //   key: 'duplicate',
          //   label: (
          //     <a onClick={duplicateCustomModule} className="menu-item">
          //       <DocumentCopy24Regular /> Duplicate
          //     </a>
          //   ),
          // },
          // {
          //   key: 'restore',
          //   label: (
          //     <a onClick={unarchiveCustomModule} className="menu-item">
          //       <ArchiveArrowBack24Regular /> Restore
          //     </a>
          //   ),
          //   danger: true,
          // },
        ]),
  ]

  if (isLoading || !customModule)
    return (
      <Container className="custom-module-box is-loading" $size={size}>
        <div className="main-content">
          <Skeleton className="left-side" active avatar title={false} paragraph={{ rows: 0 }} />
          <Skeleton className="right-side" active title={false} paragraph={{ rows: size === 'small' ? 1 : 2 }} />
        </div>
      </Container>
    )

  return (
    <Container
      className="custom-module-box"
      $size={size}
      $isClickable={!!onClick}
      onClick={onClick ? () => onClick(customModule) : null}
    >
      <FadeIn>
        <div className="main-content">
          <div className="image-container">{renderModuleImage(customModule)}</div>

          <div className="content">
            <div className="header">
              <div className="title-container">
                {onClick ? (
                  <h5 className="title">{customModule?.name}</h5>
                ) : (
                  <Link
                    className="menu-item"
                    to={`${getPlatformURL(currentAccount?.slug)}projects/${customModule?.id}/versions/${lastVersion?.id}`}
                    target="_blank"
                  >
                    <h5 className="title">{customModule?.name}</h5>
                  </Link>
                )}
              </div>

              <div className="info-box">
                {lastVersion ? (
                  <div className="version-item">
                    <h5 className="version-code">V{lastVersion?.version_number}</h5>

                    <Tooltip title={getStatusDate}>
                      <div className="status-tag-container">
                        <StatusTag status={lastVersion?.status} />
                      </div>
                    </Tooltip>
                  </div>
                ) : (
                  <span />
                )}
              </div>
            </div>
          </div>

          <div className="extra-container">
            {!disableActions ? (
              <Dropdown
                overlayClassName={'dropdown-menu'}
                trigger={['click']}
                menu={{
                  items: customModuleMenuItems,
                  onClick: ({ domEvent }) => domEvent.stopPropagation(),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {customModuleMenuItems?.length ? (
                  <Button className="open-options-button" type="text" shape="circle" icon={<MoreVertical24Filled />} />
                ) : (
                  <span />
                )}
              </Dropdown>
            ) : (
              <span />
            )}

            <Tooltip title={customModule?.stack?.name} placement="left">
              <div className="stack-info">
                <img className="image" src={customModule?.stack?.image_url} alt={customModule?.stack?.name} />
              </div>
            </Tooltip>
          </div>
        </div>
      </FadeIn>
    </Container>
  )
}

export default CustomModuleBox
