import { Layout } from 'antd'
import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled(Layout.Header)`
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  position: sticky;
  top: 0px;
  display: flex;
  justify-content: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray5};
  padding: 0 20px;
  z-index: 101;
  transition:
    0.3s transform,
    0.6s opacity;

  .ant-menu {
    background-color: ${(props) => props.theme.colors.gray1};
    border: 0px;

    .ant-menu-item {
      top: 0px;
    }

    .ant-menu-item-disabled {
      cursor: default;

      &:hover {
        background-color: initial !important;
      }
    }

    .menu-item {
      display: flex;
      gap: 6px;
    }
  }

  .content {
    /* max-width: 1440px; */
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 30px;
    overflow: hidden;
  }

  .left-content {
    display: flex;
    align-items: center;

    .logo-container {
      height: 100%;
      display: flex;
      align-items: center;
      gap: 9px;
      margin-right: 10px;
      user-select: none;

      .logo {
        height: 32px;
        display: block;
      }

      .logo-text {
        background: linear-gradient(
          90deg,
          ${(props) => props.theme.colors.gray10},
          ${(props) => props.theme.colors.geekblue7},
          ${(props) => props.theme.colors.purple6},
          ${(props) => props.theme.colors.purple7}
        );
        background-clip: text;
        -webkit-text-fill-color: transparent;
        text-transform: uppercase;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 2px;
        margin: 0px;
      }
    }
  }

  .right-content {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    gap: 20px;

    .ant-menu {
      flex: auto;
      justify-content: flex-end;

      .ant-menu-item {
        padding: 0px;
      }
    }

    .menu-icon {
      display: none;
      margin-left: 10px;
      cursor: pointer;

      .menu-icon-img {
        width: 26px;
      }
    }

    .theme-switch {
      background-color: ${(props) => props.theme.colors.gray10};

      .ant-switch-handle:before {
        background-color: ${(props) => props.theme.colors.gray6};
      }

      .ant-switch-inner {
        .ant-switch-inner-unchecked {
          color: ${(props) => props.theme.colors.gray1};
        }
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.gray9};
      }
    }

    .navbar-btn,
    .navbar-btn a {
      color: ${(props) => props.theme.colors.gray13};
    }
  }

  .ant-menu-item::after,
  .ant-menu-submenu::after {
    border-bottom: none !important;
    transition: none !important;
  }

  .ant-menu,
  .ant-menu-menu-title,
  .ant-menu-title-content a,
  .ant-menu-title-content span,
  .ant-menu-submenu-title,
  .ant-menu-submenu-content a {
    color: ${(props) => props.theme.colors.gray12};
    font-weight: 500;
  }

  .ant-menu-item-selected,
  .ant-menu-item-active,
  .ant-menu-item:hover,
  .ant-menu-submenu-active,
  .ant-menu-submenu:hover {
    color: ${(props) => props.theme.colors.gray12} !important;
    background-color: rgba(255, 255, 255, 0.1) !important;
  }

  .ant-btn-text {
    .ant-btn-icon {
      color: ${(props) => props.theme.colors.gray12};
    }

    &:hover {
      background-color: rgba(255, 255, 255, 0.1) !important;
    }
  }

  @media ${device.mdDown} {
    box-shadow: 0px 1px 4px 0px
      ${(props) =>
        localStorage.getItem('dw-theme') === 'dark' ? props.theme.colors.gray7 : props.theme.colors.gray13}26;

    .ant-menu {
      background-color: ${(props) => props.theme.colors.gray12};
      color: ${(props) => props.theme.colors.gray8};
    }

    .right-content {
      .navbar-btn {
        display: none;
      }
    }

    .content {
      .left-content {
        .logo-container {
        }

        .ant-menu {
          display: none;
        }
      }

      .center-content {
        display: none;
      }

      .right-content {
        #navbar-right-menu {
          display: none;
        }

        .menu-icon {
          display: flex;

          .menu-icon-img {
            filter: contrast(0);
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    &.hidden {
      opacity: 0;
      transform: translateY(-64px);
      margin-top: -64px;
    }

    .welcome-btn {
      display: none;
    }
  }
`
