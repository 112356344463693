import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const fetchIssues = async (queryParams) => {
  const route = 'issues'
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postIssue = async (data) => {
  const { contentId, issueType, description } = data
  const body = {
    content_type: 'module', // ("title", "module")
    content_id: contentId,
    issue_type: issueType, // ("platform_issue", "content_issue", "lab_issue", "other")
    description: description,
    metadata: {
      user_agent: window?.navigator?.userAgent,
      current_url: window?.location?.href,
    },
  }
  return API.post('issues', body)
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { fetchIssues, postIssue }
