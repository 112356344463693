import styled, { css } from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  height: calc(100% - 50px);
  max-width: 1440px;
  width: 100% !important;
  top: 30px;

  .ant-modal-content {
    height: 100%;
    background-color: ${(props) => props.theme.colors.basePageBg} !important;
    padding: 48px 32px;
  }

  .ant-modal-body {
    height: 100%;

    .container {
      height: 100%;
      display: flex;
      flex-direction: column;
      gap: 12px;

      > .header {
        .title {
          font-weight: 700;
          margin: 0px;
        }
      }

      > .content {
        height: calc(100% - 32px);
        overflow: hidden;

        .ant-tabs,
        .ant-tabs-content-holder,
        .ant-tabs-content,
        .ant-tabs-tabpane,
        .tab-content {
          height: 100%;
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.basePageBg};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }
`
