import styled, { css } from 'styled-components'

export const Container = styled.div`
  min-width: 52px;
  max-width: 52px;
  min-height: 52px;
  max-height: 52px;
  background-color: ${(props) => props.theme.colors.gray2};
  border: 2px solid ${(props) => props.theme.colors.gray7};
  border-radius: 5px;
  opacity: 0.4;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s all;

  > svg {
    width: 35px;
    height: 35px;
    color: ${(props) => props.theme.colors.gray7};
  }

  ${(props) =>
    props.$size === 'xsmall' &&
    css`
      min-width: 28px;
      max-width: 28px;
      min-height: 28px;
      max-height: 28px;

      > svg {
        width: 18px;
        height: 18px;
      }
    `}

  ${(props) =>
    props.$size === 'small' &&
    css`
      min-width: 40px;
      max-width: 40px;
      min-height: 40px;
      max-height: 40px;

      > svg {
        width: 24px;
        height: 24px;
      }
    `}

  ${(props) =>
    props.$isCurrent &&
    css`
      opacity: 1;
    `}

  ${(props) =>
    props.onClick
      ? css`
          cursor: pointer;

          &:hover {
            opacity: 1;
          }
        `
      : css`
          opacity: 1;
        `}
`
