import styled from 'styled-components'

export const Container = styled.div`
  .title {
    font-weight: 500;
  }

  .team-select {
    height: 46px;
    width: 100%;
    margin-bottom: 18px;
  }

  .license-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
    padding: 10px 0px;

    .text {
      color: ${(props) => props.theme.colors.gray8};
      margin: 0px;

      &.error {
        color: ${(props) => props.theme.colors.red6};
      }
    }
  }

  .info-text {
    color: ${(props) => props.theme.colors.gray8};
    font-size: 12px;
    margin: 10px 0px 0px;

    &.error {
      color: ${(props) => props.theme.colors.red6};
    }
  }
`
