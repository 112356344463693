import { useRef } from 'react'
import 'mathlive'
import { Container } from './styles'

const MathLiveInput = ({ className, value, ...rest }) => {
  return (
    <Container className={`${className || ''} math-live-input-container`}>
      <math-field
        {...rest}
        placeholder={rest?.placeholder || 'f(x) = 2 + x'}
        onInput={rest?.onChange}
        disabled={rest?.disabled ? true : null}
      >
        {value}
      </math-field>
    </Container>
  )
}

export default MathLiveInput
