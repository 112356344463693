import { useState, useEffect, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { domain, isDataWarsHostName } from '@/helpers/env'
import GlobalLoader from '@/components/GlobalLoader'
import Router from '@/router'
import Theme from '@/themes/base'
import { initSentry } from '@/utils/sentry'
import { initializeDayjs } from '@/utils/dayjs'
import { setAppError, setAppNotification } from '@/store/app/actions'
import { tryAutoLogin } from '@/store/users/actions'

initializeDayjs()

const App = () => {
  const dispatch = useDispatch()
  const [isPageLoading, setPageLoading] = useState(true)

  const { appError, appNotification } = useSelector((state) => state.app)
  const { isAuthenticated } = useSelector((state) => state.users)

  const onLoad = useCallback(async () => {
    await dispatch(tryAutoLogin())
    setPageLoading(false)
  }, [dispatch])

  if (appError) {
    // toastError(appError)
    dispatch(setAppError(null))
  }

  if (appNotification) {
    // toastNotification(appNotification)
    dispatch(setAppNotification(null))
  }

  useEffect(() => {
    if (isAuthenticated === null) {
      onLoad()
      return
    }
  }, [isAuthenticated]) // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (isDataWarsHostName) {
      initSentry()
    }
  }, [isDataWarsHostName])

  useEffect(() => {
    const title = isDataWarsHostName ? 'DataWars Teams' : 'Teams'
    const image = isDataWarsHostName
      ? 'https://static.prd.datawars.io/static/og-images/og-image-general.png'
      : 'https://static.prd.datawars.io/static/og-images/og-image-default.png'
    const url = `https://teams.${domain}`

    document.title = title
    document.querySelector("meta[property='og:title'").setAttribute('content', title)
    document.querySelector("meta[property='og:image'").setAttribute('content', image)
    document.querySelector("meta[property='twitter:title'").setAttribute('content', title)
    document.querySelector("meta[property='twitter:image'").setAttribute('content', image)
    document.querySelector("meta[property='og:url'").setAttribute('content', url)
  }, [])

  return <Theme>{isPageLoading ? <GlobalLoader /> : <Router />}</Theme>
}

export default App
