import packageJson from '../../package.json'

const version = packageJson.version
const currentEnv = import.meta.env.REACT_APP_ENVIRONMENT
const isProduction = currentEnv === 'production'
const isDevelopment = currentEnv === 'development'
const isStaging = currentEnv === 'staging'
const shortEnv = isProduction ? 'prd' : isDevelopment ? 'dev' : ''

const hostname = window.location.hostname
const isDataWarsHostName = hostname?.endsWith('datawars.io')
const domain = hostname?.split('.').slice(-2).join('.')

const apiURL = import.meta.env.REACT_APP_API_BASE_URL?.replace('datawars.io', domain)
const platformURL = import.meta.env.REACT_APP_PLATFORM_BASE_URL?.replace('datawars.io', domain)
const djangoAdminURL = import.meta.env.REACT_APP_DJANGO_ADMIN_URL

const getPlatformURL = (accountSlug) => {
  if (isDataWarsHostName) return platformURL
  const url = platformURL.replace('app.', `${accountSlug}.`)
  return url
}

export {
  version,
  currentEnv,
  isDevelopment,
  isStaging,
  isProduction,
  shortEnv,
  isDataWarsHostName,
  domain,
  apiURL,
  djangoAdminURL,
  getPlatformURL,
}
