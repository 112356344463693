import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Input as AntdInput } from 'antd'
import { PlusSquareOutlined } from '@ant-design/icons'
import { Book24Regular } from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import Input from '@/components/Input'
import CourseIcon, { courseIcons } from '@/pages/MainPage/components/CoursesTab/components/CourseIcon'
import {
  createCollection,
  updateCollection,
  updateCollectionWizard,
  resetCollectionWizard,
} from '@/store/collections/actions'
import { Modal } from './styles'

const CourseModal = () => {
  const dispatch = useDispatch()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { collectionWizard, isCollectionsLoading } = useSelector((state) => state.collections)

  const isEditing = !!collectionWizard?.id

  const handleUpdateCollectionWizard = (field, value) => {
    dispatch(updateCollectionWizard({ [field]: value }))
  }

  const handleCloseModal = () => {
    dispatch(resetCollectionWizard())
  }

  const handleSubmit = () => {
    if (!collectionWizard?.name) {
      showToast('Course name is required', 'error')
      return
    }

    const auxCourse = {
      name: collectionWizard?.name?.trim(),
      description_md: collectionWizard?.description,
      code: collectionWizard?.code?.toUpperCase().trim(),
      icon: collectionWizard?.icon,
      status: 'published',
    }

    if (isEditing) {
      dispatch(
        updateCollection(collectionWizard?.id, auxCourse, { account_id: currentAccount?.id }, () => {
          showToast('Course updated successfully')
          handleCloseModal()
        }),
      )
    } else {
      dispatch(
        createCollection(auxCourse, { account_id: currentAccount?.id }, () => {
          showToast('Course created successfully')
          handleCloseModal()
        }),
      )
    }
  }

  useEffect(() => {
    if (collectionWizard?.isModalOpen && !collectionWizard?.icon) {
      handleUpdateCollectionWizard('icon', courseIcons[0].name)
    }
  }, [collectionWizard?.isModalOpen])

  return (
    <Modal className="course-modal" open={!!collectionWizard?.isModalOpen} onCancel={handleCloseModal} footer={null}>
      <div className="container">
        <div className="header">
          {isEditing ? (
            <>
              <Book24Regular className="icon" />
              <h3 className="title">Edit your course</h3>
            </>
          ) : (
            <>
              <Book24Regular className="icon" />
              <h3 className="title">Create a new course</h3>
            </>
          )}
        </div>

        <div className="content">
          <div className="section">
            <div className="row">
              <Input
                className="course-name-input"
                size={'large'}
                value={collectionWizard?.name}
                onChange={(evt) => {
                  const name = evt.target.value
                  const defaultCode = name?.substr(0, 3)
                  const code = defaultCode?.length === 3 ? defaultCode : 'COD'

                  handleUpdateCollectionWizard('name', name)
                  handleUpdateCollectionWizard('code', code)
                }}
                label="Name"
                fixedLabel
                placeholder="Add a course name"
                disabled={isCollectionsLoading}
              />

              <Input
                className="course-code-input"
                size={'large'}
                value={collectionWizard?.code}
                onChange={(evt) => handleUpdateCollectionWizard('code', evt.target.value)}
                label="Code"
                fixedLabel
                placeholder="Add a course code"
                disabled={isCollectionsLoading}
              />
            </div>
          </div>

          <div className="section">
            <AntdInput.TextArea
              rows={4}
              placeholder="Add a description to your course."
              maxLength={480}
              value={collectionWizard?.description}
              onChange={(e) => handleUpdateCollectionWizard('description', e.target.value)}
              style={{ marginBottom: 10 }}
            />
          </div>

          <div className="section">
            <p className="section-title">Select an icon</p>

            <div className="icons-container">
              {courseIcons?.map((iconData, i) => (
                <CourseIcon
                  key={`icon-box-${i}`}
                  iconName={iconData?.name}
                  isCurrent={collectionWizard?.icon === iconData?.name}
                  onClick={() => handleUpdateCollectionWizard('icon', iconData?.name)}
                />
              ))}
            </div>
          </div>

          <div className="actions">
            <Button className="submit-button" type="secondary" onClick={handleSubmit} loading={isCollectionsLoading}>
              <PlusSquareOutlined /> {isEditing ? 'Edit course' : 'Create course'}
            </Button>
          </div>
        </div>
      </div>
    </Modal>
  )
}

export default CourseModal
