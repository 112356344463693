import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Modal, Tooltip, Button, Tag, Dropdown, Skeleton } from 'antd'
import { ExclamationCircleOutlined, LineChartOutlined, CopyOutlined } from '@ant-design/icons'
import {
  MoreVertical24Filled,
  Archive24Regular,
  DocumentCopy24Regular,
  Options24Regular,
  ArchiveArrowBack24Regular,
  PlayCircle24Regular,
  CheckmarkCircle24Regular,
  Share24Regular,
  PersonArrowRight24Regular,
} from '@fluentui/react-icons'
import { getPlatformURL, domain, shortEnv } from '@/helpers/env'
import { showToast } from '@/utils/toast'
import { capitalize } from '@/utils/functions'
import getIconByUrl from '@/utils/getIconByUrl'
import FadeIn from '@/components/FadeIn'
import PlaygroundStatusTag from '@/components/PlaygroundStatusTag'
import { setAssignModalOpen } from '@/store/modules/actions'
import { createPlayground, updatePlayground } from '@/store/playgrounds/actions'
import { Container } from './styles'

const PlaygroundBox = ({ playground, size, disableActions, onClick, isLoading }) => {
  const dispatch = useDispatch()
  const { accountId } = useParams()

  const [mainIcon, setMainIcon] = useState(null)

  const { userProfile } = useSelector((state) => state.users)
  const { currentAccount, allUsers } = useSelector((state) => state.accounts)
  const { items: labSessions } = useSelector((state) => state.sessions)
  const { isLoading: isPlaygroundsLoading } = useSelector((state) => state.playgrounds)

  const accountMembers = allUsers?.filter((u) => u.role === 'member')

  const isCustomProject = !!playground?.metadata?.account_id
  const lastVersion = playground?.versions?.[playground?.versions?.length - 1]

  const pendingCount = playground?.copies?.filter((c) => c?.versions === 0)?.length || 0
  const completedCount = playground?.copies?.filter((c) => c?.versions > 0)?.length || 0

  const activeLabSession = labSessions?.filter(
    (s) => s?.lab_id === playground?.lab_id && s?.allocated_by?.id !== userProfile?.id,
  )?.[0]?.allocated_by
  const isOtherUserUsingLab =
    activeLabSession &&
    (activeLabSession?.first_name ? `${activeLabSession?.first_name} ${activeLabSession?.last_name}` : 'another user')

  const selfLaunchIsEnabled = currentAccount?.config?.launch?.self_launch
  // const isLTIEnabled = true

  const duplicatePlayground = () => {
    Modal.confirm({
      title: 'Duplicate custom project',
      content: 'Are you sure you want to duplicate this project? This action will create a draft copy of your project.',
      icon: <></>,
      okText: 'Yes, duplicate',
      cancelText: 'No',
      onOk: () =>
        dispatch(
          createPlayground(
            {
              playground_id: playground?.id,
              metadata: { account_id: accountId, is_student_copy: false },
              ...(!playground?.versions?.length ? { from_commit: 'last_commit' } : {}),
            },
            () => showToast('Project duplicated successfully'),
          ),
        ),
      okButtonProps: {
        type: 'primary',
        disabled: isPlaygroundsLoading,
        loading: isPlaygroundsLoading,
      },
    })
  }

  const archivePlayground = () => {
    Modal.confirm({
      title: 'Archive custom project',
      content: 'Are you sure you want to archive this project?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, archive',
      cancelText: 'No',
      onOk: () =>
        dispatch(
          updatePlayground(playground?.id, { status: 'archived' }, () => showToast('Project archived successfully')),
        ),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isPlaygroundsLoading,
        loading: isPlaygroundsLoading,
      },
    })
  }

  const unarchivePlayground = () => {
    Modal.confirm({
      title: 'Restore custom project',
      content: 'Are you sure you want to restore this project as draft?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, restore',
      cancelText: 'No',
      onOk: () =>
        dispatch(
          updatePlayground(playground?.id, { status: playground?.versions?.length ? 'published' : 'draft' }, () =>
            showToast('Project restored successfully'),
          ),
        ),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isPlaygroundsLoading,
        loading: isPlaygroundsLoading,
      },
    })
  }

  const getStatusDate = () => {
    if (playground?.status === 'draft') return `Created ${dayjs(playground?.created).from()}`

    if (playground?.status === 'published') return `Published ${dayjs(lastVersion?.created).from()}`

    if (playground?.status === 'archived') return `Archived ${dayjs(playground?.status_changed).from()}`
  }

  const playgroundMenuItems = [
    ...(playground?.status === 'published'
      ? [
          {
            key: 'assign',
            label: (
              <a
                onClick={() => {
                  dispatch(setAssignModalOpen(playground))
                }}
                className="menu-item"
              >
                <PersonArrowRight24Regular /> Assign
              </a>
            ),
          },
          {
            key: 'analytics',
            label: (
              <Link
                className="menu-item"
                to={`/accounts/${accountId}/analytics?tab=customProjects&project=${playground?.id}`}
              >
                <LineChartOutlined /> View Analytics
              </Link>
            ),
          },
        ]
      : []),
    ...(playground?.status !== 'archived'
      ? [
          {
            key: 'edit',
            label: (
              <Link
                className="menu-item"
                to={`${getPlatformURL(currentAccount?.slug)}custom-projects/${accountId}/${playground?.id}/settings`}
                target="_blank"
              >
                <Options24Regular /> Settings
              </Link>
            ),
          },
          {
            key: 'duplicate',
            label: (
              <a onClick={duplicatePlayground} className="menu-item">
                <DocumentCopy24Regular /> Duplicate
              </a>
            ),
          },
          ...(playground?.status === 'published'
            ? [
                {
                  key: 'copy-id',
                  label: (
                    <span
                      className="menu-item"
                      onClick={() => {
                        navigator.clipboard.writeText(playground?.id)
                        showToast('Project ID copied to clipboard')
                      }}
                    >
                      <CopyOutlined /> Copy project ID
                    </span>
                  ),
                },
              ]
            : []),
          ...(playground?.status === 'published' && selfLaunchIsEnabled
            ? [
                {
                  key: 'share',
                  label: (
                    <a
                      onClick={() => {
                        let url = `${getPlatformURL(currentAccount?.slug)}custom-projects/${playground?.id}`
                        navigator?.clipboard?.writeText(url)
                        showToast('Link copied to clipboard')
                      }}
                      className="menu-item"
                    >
                      <Share24Regular /> Copy project link
                    </a>
                  ),
                },
              ]
            : []),
          // ...(playground?.status === 'published' && isLTIEnabled
          //   ? [
          //       {
          //         key: 'copy-lti-url',
          //         label: (
          //           <span
          //             className="menu-item"
          //             onClick={() => {
          //               let url = `https://api.${shortEnv}.${domain}/lti/launch/?content_type=project&content_id=${playground?.id}`
          //               navigator?.clipboard?.writeText(url)
          //               showToast('Project LTI URL copied to clipboard')
          //             }}
          //           >
          //             <CopyOutlined /> Copy LTI URL
          //           </span>
          //         ),
          //       },
          //     ]
          //   : []),
          {
            key: 'archive',
            label: (
              <a onClick={archivePlayground} className="menu-item">
                <Archive24Regular /> Archive
              </a>
            ),
            danger: true,
          },
        ]
      : [
          {
            key: 'duplicate',
            label: (
              <a onClick={duplicatePlayground} className="menu-item">
                <DocumentCopy24Regular /> Duplicate
              </a>
            ),
          },
          {
            key: 'restore',
            label: (
              <a onClick={unarchivePlayground} className="menu-item">
                <ArchiveArrowBack24Regular /> Restore
              </a>
            ),
            danger: true,
          },
        ]),
  ]

  useEffect(() => {
    if (!playground) return

    getIconByUrl({
      iconUrl: playground?.data_sources?.[0]?.theme?.logo_url || 'ant-FileTextOutlined',
      className: 'main-icon',
      onReady: setMainIcon,
    })
  }, [playground])

  if (isLoading || !playground?.name)
    return (
      <Container className="playground-box is-loading" $size={size}>
        <div className="main-content">
          <Skeleton className="left-side" active avatar title={false} paragraph={{ rows: 0 }} />
          <Skeleton className="right-side" active title={false} paragraph={{ rows: size === 'small' ? 1 : 2 }} />
        </div>
      </Container>
    )

  return (
    <Container
      className="playground-box"
      $size={size}
      $playgroundBackgroundImage={playground?.image_url}
      $isClickable={!!onClick}
      onClick={onClick ? () => onClick(playground) : null}
    >
      <FadeIn>
        <div className="main-content">
          <div className="image-container">{mainIcon}</div>

          <div className="content">
            <div className="header">
              <div className="title-container">
                {onClick ? (
                  <h5 className="title">{playground?.name}</h5>
                ) : (
                  <Link
                    className="menu-item"
                    to={`${getPlatformURL(currentAccount?.slug)}custom-projects/${playground?.id}`}
                    target="_blank"
                  >
                    <h5 className="title">{playground?.name}</h5>
                  </Link>
                )}

                {!disableActions && playground?.user?.first_name && playground?.user?.last_name && (
                  <div className="author-info">
                    Created by{' '}
                    <span>
                      {capitalize(playground?.user?.first_name)} {capitalize(playground?.user?.last_name)}
                    </span>
                  </div>
                )}
              </div>

              <div className="info-box">
                <div className="members-status-container">
                  <Tooltip title={getStatusDate}>
                    <div>
                      <PlaygroundStatusTag
                        status={playground?.status}
                        version={playground?.versions?.length}
                        visibility={playground?.visibility}
                        isCustomProject={isCustomProject}
                      />
                    </div>
                  </Tooltip>

                  {!disableActions && playground?.status === 'published' && (
                    <div className="stats-container">
                      <Tooltip title="Working on it">
                        <div className="item">
                          <PlayCircle24Regular /> {pendingCount}
                        </div>
                      </Tooltip>

                      <Tooltip title="Completed">
                        <div className="item">
                          <CheckmarkCircle24Regular /> {completedCount}/{accountMembers?.length}
                        </div>
                      </Tooltip>
                    </div>
                  )}
                </div>
              </div>

              {isOtherUserUsingLab && (
                <Tag className="info-tag">
                  This {isCustomProject ? 'project' : 'playground'} is being edited by {isOtherUserUsingLab}.
                </Tag>
              )}
            </div>
          </div>

          <div className="extra-container">
            {!disableActions && (
              <Dropdown
                overlayClassName={'dropdown-menu'}
                trigger={['click']}
                menu={{
                  items: playgroundMenuItems,
                  onClick: ({ domEvent }) => domEvent.stopPropagation(),
                }}
                onClick={(e) => e.stopPropagation()}
              >
                {playgroundMenuItems?.length ? (
                  <Button className="open-options-button" type="text" shape="circle" icon={<MoreVertical24Filled />} />
                ) : (
                  <></>
                )}
              </Dropdown>
            )}
          </div>
        </div>
      </FadeIn>
    </Container>
  )
}

export default PlaygroundBox
