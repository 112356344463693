import styled, { css, keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  background: ${(props) => props.theme.colors.gray1};
  display: flex;
  flex-direction: column;
  gap: 10px;
  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
  padding: 16px;
  box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.gray7};

  .activity-main-content {
    min-height: 72px;
    position: relative;

    ${(props) =>
      props.isReview &&
      css`
        display: flex;
        justify-content: space-between;
      `}

    .activity-main-body {
      max-width: calc(100% - 100px);
      flex: 1;

      .ai-graded-tag {
        width: fit-content;
        background:
          radial-gradient(217.52% 153.59% at 122.4% 118.47%, #9196ff 47.5%, #ac8fff 73%, #9eb4fe 94.27%), #f0f2f5;
        color: ${(props) => props.theme.colors.gray1};
        display: flex;
        align-items: center;
        gap: 5px;
        font-size: 12px;
        border-radius: 25px;
        padding: 5px 14px;
        margin-bottom: 16px;

        .icon {
          height: 20px;
          width: 20px;
        }
      }

      .activity-content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        animation: 1s ${fadeInAnimation};

        .activity-body {
          display: flex;
          justify-content: space-between;
          gap: 10px;

          .activity-number {
            color: ${(props) => props.theme.colors.gray10};
            height: 30px;
            max-width: 30px;
            width: 100%;
            background-color: ${(props) => props.theme.colors.gray4};
            font-size: 12px;
            font-weight: 400;
            display: flex;
            border-radius: 50%;
            align-items: center;
            justify-content: center;
            margin-top: -2px;
          }

          .title-container {
            flex: 1;

            p {
              color: ${(props) => props.theme.colors.gray10};
              font-size: 15px;
              font-weight: 700;
              line-height: 22px;
            }
          }
        }

        .activity-content-markdown {
          display: grid;

          p,
          ul li {
            color: ${(props) => props.theme.colors.gray8};
            font-size: 13px;
            font-weight: 400;
          }

          pre,
          code {
            font-size: 12px;
          }

          .markdown-block {
            overflow: auto;

            p {
              margin: 0px 0px 10px;
            }
          }
        }

        .activity-expected-outcome-container {
          border-radius: 6px;
          border: 1px solid ${(props) => props.theme.colors.gray4};
          padding: 6px 10px;
          margin-bottom: 14px;

          .section-title {
            color: ${(props) => props.theme.colors.gray8};
            font-size: 10px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0px;
          }

          p,
          ul li {
            color: ${(props) => props.theme.colors.gray8};
            font-size: 13px;
            font-weight: 400;
          }

          pre,
          code {
            font-size: 12px;
          }

          .markdown-block {
            overflow: auto;
          }
        }
      }
    }
  }

  &.submitted {
    background: ${(props) => props.theme.colors.geekblue1}33;
    box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.geekblue4};

    .activity-main-content .activity-main-body .activity-content .activity-body .activity-number {
      background-color: ${(props) => props.theme.colors.geekblue3};
    }
  }

  &.correct {
    background: ${(props) => props.theme.colors.cyan1}33;
    box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.cyan6};

    .activity-main-content .activity-main-body .activity-content .activity-body .activity-number {
      background-color: ${(props) => props.theme.colors.cyan3};
    }
  }

  &.incorrect {
    background: ${(props) => props.theme.colors.red1}3a;
    box-shadow: inset 0px 2px 0px ${(props) => props.theme.colors.red4};

    .activity-main-content .activity-main-body .activity-content .activity-body .activity-number {
      color: ${(props) => props.theme.colors.base.gray1};
      background-color: ${(props) => props.theme.colors.red4};
    }
  }

  .info-block {
    color: ${(props) => props.theme.colors.gray9};
    background-color: ${(props) => props.theme.colors.gray4};
    border-radius: 6px;
    overflow: auto;
    padding: 12px;
    margin: 8px 0px;

    .code-toolbar {
      margin-bottom: 12px;

      > pre {
        background-color: ${(props) => props.theme.colors.gray2};
      }
    }

    p,
    ul li {
      color: ${(props) => props.theme.colors.gray8};
      font-size: 13px;
      font-weight: 400;
    }

    pre,
    code {
      font-size: 12px;
    }

    .markdown-block {
      overflow: auto;
    }

    &.error {
      background-color: ${(props) => props.theme.colors.red1};
      color: ${(props) => props.theme.colors.red5};
    }

    &.out {
      background-color: ${(props) => props.theme.colors.gray3};
    }

    &.comments {
      background-color: ${(props) => props.theme.colors.gray3};
      white-space: normal;
    }
  }

  .activity-actions {
    display: flex;
    justify-content: space-between;
    gap: 6px;
    margin-bottom: 10px;

    button {
      background-color: transparent;

      &.is-selected {
        background-color: ${(props) => props.theme.colors.gray4};
        color: ${(props) => props.theme.colors.gray10};
        border: none;
      }

      &.danger {
        color: ${(props) => props.theme.colors.base.gold6};
        border-color: ${(props) => props.theme.colors.base.gold6};
      }
    }
  }

  .activity-input {
    color: ${(props) => props.theme.colors.gray12};
    background-color: ${(props) => props.theme.colors.gray1};
  }

  .activity-answers-group {
    display: flex;
    flex-direction: column;
    animation: 1s ${fadeInAnimation};

    > label {
      width: 100%;
      margin-bottom: 8px;

      > span:nth-child(2) {
        width: 100%;
        overflow: auto;
      }

      p {
        margin: 0px;
      }
    }
  }

  .error-text {
    color: #fa541c;
    font-size: 12px;
    margin: -12px 0px 12px;
  }

  .activity-status-btn {
    background-color: transparent !important;
    color: ${(props) => props.theme.colors.gray7};
    margin-left: 8px;
    cursor: default;

    &:hover,
    &:focus,
    &:active {
      color: ${(props) => props.theme.colors.text} !important;
      border-color: ${(props) => props.theme.colors.gray9} !important;
    }

    .ant-wave {
      display: none;
    }

    &.submitted {
      color: ${(props) => props.theme.colors.base.geekblue5} !important;
      border-color: ${(props) => props.theme.colors.base.geekblue5} !important;

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.base.geekblue5} !important;
        border-color: ${(props) => props.theme.colors.base.geekblue5} !important;
      }
    }

    &.correct {
      color: ${(props) => props.theme.colors.base.cyan7} !important;
      border-color: ${(props) => props.theme.colors.base.cyan7} !important;

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.base.cyan7} !important;
        border-color: ${(props) => props.theme.colors.base.cyan7} !important;
      }
    }

    &.incorrect {
      color: ${(props) => props.theme.colors.base.red5} !important;
      border-color: ${(props) => props.theme.colors.base.red5} !important;

      &:hover,
      &:focus,
      &:active {
        color: ${(props) => props.theme.colors.base.red5} !important;
        border-color: ${(props) => props.theme.colors.base.red5} !important;
      }
    }

    &.not-actioned {
      color: rgba(0, 0, 0, 0.3) !important;
      border-color: rgba(0, 0, 0, 0.3) !important;

      &:hover,
      &:focus,
      &:active {
        color: rgba(0, 0, 0, 0.3) !important;
        border-color: rgba(0, 0, 0, 0.3) !important;
      }
    }
  }

  .review-status-block {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;

    ${(props) =>
      props.isReview &&
      `
      align-items: flex-end;
    `}

    .submit-info {
      color: ${(props) => props.theme.colors.gray7};
      font-size: 11px;
      margin: 0px;
    }
  }

  .feedback-box {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    user-select: none;

    .feedback-btn {
      color: ${(props) => props.theme.colors.gray7};
      display: flex;
      gap: 4px;
      align-items: center;
      font-size: 11px;
      font-weight: 400;
      padding: 0px;
      margin: 0px;
      cursor: pointer;
      transition: 0.3s all;

      &:hover {
        color: ${(props) => props.theme.colors.gray7};
      }
    }
  }

  .activity-footer-actions {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border-top: 1px solid ${(props) => props.theme.colors.gray4};
    padding-top: 14px;
    margin-top: 20px;

    .footer-item {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 12px;
      padding: 12px;
      cursor: pointer;

      &:hover {
        background: ${(props) => props.theme.colors.gray2};
      }

      &:not(:last-of-type) {
        border-right: 1px solid ${(props) => props.theme.colors.gray4};
      }

      > span {
        color: ${(props) => props.theme.colors.gray7};
      }

      .anticon {
        color: ${(props) => props.theme.colors.gray8};
        font-size: 16px;
      }
    }
  }

  @media ${device.mdDown} {
    flex-direction: column;
    gap: 12px;
    padding: 18px 12px;

    .activity-container {
      max-width: initial;
    }

    .activity-status-btn {
      margin: 0px;
    }

    .review-status-block {
      flex-direction: row-reverse;
      align-items: center;
      justify-content: flex-start;

      .activity-actions {
        margin-bottom: 0px;
      }
    }
  }
`
