import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useSearchParams } from 'react-router-dom'
import { Tabs } from 'antd'
import Button from '@/components/Button'
import MembersTable from '../MembersTable'
import PendingInvitesTable from '../PendingInvitesTable'
import TeamsDropdown from '../TeamsDropdown'
import { setInviteModalOpen } from '@/store/accounts/actions'
import { setMembersTab, setSelectedUsers, setUserSearchText } from '@/store/accounts/actions'
import { Container } from './styles'

const MembersTab = () => {
  const dispatch = useDispatch()
  let [searchParams, setSearchParams] = useSearchParams()

  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const { currentAccount: account, selectedMembersTab } = useSelector((state) => state.accounts)

  const maxMembers = currentSubscription?.available_features?.account_members?.limit
  const enrolledMembers = currentSubscription?.available_features?.account_members?.current_value

  const inviteFulfillmentIsEnabled = account?.config?.fulfillment?.invite

  const tab = searchParams.get('tab')

  const tabItems = [
    {
      key: 'active',
      label: 'Active members',
      children: (
        <div className="tab-content">
          <MembersTable />
        </div>
      ),
    },
    ...(inviteFulfillmentIsEnabled
      ? [
          {
            key: 'pending',
            label: 'Pending invites',
            children: (
              <div className="tab-content">
                <PendingInvitesTable />
              </div>
            ),
          },
        ]
      : []),
  ]

  const handleChangeTab = (tab) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tab })
  }

  useEffect(() => {
    dispatch(setUserSearchText(''))

    const isValidTab = ['active', 'pending'].includes(tab)

    if (tab && tab !== selectedMembersTab && isValidTab) {
      dispatch(setMembersTab(tab))
      return
    }

    if (!tab || !isValidTab) {
      dispatch(setMembersTab('active'))
    }
  }, [tab])

  useEffect(() => {
    return () => {
      dispatch(setUserSearchText(''))
      dispatch(setSelectedUsers([]))
    }
  }, [])

  return (
    <Container>
      <div className="header-container">
        <div className="info">
          <div className="title-container ">
            <h4 className="title">
              {tab === 'active' ? 'Members' : 'Invites'} <span className="divider">/</span>
            </h4>{' '}
            <TeamsDropdown
              label={!tab || tab === 'active' ? 'Members' : 'Invites'}
              showAllOption
              showNotInTeamsOption
            />
          </div>

          {(tab === 'active' || !tab) && (
            <p className="text">
              {enrolledMembers}/{maxMembers} enrolled members
            </p>
          )}
        </div>

        {inviteFulfillmentIsEnabled && (
          <Button type="secondary" onClick={() => dispatch(setInviteModalOpen(true))}>
            Invite members
          </Button>
        )}
      </div>

      <div className="content">
        <Tabs activeKey={selectedMembersTab} items={tabItems} onChange={handleChangeTab} />
      </div>
    </Container>
  )
}

export default MembersTab
