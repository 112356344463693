import Input from '@/components/Input'
import CodeEditor from '@/components/CodeEditor'
import MarkdownEditor from '@/components/MarkdownEditor'
import { Container } from './styles'

const ActivityWidget = ({
  widgetType,
  widgetMetadata,
  answer,
  setAnswer,
  label = 'Your answer',
  placeholder = 'Enter your answer',
  disabled = false,
}) => {
  const renderWidget = () => {
    if (widgetType === 'text' && !widgetMetadata?.rich_text_enabled) {
      return (
        <Input
          className="activity-input"
          label={label}
          value={answer}
          placeholder={placeholder}
          size="large"
          onChange={(evt) => setAnswer(evt.target.value)}
          disabled={disabled}
        />
      )
    }

    if (widgetType === 'text' && widgetMetadata?.rich_text_enabled) {
      return (
        <>
          <MarkdownEditor
            className="activity-markdown-editor"
            content={answer}
            onChange={setAnswer}
            placeholder={placeholder}
            readOnly={disabled}
          />
        </>
      )
    }

    if (widgetType === 'code') {
      return (
        <CodeEditor
          className="activity-code-editor"
          language={widgetMetadata?.code_language}
          value={answer}
          setValue={setAnswer}
          placeholder={placeholder}
          readOnly={disabled}
        />
      )
    }

    if (widgetType === 'latex') {
      return (
        <Input
          className="activity-mathlive-input"
          type={'mathlive'}
          label={label}
          value={answer}
          placeholder="Enter \ your \ answer \ (e.g., \ f(x) = 2 + x)"
          onChange={(evt) => setAnswer(evt.target.value)}
          disabled={disabled}
        />
      )
    }
  }

  return <Container className="activity-widget">{renderWidget()}</Container>
}

export default ActivityWidget
