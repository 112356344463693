import { Link } from 'react-router-dom'
import Button from '@/components/Button'
import ErrorImage from '@/assets/images/error.gif'
import NoResults from '@/assets/images/no-results.svg'
import { Container } from './styles'

const ErrorCard = ({
  title,
  text,
  primaryButtonText = 'Go to Dashboard',
  primaryButtonLinkTo = '/',
  buttonText,
  buttonOnClick,
  bottomText,
  bottomOnClick,
}) => {
  const isNotFoundPage = text === 'Not Found'

  const errorTitle = isNotFoundPage ? 'Go to your account login page' : title

  return (
    <Container className="error-card-container">
      <img src={isNotFoundPage ? NoResults : ErrorImage} alt={errorTitle} />

      <h3 className="title">{errorTitle}</h3>
      {!!text && !isNotFoundPage && <p className="text-body">{text}</p>}

      {!isNotFoundPage && (
        <div className="actions">
          <Link to={primaryButtonLinkTo}>
            <Button className="button" type="primary">
              {primaryButtonText}
            </Button>
          </Link>

          {!!buttonText && (
            <Button className="button" onClick={buttonOnClick}>
              {buttonText}
            </Button>
          )}
        </div>
      )}

      {!!bottomText && (
        <p className="bottom-text" onClick={bottomOnClick}>
          {bottomText}
        </p>
      )}
    </Container>
  )
}

export default ErrorCard
