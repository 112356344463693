import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import CustomProjectsEmptyState from '@/assets/images/empty-state-custom-projects.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 20px;

  > .body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0px;

    > .content {
      height: 100%;
      max-width: 1440px;
      width: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      overflow: auto;
      padding: 10px;
      margin: 0px;

      .empty-state-content {
        background: url(${CustomProjectsEmptyState}) no-repeat center center;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 20px;
        padding: 0px 60px;
        animation: 1s ${fadeInAnimation};

        > .header {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: flex-end;
          text-align: center;
          padding: 24px 36px;

          .container {
            max-width: 340px;

            .icon {
              font-size: 24px;
              color: ${(props) => props.theme.colors.geekblue5};
              margin-bottom: 10px;
            }

            .title {
              margin: 0px;
              line-height: 28px;
              margin-bottom: 0px;
            }

            .text {
              color: ${(props) => props.theme.colors.gray7};
              font-weight: 400;
              margin: 6px 0px 0px;
            }

            > button {
              margin-top: 16px;
            }
          }
        }
      }

      .ant-collapse-header {
        height: 56px;
        align-items: center;
      }

      .unit-container {
        border-radius: 0px;

        .unit-header {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;

          .unit-name {
            font-weight: 700;
            margin: 0px;
          }

          .actions {
            display: flex;
            align-items: center;
            gap: 10px;

            button {
              background-color: ${(props) => props.theme.colors.gray1};
            }
          }
        }

        &:not(:last-child) {
          border-bottom: none;
        }

        .lesson-container {
          background-color: ${(props) => props.theme.colors.gray2};
          border-radius: 0px;

          .lesson-header {
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 10px;

            .lesson-name {
              margin: 0px;
            }

            .actions {
              display: flex;
              align-items: center;
              gap: 10px;

              button {
                background-color: ${(props) => props.theme.colors.gray1};
              }
            }
          }

          .ant-collapse-content-box {
            padding: 0px;

            .projects-container {
              .project-content {
                display: flex;

                > div {
                  border-bottom: 1px solid ${(props) => props.theme.colors.gray4};
                  border-radius: 0px;

                  .main-content {
                    padding: 10px 30px;
                  }
                }

                .actions {
                  background-color: ${(props) => props.theme.colors.gray1};
                  display: flex;
                  align-items: center;
                  gap: 10px;
                  padding-right: 30px;

                  button {
                    background-color: ${(props) => props.theme.colors.gray1};
                  }
                }
              }
            }

            .lesson-footer {
              background-color: ${(props) => props.theme.colors.gray1};
              padding-top: 12px;
              padding-left: 24px;
            }
          }

          &:not(:last-child) {
            margin-bottom: 20px;
          }
        }

        .unit-footer {
        }

        .no-results-box {
          display: flex;
          flex-direction: column;
          padding: 12px 20px;

          & > img {
            user-select: none;
            pointer-events: none;
          }

          .title {
            margin-bottom: 6px;
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            font-weight: 500;
          }
        }
      }

      .collection-footer {
      }

      .loading-container {
        border: 1px solid ${(props) => props.theme.colors.gray5};

        .ant-skeleton {
          .ant-skeleton-paragraph {
            margin: 0px;
          }
        }

        > .header {
          height: 56px;
          background: ${(props) => props.theme.colors.gray3};
          display: flex;
          align-items: center;
          padding: 0px 16px;
        }

        > .body {
          padding: 16px;

          .sub-header {
            height: 56px;
            background: ${(props) => props.theme.colors.gray2};
            display: flex;
            align-items: center;
            padding: 0px 16px;
          }
        }
      }
    }

    .add-content-block {
      background-color: ${(props) => props.theme.colors.gray2};
      display: flex;
      align-items: center;
      gap: 8px;
      border: 1px dashed ${(props) => props.theme.colors.gray5};
      padding: 14px 16px;
      cursor: pointer;
      user-select: none;

      .icon {
        color: ${(props) => props.theme.colors.gray7};
      }

      .text {
        color: ${(props) => props.theme.colors.gray6};
        font-weight: 500;
        margin: 0px;
        transition: 0.3s all;
      }

      &:hover {
        background-color: ${(props) => props.theme.colors.gray4};

        .text {
          color: ${(props) => props.theme.colors.gray10};
        }
      }
    }
  }

  @media ${device.mdDown} {
    padding: 0px;
  }
`
