import { useState } from 'react'
import { InstantSearch, SearchBox, Configure, useInstantSearch, useConfigure } from 'react-instantsearch'
import { Drawer } from 'antd'
import { SearchOutlined, FilterOutlined, ArrowRightOutlined } from '@ant-design/icons'
import { getAlgoliaSearchClient } from '@/utils/algolia'
import Button from '@/components/Button'
import LabFilters from './components/LabFilters'
import Results from './components/Results'
import searchParameters from './searchParameters'
import { Container } from './styles'

const ModulesCatalogContent = ({ disableItemActions, onItemClick, onItemClickText }) => {
  const { results, status, indexUiState, setIndexUiState } = useInstantSearch()

  const currentSearchParameters = indexUiState?.configure
  const { refine } = useConfigure({ ...currentSearchParameters })

  const [page, setPage] = useState(1)
  const [isFilterOpen, setFiltersOpen] = useState(false)

  return (
    <>
      <div className="catalog-header">
        <div className="search-box">
          <SearchBox placeholder="Search projects here..." />
          <SearchOutlined className="icon" />
        </div>

        <Button icon={<FilterOutlined />} onClick={() => setFiltersOpen(true)}>
          Filters
        </Button>
      </div>

      <div className="catalog-body">
        <div className="content">
          <Results
            results={results}
            status={status}
            indexUiState={indexUiState}
            refine={refine}
            page={page}
            setPage={setPage}
            disableItemActions={disableItemActions}
            onItemClick={onItemClick}
            onItemClickText={onItemClickText}
          />
        </div>

        <Drawer
          rootClassName="modules-catalog-filters-drawer-root"
          title={
            <div className="title-container">
              <FilterOutlined />
              <h5 className="title">Filters</h5>
            </div>
          }
          open={isFilterOpen}
          onClose={() => setFiltersOpen(false)}
          closeIcon={<ArrowRightOutlined />}
          mask={false}
          getContainer={false}
        >
          <LabFilters status={status} indexUiState={indexUiState} setIndexUiState={setIndexUiState} />
        </Drawer>
      </div>
    </>
  )
}

const ModulesCatalog = ({ disableItemActions, onItemClick, onItemClickText }) => {
  const algoliaSearchClient = getAlgoliaSearchClient()

  return (
    <Container className="modules-catalog">
      <InstantSearch
        searchClient={algoliaSearchClient}
        indexName={import.meta.env.REACT_APP_ALGOLIA_MODULES_INDEX}
        // FIXME: be ready for next Algolia update
        future={{
          preserveSharedStateOnUnmount: true,
        }}
      >
        <Configure {...searchParameters} />

        <ModulesCatalogContent
          disableItemActions={disableItemActions}
          onItemClick={onItemClick}
          onItemClickText={onItemClickText}
        />
      </InstantSearch>
    </Container>
  )
}

export default ModulesCatalog
