import ProBadge from '@/assets/images/plans/pro-badge.png'
import { Container } from './styles'

const ProLabel = ({ hideBadge, isBasic }) => {
  return (
    <Container className="pro-label" $isBasic={isBasic}>
      {!isBasic && !hideBadge && <img className="pro-badge" src={ProBadge} alt="PRO user" />}
      <span>{isBasic ? 'Basic' : 'PRO'}</span>
    </Container>
  )
}

export default ProLabel
