import styled, { css } from 'styled-components'
import { Modal as AntModal } from 'antd'

export const Modal = styled(AntModal)`
  max-height: initial;
  height: 100%;
  max-width: 100% !important;
  width: 100% !important;
  top: 0px;
  padding: 16px !important;
  margin: 0px auto;

  .ant-modal-content {
    height: 100% !important;
    width: 100%;
    background-color: ${(props) => props.theme.colors.basePageBg} !important;
    top: 0;
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .container {
      width: 100%;
      max-width: 540px;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      overflow: auto;

      > .header {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 12px;
        margin: 30px 0 20px;

        .icon {
          width: 35px;
          height: 35px;
          color: ${(props) => props.theme.colors.geekblue5};
          font-size: 35px;

          &.success {
            color: ${(props) => props.theme.colors.cyan5};
          }

          &.error {
            color: ${(props) => props.theme.colors.red6};
          }
        }

        .title {
          max-width: 290px;
          font-weight: 500;
          text-align: center;
          margin: 0px;
        }

        .team-name {
          text-transform: capitalize;
        }

        .text {
          color: ${(props) => props.theme.colors.gray8};
          margin: 0px;
        }
      }

      > .content {
        display: flex;
        flex-direction: column;
        gap: 24px;

        .divider {
          width: 100%;
          border-top: 1px solid ${(props) => props.theme.colors.gray4};
        }

        .section {
          .row {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
            gap: 20px;

            > div {
              width: 100%;
            }
          }

          .section-title {
            font-weight: 500;
          }
        }

        .course-code-input {
          text-transform: uppercase;

          &::placeholder {
            text-transform: none;
          }
        }

        .icons-container {
          display: flex;
          align-items: center;
          justify-content: flex-start;
          flex-wrap: wrap;
          gap: 12px;
        }

        .members-table {
          max-height: 220px;
          border: 1px solid ${(props) => props.theme.colors.gray5};
          overflow: auto;
        }
      }

      .actions {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        gap: 10px;
        margin-top: 12px;

        &.center {
          justify-content: center;
        }

        .submit-button {
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.basePageBg};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }
`
