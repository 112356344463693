import styled, { css } from 'styled-components'
import { Modal as AntdModal } from 'antd'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 120px;
  width: 100%;
  background-color: ${(props) => props.theme.colors.gray1};
  position: relative;
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  transition: 0.3s all;

  .fade-in-mask {
    display: flex;
    flex-direction: column;
  }

  &.is-loading {
    .main-content {
      display: flex;
      flex-direction: row;
      gap: 22px;
      padding: 0px 20px;
      animation: none;
    }

    .left-side {
      width: 80px;
      display: flex;
      align-items: center;

      .ant-skeleton-avatar {
        width: 80px;
        height: 80px;
        border-radius: 16px;
      }
    }

    .right-side {
      display: flex;
      align-items: center;
    }
  }

  ${(props) =>
    props.$hasCustomOnClick &&
    css`
      cursor: pointer;

      &:hover {
        background-color: ${(props) => props.theme.colors.gray2};
      }
    `};

  ${(props) =>
    props.$size === 'small' &&
    css`
      height: 70px;

      &.is-loading {
        .main-content {
          gap: 12px;

          .left-side {
            width: 48px;
            display: flex;
            align-items: center;

            .ant-skeleton-avatar {
              width: 48px;
              height: 48px;
              border-radius: 16px;
            }
          }
        }
      }
    `};

  ${(props) =>
    props.$border &&
    css`
      border: 1px solid ${(props) => props.theme.colors.gray4};
    `}
`

export const Content = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  gap: 22px;
  padding: 18px 20px;

  .module-image {
    min-width: 78px;
    width: 78px;
    height: auto;
    opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
    image-rendering: auto;
    pointer-events: none;
    user-select: none;
  }

  .module-info {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between;
    gap: 6px;
    overflow: auto;

    .title-container {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 10px;

      .module-title {
        height: initial;
        max-height: 48px;
        color: ${(props) => props.theme.colors.gray11};
        font-weight: 700;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin: 0px;

        ${(props) =>
          !props.$hasCustomOnClick &&
          !props.$disableDetailModal &&
          css`
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          `}
      }
    }

    .info-box {
      display: flex;
      align-items: center;
      gap: 10px;
    }

    .early-access-badge {
      background: linear-gradient(
        84deg,
        ${(props) => props.theme.colors.base.gray10} 24.3%,
        #5479f7 67.59%,
        #89d2c2 99.33%
      );
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      padding: 0px 7px;

      > span {
        color: ${(props) => props.theme.colors.base.gray1};
        font-size: 12px;
        font-weight: 400;
        line-height: 20px;
      }

      .pro-badge {
        width: 14px;
      }
    }
  }

  .extra-container {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: space-between;

    .open-options-button {
      color: ${(props) => props.theme.colors.gray8};
    }
  }

  ${(props) =>
    props.$size === 'small' &&
    css`
      gap: 12px;
      padding: 10px 15px;

      .module-image {
        min-width: 48px;
        width: 48px;
      }

      .module-info {
        flex-direction: row;
        align-items: center;

        .title-container {
          .module-title {
            font-size: 14px;
            font-weight: 600;
          }
        }
      }

      .extra-container {
        justify-content: center;
      }
    `};

  ${(props) =>
    props.$draft &&
    css`
      .module-image {
        filter: saturate(0);
        opacity: 0.45;
      }

      .module-info {
        .module-title {
          color: ${props.theme.colors.gray7} !important;
        }
      }
    `}

  .tags-container {
    max-width: 100%;
    display: flex;
    align-items: center;
    gap: 4px;
    overflow: auto;

    .tag {
      background-color: ${(props) => props.theme.colors.base.gray7};
      color: ${(props) => props.theme.colors.base.gray1};
      text-transform: capitalize;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      gap: 6px;
      margin: 0px;

      > img {
        width: initial;
      }

      &.module-type {
        color: ${(props) => props.theme.colors.base.gray1};
        background-color: ${(props) => props.theme.colors.base.gray10};
      }

      &.difficulty-easy {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.green2};
      }

      &.difficulty-medium {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.gold3};
      }

      &.difficulty-hard {
        color: ${(props) => props.theme.colors.base.gray9};
        background-color: ${(props) => props.theme.colors.base.red2};
      }
    }
  }

  @media ${device.mdDown} {
    .module-header {
      flex-direction: column;
      align-items: flex-start;
      gap: 6px;
    }
  }
`

export const Modal = styled(AntdModal)`
  max-width: 600px;
  width: 100% !important;

  .modal {
    gap: 24px;
    padding: 12px 0px;

    .module-image {
      min-width: 124px;
      width: 134px;
    }

    .pre-title {
      display: flex;
      align-items: center;
      gap: 8px;
      margin-bottom: 4px;

      .la-bullet {
        min-width: 12px;
        width: 12px;
        height: 12px;
        background-color: ${(props) => props.theme.colors.gray7};
        display: inline-block;
        border-radius: 50%;
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-size: 12px;
        line-height: 16px;
        margin: 0px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .module-info {
      .module-title {
        height: initial;
        max-height: initial;
        font-weight: 700;
        display: block;
        overflow: initial;
        margin: 0px 0px 8px;
      }

      .rating {
        color: ${(props) => props.theme.colors.gray8};
        font-weight: 500;
        display: flex;
        gap: 4px;
        margin-top: 8px;

        .subtext {
          color: ${(props) => props.theme.colors.gray8};
          font-size: 12px;
          font-weight: 400;
          margin: 0px;
        }

        .ant-rate-star {
          margin-inline-end: 3px;

          svg {
            font-size: 18px;
          }
        }
      }
    }
  }
`

export const ExtraContent = styled.div`
  .module-description {
    color: ${(props) => props.theme.colors.gray8};
    margin-bottom: 12px;
  }

  .module-bottom {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    border-top: 1px solid ${(props) => props.theme.colors.gray4};
    padding-top: 14px;
    margin-top: 12px;

    .module-author {
      display: flex;
      align-items: center;
      gap: 10px;

      .ant-avatar {
        width: 32px;
        box-shadow: 0px 0px 3px 1px rgba(0, 0, 0, 0.1);
      }

      .name {
        font-size: 12px;
        line-height: 20px;
        margin: 0px;
      }
    }

    .actions {
      display: flex;
      gap: 6px;

      svg {
        width: 16px;
      }
    }
  }

  @media ${device.mdDown} {
    .module-bottom {
      flex-direction: column;
      align-items: center;
      gap: 10px;
    }
  }
`
