import {
  SET_CUSTOM_MODULE_WIZARD_UPDATED,
  RESET_CUSTOM_MODULE_WIZARD_STATE,
  SET_MODULE_LOADING,
  MODULES_UPDATED,
  MODULE_UPDATED,
  CUSTOM_MODULES_UPDATED,
  SET_ASSIGN_MODAL_OPEN,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
} from '@/store/modules'
import { ATTEMPTS_ANALYTICS_UPDATED } from '@/store/accounts'
import {
  getAlgoliaModules,
  postModuleAttemptComment,
  patchModuleAttemptComment,
  deleteModuleAttemptComment,
} from '@/services/modules'
import { getCustomModules, postCustomModule, patchCustomModule } from '@/services/customModules'
import { addCollectionContent } from '@/store/collections/actions'

const updateCustomModuleWizard = (data) => async (dispatch, getState) => {
  const { customModuleWizard } = getState().modules

  try {
    dispatch(SET_CUSTOM_MODULE_WIZARD_UPDATED({ ...customModuleWizard, ...data }))
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULE_WIZARD_UPDATED({ ...customModuleWizard, error: message }))
  }
}

const resetCustomModuleWizard = () => async (dispatch, getState) => {
  const { customModuleWizard } = getState().modules

  try {
    dispatch(RESET_CUSTOM_MODULE_WIZARD_STATE())
  } catch (error) {
    const { message } = error
    dispatch(SET_CUSTOM_MODULE_WIZARD_UPDATED({ ...customModuleWizard, error: message }))
  }
}

// const fetchModules = (params) => async (dispatch) => {
//   try {
//     dispatch(SET_MODULE_LOADING(true))

//     const filter = params?.ids ? params?.ids?.map((id) => `objectID:${id}`)?.join(' OR ') : 'objectID:no-results'
//     const modules = await getAlgoliaModules(filter)

//     dispatch(MODULES_UPDATED(modules))
//   } catch (error) {
//     const { message } = error
//     dispatch(SET_MODULE_ERROR(message))
//   } finally {
//     dispatch(SET_MODULE_LOADING(false))
//   }
// }

const fetchCustomModules = (params) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const customModules = await getCustomModules(params)
    dispatch(CUSTOM_MODULES_UPDATED(customModules))
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const createCustomModule = (customModuleData, params, onSuccess, onError) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { customModulesData } = getState().modules
    const { currentCollection, lessonSelected } = getState().collections

    const newCustomModule = await postCustomModule(customModuleData, params)

    // Add custom module to the list of custom modules on Content section
    if (customModulesData?.results) {
      const auxCustomModules = {
        ...customModulesData,
        count: customModulesData?.count + 1,
        results: [newCustomModule, ...customModulesData?.results],
      }

      dispatch(CUSTOM_MODULES_UPDATED(auxCustomModules))
    }

    // Add custom module to the list collection content on Courses section
    if (currentCollection) {
      const auxItem = { ...newCustomModule, type: 'custom_module' }
      dispatch(addCollectionContent(auxItem))
    }

    if (onSuccess) {
      onSuccess(newCustomModule)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))

    if (onError) {
      onError(message)
    }
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const updateCustomModule =
  (customModuleId, customModuleData, params, onSuccess, onError) => async (dispatch, getState) => {
    try {
      dispatch(SET_MODULE_LOADING(true))

      const { customModulesData } = getState().modules

      const updatedCustomModule = await patchCustomModule(customModuleId, customModuleData, params)

      const auxCustomModules = {
        ...customModulesData,
        results: customModulesData?.results.map((m) => (m.id === customModuleId ? updatedCustomModule : m)),
      }

      dispatch(CUSTOM_MODULES_UPDATED(auxCustomModules))

      if (onSuccess) {
        onSuccess(updatedCustomModule)
      }
    } catch (error) {
      const { message } = error
      dispatch(SET_MODULE_ERROR(message))

      if (onError) {
        onError(message)
      }
    } finally {
      dispatch(SET_MODULE_LOADING(false))
    }
  }

const fetchModule = (moduleId, cb) => async (dispatch) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const modules = await getAlgoliaModules(`objectID:${moduleId}`)
    dispatch(MODULE_UPDATED(modules?.[0]))

    if (cb) {
      cb()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  }
}

const createComment = (moduleId, moduleAttemptId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { attemptsAnalytics } = getState().accounts

    const response = await postModuleAttemptComment(moduleId, moduleAttemptId, data)
    const newCommentEvent = {
      content_md: data?.content_md,
      event_name: 'content.comment.create',
      id: response?.id,
      datetime: response?.created,
      content_html: response?.content_html,
      user: response?.user,
    }

    const auxAttemptsAnalytics = {
      ...attemptsAnalytics,
      module_attempts: attemptsAnalytics?.module_attempts.map((a) => {
        if (a.id === moduleAttemptId) {
          return {
            ...a,
            timeline: [...a.timeline, newCommentEvent],
          }
        }

        return a
      }),
    }

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(auxAttemptsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const updateComment = (moduleId, moduleAttemptId, commentId, data, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { attemptsAnalytics } = getState().accounts

    const response = await patchModuleAttemptComment(moduleId, moduleAttemptId, commentId, data)
    const newCommentEvent = {
      content_md: data?.content_md,
      event_name: 'content.comment.create',
      id: response?.id,
      datetime: response?.created,
      content_html: response?.content_html,
      user: response?.user,
    }

    const auxAttemptsAnalytics = {
      ...attemptsAnalytics,
      module_attempts: attemptsAnalytics?.module_attempts.map((a) => {
        if (a.id === moduleAttemptId) {
          return {
            ...a,
            timeline: a.timeline?.map((e) => (e.id === commentId ? newCommentEvent : e)),
          }
        }

        return a
      }),
    }

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(auxAttemptsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const deleteComment = (moduleId, moduleAttemptId, commentId, callback) => async (dispatch, getState) => {
  try {
    dispatch(SET_MODULE_LOADING(true))

    const { attemptsAnalytics } = getState().accounts

    await deleteModuleAttemptComment(moduleId, moduleAttemptId, commentId)

    const auxAttemptsAnalytics = {
      ...attemptsAnalytics,
      module_attempts: attemptsAnalytics?.module_attempts.map((a) => {
        if (a.id === moduleAttemptId) {
          return {
            ...a,
            timeline: a.timeline?.filter((e) => e.id !== commentId),
          }
        }

        return a
      }),
    }

    dispatch(ATTEMPTS_ANALYTICS_UPDATED(auxAttemptsAnalytics))

    if (callback) {
      callback()
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_MODULE_ERROR(message))
  } finally {
    dispatch(SET_MODULE_LOADING(false))
  }
}

const setAssignModalOpen = (isOpen) => (dispatch) => {
  dispatch(SET_ASSIGN_MODAL_OPEN(isOpen))
}

const resetModulesState = () => (dispatch) => {
  dispatch(RESET_MODULES_STATE())
}

export {
  updateCustomModuleWizard,
  resetCustomModuleWizard,
  // fetchModules,
  fetchCustomModules,
  createCustomModule,
  updateCustomModule,
  fetchModule,
  createComment,
  updateComment,
  deleteComment,
  setAssignModalOpen,
  resetModulesState,
}
