import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { Collapse, Dropdown, Tooltip, Modal, Skeleton } from 'antd'
import {
  PlusOutlined,
  EditOutlined,
  AppstoreAddOutlined,
  DeleteOutlined,
  ExclamationCircleOutlined,
} from '@ant-design/icons'
import { AppsListDetail24Regular } from '@fluentui/react-icons'
import FadeIn from '@/components/FadeIn'
import Button from '@/components/Button'
import ModuleBox from '@/components/ModuleBox'
import PlaygroundBox from '@/components/PlaygroundBox'
import CustomModuleBox from '@/components/CustomModuleBox'
import { toggleContentSearchModal } from '@/store/app/actions'
import { updateCustomModuleWizard, resetModulesState } from '@/store/modules/actions'
import { resetPlaygroundsState } from '@/store/playgrounds/actions'
import {
  fetchCollection,
  hydrateCollectionContent,
  setCollection,
  setCollectionMode,
  setLesson,
} from '@/store/collections/actions'
import { Container } from './styles'

const CourseDetail = () => {
  const dispatch = useDispatch()
  const { accountId, param1: courseId } = useParams()

  const { currentCollection, currentCollectionMode, isCollectionsLoading } = useSelector((state) => state.collections)

  const isEditMode = currentCollectionMode === 'edit'

  const addProjectMenuItems = (lessonId) => [
    {
      key: 'create',
      label: (
        <span
          className="menu-item"
          onClick={() => {
            dispatch(setLesson(lessonId))
            dispatch(updateCustomModuleWizard({ isModalOpen: true }))
          }}
        >
          <PlusOutlined />
          Create new project
        </span>
      ),
    },
    {
      key: 'select',
      label: (
        <span
          className="menu-item"
          onClick={() => {
            dispatch(setLesson(lessonId))
            dispatch(toggleContentSearchModal(true))
          }}
        >
          <AppstoreAddOutlined />
          Select existing project
        </span>
      ),
    },
  ]

  const handleStartEdit = () => {
    if (currentCollectionMode === 'edit' && !currentCollection?.content?.length) {
      setDefaultCollectionStructure()
      return
    }

    dispatch(setCollectionMode('edit'))
  }

  const setDefaultCollectionStructure = () => {
    const hydratatedCollection = { ...currentCollection }

    hydratatedCollection.content = [
      {
        id: uuidv4(),
        name: 'Unit 1',
        type: 'group',
        content: [
          {
            id: uuidv4(),
            name: 'Lesson 1',
            type: 'group',
            content: [],
          },
        ],
      },
    ]

    dispatch(setCollection(hydratatedCollection))
  }

  const addUnit = () => {
    const newUnit = {
      id: uuidv4(),
      name: `Unit ${currentCollection.content.length + 1}`,
      type: 'group',
      content: [],
    }

    const auxCollection = { ...currentCollection, content: [...currentCollection.content, newUnit] }
    dispatch(setCollection(auxCollection))
  }

  const addLesson = (unit) => {
    const newLesson = {
      id: uuidv4(),
      name: `Lesson ${unit.content.length + 1}`,
      type: 'group',
      content: [],
    }

    const auxUnits = currentCollection.content.map((currentUnit) => {
      if (currentUnit.id === unit.id) {
        return { ...currentUnit, content: [...currentUnit.content, newLesson] }
      }

      return currentUnit
    })

    const auxCollection = { ...currentCollection, content: auxUnits }
    dispatch(setCollection(auxCollection))
  }

  const removeUnit = (evt, unitId) => {
    evt.stopPropagation()

    Modal.confirm({
      title: 'Remove unit',
      content: 'Are you sure you want to delete this unit and all its content?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, delete',
      cancelText: 'No',
      onOk: () => {
        const auxUnits = currentCollection.content.filter((unit) => unit.id !== unitId)
        const auxCollection = { ...currentCollection, content: auxUnits }
        dispatch(setCollection(auxCollection))
      },
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
    })
  }

  const removeLesson = (evt, unitId, lessonId) => {
    evt.stopPropagation()

    Modal.confirm({
      title: 'Remove lesson',
      content: 'Are you sure you want to delete this lesson and all its content?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, delete',
      cancelText: 'No',
      onOk: () => {
        const auxUnits = currentCollection.content.map((unit) => {
          if (unit.id === unitId) {
            const auxLessons = unit.content.filter((lesson) => lesson.id !== lessonId)
            return { ...unit, content: auxLessons }
          }

          return unit
        })

        const auxCollection = { ...currentCollection, content: auxUnits }
        dispatch(setCollection(auxCollection))
      },
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
    })
  }

  const removeProject = (evt, unitId, lessonId, projectId) => {
    evt.stopPropagation()

    Modal.confirm({
      title: 'Remove unit',
      content: 'Are you sure you want to delete this project?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, delete',
      cancelText: 'No',
      onOk: () => {
        const auxUnits = currentCollection.content.map((unit) => {
          if (unit.id === unitId) {
            const auxLessons = unit.content.map((lesson) => {
              if (lesson.id === lessonId) {
                const auxProjects = lesson.content.filter((project) => project.id !== projectId)
                return { ...lesson, content: auxProjects }
              }

              return lesson
            })

            return { ...unit, content: auxLessons }
          }

          return unit
        })

        const auxCollection = { ...currentCollection, content: auxUnits }
        dispatch(setCollection(auxCollection))
      },
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
    })
  }

  useEffect(() => {
    if (currentCollectionMode === 'edit' && !currentCollection?.content?.length) {
      setDefaultCollectionStructure()
      return
    }
  }, [currentCollectionMode])

  useEffect(() => {
    if (!currentCollection) return

    dispatch(hydrateCollectionContent(accountId, currentCollection))
  }, [currentCollection?.id])

  useEffect(() => {
    if (!courseId) return

    dispatch(fetchCollection(courseId, { account_id: accountId }))
  }, [courseId])

  useEffect(() => {
    return () => {
      dispatch(resetModulesState())
      dispatch(resetPlaygroundsState())
      dispatch(setCollectionMode(null))
      dispatch(setCollection(null))
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  if (isCollectionsLoading) {
    return (
      <Container className="course-detail">
        <div className="body">
          <div className="content">
            <div className="loading-container">
              <div className="header">
                <Skeleton active title={false} paragraph={{ rows: 1 }} />
              </div>

              <div className="body">
                <div className="sub-header">
                  <Skeleton active title={false} paragraph={{ rows: 1 }} />
                </div>

                <CustomModuleBox size="small" isLoading />
                <CustomModuleBox size="small" isLoading />
              </div>
            </div>
          </div>
        </div>
      </Container>
    )
  }

  return (
    <Container className="course-detail">
      <div className="body">
        {currentCollection?.content?.length ? (
          <div className="content">
            {currentCollection?.content?.map((level0, index) => (
              <Collapse
                key={index}
                className="unit-container"
                defaultActiveKey={[`unit-${currentCollection?.content?.[0]?.id}`]}
                items={[
                  {
                    key: `unit-${level0?.id}`,
                    label: (
                      <div className="unit-header">
                        <p className="unit-name">{level0?.name}</p>

                        {isEditMode && (
                          <div className="actions">
                            <Tooltip title="Remove unit">
                              <Button
                                type="default"
                                icon={<DeleteOutlined />}
                                onClick={(evt) => removeUnit(evt, level0?.id)}
                              />
                            </Tooltip>
                          </div>
                        )}
                      </div>
                    ),
                    children: (
                      <>
                        {level0?.content?.map((level1, index) => (
                          <Collapse
                            key={index}
                            className="lesson-container"
                            ghost
                            defaultActiveKey={[`lesson-${level0?.content?.[0]?.id}`]}
                            items={[
                              {
                                key: `lesson-${level1?.id}`,
                                label: (
                                  <div className="lesson-header">
                                    <p className="lesson-name">{level1?.name}</p>

                                    {isEditMode && (
                                      <div className="actions">
                                        <Tooltip title="Remove lesson">
                                          <Button
                                            type="default"
                                            icon={<DeleteOutlined />}
                                            onClick={(evt) => removeLesson(evt, level0?.id, level1?.id)}
                                          />
                                        </Tooltip>
                                      </div>
                                    )}
                                  </div>
                                ),
                                children: (
                                  <>
                                    <div className="projects-container">
                                      {level1?.content?.map((level2) => {
                                        return (
                                          <div className="project-content">
                                            {level2?.type === 'module' && (
                                              <ModuleBox
                                                key={`module-${level2?.id}`}
                                                module={level2}
                                                size="small"
                                                isLoading={isCollectionsLoading}
                                              />
                                            )}
                                            {level2?.type === 'custom_module' && (
                                              <CustomModuleBox
                                                key={`custom-module-${level2?.id}`}
                                                customModule={level2}
                                                size="small"
                                                isLoading={isCollectionsLoading}
                                              />
                                            )}
                                            {level2?.type === 'playground' && (
                                              <PlaygroundBox
                                                key={`playground-${level2?.id}`}
                                                playground={level2}
                                                size="small"
                                                isLoading={isCollectionsLoading}
                                              />
                                            )}
                                            {isEditMode && (
                                              <div className="actions">
                                                <Tooltip title="Remove project">
                                                  <Button
                                                    type="default"
                                                    icon={<DeleteOutlined />}
                                                    onClick={(evt) =>
                                                      removeProject(evt, level0?.id, level1?.id, level2?.id)
                                                    }
                                                  />
                                                </Tooltip>
                                              </div>
                                            )}
                                          </div>
                                        )
                                      })}
                                    </div>

                                    {!level1?.content?.length && !isEditMode && (
                                      <div className="lesson-footer">No content</div>
                                    )}

                                    {isEditMode && (
                                      <FadeIn>
                                        <div className="lesson-footer">
                                          <Dropdown
                                            overlayClassName={'dropdown-menu'}
                                            trigger={['click']}
                                            menu={{
                                              items: addProjectMenuItems(level1?.id),
                                            }}
                                          >
                                            <div className="add-content-block">
                                              <PlusOutlined className="icon" />
                                              <p className="text">Add project</p>
                                            </div>
                                          </Dropdown>
                                        </div>
                                      </FadeIn>
                                    )}
                                  </>
                                ),
                              },
                            ]}
                          />
                        ))}

                        {!level0?.content?.length && !isEditMode && <div className="unit-footer">No content</div>}

                        {isEditMode && (
                          <FadeIn>
                            <div className="unit-footer" onClick={() => addLesson(level0)}>
                              <div className="add-content-block">
                                <PlusOutlined className="icon" />
                                <p className="text">Add lesson</p>
                              </div>
                            </div>
                          </FadeIn>
                        )}
                      </>
                    ),
                  },
                ]}
              />
            ))}

            {isEditMode && (
              <FadeIn>
                <div className="collection-footer" onClick={addUnit}>
                  <div className="add-content-block">
                    <PlusOutlined className="icon" />
                    <p className="text">Add unit</p>
                  </div>
                </div>
              </FadeIn>
            )}
          </div>
        ) : (
          <div className="content">
            <div className="empty-state-content">
              <div className="header">
                <div className="container">
                  <div className="title-container">
                    <AppsListDetail24Regular className="icon" />

                    <h4 className="title">This course does not have any content yet.</h4>
                  </div>

                  <p className="text">You can structure each course with units, lessons, and projects.</p>

                  <Button type="secondary" icon={<EditOutlined />} onClick={handleStartEdit}>
                    Start adding content
                  </Button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </Container>
  )
}

export default CourseDetail
