import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  height: 100%;
  height: 100%;

  .results-table {
    height: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    overflow: auto;

    .ant-spin-nested-loading {
      height: 100%;

      .ant-spin-container {
        height: 100%;

        .ant-table {
          height: calc(100% - 60px);
        }

        .ant-pagination {
          justify-content: flex-start;
        }
      }
    }

    .ant-table-header {
      max-height: 55px;

      .ant-table-cell {
        padding: 0px 16px;
      }
    }

    .ant-table-body {
      max-height: calc(100vh - 375px) !important;
      overflow: auto !important;
    }

    .account-item {
      width: 310px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 14px;

      .link {
        color: ${(props) => props.theme.colors.gray10};
        display: flex;
        align-items: center;
        gap: 14px;

        &:hover {
          .title {
            text-decoration: underline;
          }
        }
      }

      .account-image-container {
        min-width: 42px;
        max-width: 42px;
        min-height: 42px;
        max-height: 42px;
        background: linear-gradient(86deg, #39476d 8.49%, #4150d0 67.75%, rgba(179, 114, 244, 0.62) 97.13%);
        display: flex;
        border-radius: 7px;
        overflow: hidden;
        box-shadow: 0px 1px 4px 2px ${(props) => props.theme.colors.gray6}66;

        .account-image {
          width: 100%;
          object-fit: cover;
          pointer-events: none;
        }
      }

      .title-container {
        display: flex;
        flex-direction: column;

        .title {
          width: 100%;
          font-weight: 600;
          margin: 0px;
        }
      }

      .star-btn {
        color: ${(props) => props.theme.colors.gray6};

        svg {
          width: 24px;
          height: 24px;
        }

        &:hover {
          color: ${(props) => props.theme.colors.gray7};
        }

        &.is-favorite {
          color: ${(props) => props.theme.colors.yellow6};
        }
      }
    }

    .account-owners {
      max-width: 200px;
      display: flex;
      flex-direction: column;

      .account-owner {
        font-size: 13px;
        display: flex;
        align-items: center;
        margin: 0px;

        .mail-btn {
          height: 24px;
          width: 24px;
          padding: 0px;

          svg {
            width: 16px;
            height: 16px;
            opacity: 0.3;
          }
        }
      }

      .more {
        color: ${(props) => props.theme.colors.blue7};
        font-size: 12px;
        margin: 0px;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .account-actions {
      .archive-btn,
      .restore-btn {
        svg {
          width: 18px;
          height: 18px;
        }
      }

      .archive-btn {
        color: ${(props) => props.theme.colors.red4};

        &:hover {
          color: ${(props) => props.theme.colors.red5};
        }
      }

      .restore-btn {
        color: ${(props) => props.theme.colors.geekblue4};

        &:hover {
          color: ${(props) => props.theme.colors.geekblue5};
        }
      }

      .django-btn {
        filter: saturate(0);
        opacity: 0.6;
      }
    }
  }

  @media ${device.mdDown} {
    .results-table {
      .ant-table {
        .ant-table-body {
          max-height: calc(100vh - 356px) !important;
        }

        .ant-table-cell {
          font-size: 12px;
          padding: 10px;
        }
      }

      .account-item {
        width: 220px;

        .title {
          font-size: 12px;
        }
      }

      .account-owners {
        .account-owner {
          font-size: 12px;
        }
      }
    }
  }
`
