import { useState, useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import dayjs from 'dayjs'
import { Input, Table, Tag, Modal, Tooltip, Empty } from 'antd'
import { ExclamationCircleFilled } from '@ant-design/icons'
import { SendClock24Regular, ChannelDismiss24Regular } from '@fluentui/react-icons'
import { debounce } from 'lodash'
import Button from '@/components/Button'
import {
  fetchAccountUserInvites,
  updateAccountUserInvite,
  resendAccountUserInvite,
  setUserSearchText,
} from '@/store/accounts/actions'
import { Container } from './styles'

const PendingInvitesTable = ({ size, isPreview }) => {
  const dispatch = useDispatch()

  const { pageSize: defaultPageSize } = useSelector((state) => state.app)
  const {
    currentAccount: account,
    userInvites,
    userSearchText,
    filteredTeam,
    isInviteLoading,
  } = useSelector((state) => state.accounts)

  const teamId = filteredTeam === 'all' ? undefined : filteredTeam === 'not-in-teams' ? 'None' : filteredTeam

  const [pageSize, setPageSize] = useState(defaultPageSize)

  const handleRevokeInvite = (invite) => {
    Modal.confirm({
      title: 'Revoking member invite',
      content: `Are you sure you want to revoke ${invite?.email} member invite?`,
      icon: <ExclamationCircleFilled />,
      okText: 'Yes, revoke',
      cancelText: 'Cancel',
      onOk: () => dispatch(updateAccountUserInvite(account?.id, invite?.id, { status: 'revoked' })),
      okButtonProps: {
        danger: true,
        type: 'primary',
      },
    })
  }

  const handleTableChange = (pagination) => {
    const { current: page, pageSize } = pagination

    setPageSize(pageSize)
    dispatch(
      fetchAccountUserInvites(account?.id, {
        status: 'pending',
        search: userSearchText,
        team_id: teamId,
        page_size: pageSize,
        page,
      }),
    )
  }

  const columns = [
    {
      title: 'Email',
      dataIndex: 'email',
      className: 'email-col',
    },
    ...(isPreview
      ? []
      : [
          {
            title: 'Team',
            dataIndex: 'team',
          },
        ]),
    {
      title: 'Invited at',
      dataIndex: 'invitedAt',
      className: 'invited-at-col',
    },
    {
      title: 'Actions',
      dataIndex: 'actions',
      className: 'actions-col',
    },
  ]

  const data = userInvites?.results?.map((invite) => {
    return {
      key: invite?.id,
      email: invite?.email,
      team: invite?.teams?.length
        ? invite?.teams?.map((t) => (
            <Tag
              key={t.id}
              className="team-tag"
              style={{ backgroundColor: `${t.color}15`, color: `${t.color}cc`, borderColor: `${t.color}cc` }}
            >
              {t.name}
            </Tag>
          ))
        : '—',
      invitedAt: dayjs(invite?.created).from(),
      actions: (
        <div className="actions-col-content">
          <Tooltip title="Resend">
            <Button
              className="resend-button"
              type={isPreview ? 'text' : 'default'}
              icon={<SendClock24Regular />}
              onClick={() => dispatch(resendAccountUserInvite(account?.id, invite?.id))}
              loading={isInviteLoading}
            />
          </Tooltip>

          <Tooltip title="Revoke">
            <Button
              className="revoke-button"
              type={isPreview ? 'text' : 'default'}
              icon={<ChannelDismiss24Regular />}
              onClick={() => handleRevokeInvite(invite)}
              loading={isInviteLoading}
            />
          </Tooltip>
        </div>
      ),
    }
  })

  const handleUserSearch = useMemo(
    () =>
      debounce((evt) => {
        const search = evt?.target?.value?.toLowerCase()

        dispatch(
          fetchAccountUserInvites(account?.id, { status: 'pending', search, team_id: teamId, page_size: pageSize }),
        )
      }, 300),
    [filteredTeam],
  )

  useEffect(() => {
    if (userSearchText) return

    dispatch(fetchAccountUserInvites(account?.id, { status: 'pending', team_id: teamId, page_size: pageSize }))
  }, [userSearchText, filteredTeam])

  return (
    <Container className="members-table-container">
      {!isPreview && (
        <div className="table-header">
          <div className="filters">
            <Input.Search
              className="user-search"
              value={userSearchText}
              onChange={(evt) => {
                dispatch(setUserSearchText(evt?.target?.value?.toLowerCase()))
                handleUserSearch(evt)
              }}
              allowClear
              placeholder="Search invite by email..."
              loading={isInviteLoading}
            />
          </div>
        </div>
      )}

      <Table
        className="pending-invites-table"
        size={size || 'middle'}
        columns={columns}
        dataSource={data}
        loading={!userInvites || isInviteLoading}
        onChange={handleTableChange}
        locale={{ emptyText: <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No invites found" /> }}
        pagination={
          !isPreview && {
            pageSize,
            total: userInvites?.count,
            showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} invites`,
          }
        }
        scroll={{
          y: true,
          x: true,
        }}
      />
    </Container>
  )
}
export default PendingInvitesTable
