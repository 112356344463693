import { createSlice } from '@reduxjs/toolkit'

const initialCustomModuleWizardState = {
  id: null,
  name: '',
  description: '',
  svgIconUrl: null,
  stack: null,
  stackSize: null,
  gpuType: null,
  gpuCount: 0,
  internetEdition: null,
  internetResolution: null,
  // isEdit: false,
  isModalOpen: false,
  error: null,
}

const initialState = {
  modulesData: null,
  currentModule: null,
  customModuleWizard: initialCustomModuleWizardState,
  customModulesData: null,
  isModuleLoading: false,
  isAssignModalOpen: false,
  error: null,
}

const slice = createSlice({
  name: 'modules',
  initialState,
  reducers: {
    SET_CUSTOM_MODULE_WIZARD_UPDATED: (state, { payload }) => {
      state.customModuleWizard = payload
    },
    RESET_CUSTOM_MODULE_WIZARD_STATE: (state) => {
      state.customModuleWizard = initialCustomModuleWizardState
    },
    SET_MODULE_LOADING: (state, { payload }) => {
      state.isModuleLoading = payload
    },
    MODULES_UPDATED: (state, { payload }) => {
      state.modulesData = payload
    },
    MODULE_UPDATED: (state, { payload }) => {
      state.currentModule = payload
      state.isModuleLoading = false
      state.error = null
    },
    CUSTOM_MODULES_UPDATED: (state, { payload }) => {
      state.customModulesData = payload
    },
    SET_ASSIGN_MODAL_OPEN: (state, { payload }) => {
      state.isAssignModalOpen = payload
    },
    RESET_MODULES_STATE: () => initialState,
    SET_MODULE_ERROR: (state, { payload }) => {
      state.error = payload
      state.isModuleLoading = false
    },
  },
})

export const {
  SET_CUSTOM_MODULE_WIZARD_UPDATED,
  RESET_CUSTOM_MODULE_WIZARD_STATE,
  SET_MODULE_LOADING,
  MODULES_UPDATED,
  MODULE_UPDATED,
  CUSTOM_MODULES_UPDATED,
  SET_ASSIGN_MODAL_OPEN,
  RESET_MODULES_STATE,
  SET_MODULE_ERROR,
} = slice.actions
export default slice.reducer
