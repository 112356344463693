import {
  SET_SUBSCRIPTIONS_LOADING,
  SUBSCRIPTION_UPDATED,
  SET_SUBSCRIPTIONS_ERROR,
  RESET_SUBSCRIPTIONS_STATE,
} from '@/store/subscriptions'
import { getSubscriptionsMe } from '@/services/subscriptions'

const fetchSubscriptionsMe = (params, onSuccess, onError) => async (dispatch) => {
  try {
    dispatch(SET_SUBSCRIPTIONS_LOADING(true))

    const subscription = await getSubscriptionsMe(params)

    dispatch(SUBSCRIPTION_UPDATED(subscription))

    if (onSuccess) {
      onSuccess()
    }
  } catch (error) {
    const { message } = error

    if (onError) {
      onError(message)
    }

    dispatch(SET_SUBSCRIPTIONS_ERROR(message))
  } finally {
    dispatch(SET_SUBSCRIPTIONS_LOADING(false))
  }
}

export { fetchSubscriptionsMe }
