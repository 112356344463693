import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  background-color: ${(props) => props.theme.colors.gray1};
  border-radius: 10px;
  overflow: hidden;

  > .content {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    gap: 40px;
    flex: 1;
    padding: 20px 28px;

    .info-container {
      min-width: 220px;
      display: flex;
      align-items: center;
      justify-content: center;

      .info {
        width: 100%;
        height: 100%;
        background-color: ${(props) => props.theme.colors.gray2};
        border-radius: 8px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 8px;
        padding: 0px 24px;

        .value-container {
          .value {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 8px;
            margin: 0px;
          }

          .text {
            color: ${(props) => props.theme.colors.gray7};
            text-align: center;
            margin: 0px;
          }
        }
      }
    }

    .table-container {
      max-height: 280px;
      width: 100%;
      position: relative;
      overflow: auto;

      .pending-invites-table {
        .ant-table {
          height: 100%;

          .ant-table-container {
            height: 100%;

            .ant-table-body {
              height: calc(100% - 54px);
              overflow: auto;

              .ant-table-placeholder {
                .ant-table-cell {
                  height: 100%;

                  .ant-table-expanded-row-fixed {
                    padding: 8px;

                    .ant-empty {
                      margin-block: 12px;

                      .ant-empty-image {
                        height: 32px;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }

  @media ${device.xlDown} {
    min-height: 300px !important;

    > .content {
      flex-direction: column;
      gap: 12px;
      padding: 28px;

      .info-container {
        width: 100%;

        .info {
          background-color: initial;
          width: 100%;
          flex-direction: row;
          justify-content: space-between;
          padding: 0px;

          .icon svg {
            height: 20px;
            width: 20px;
          }

          .value-container {
            display: flex;
            align-items: center;
            gap: 8px;

            .value,
            .text {
              color: ${(props) => props.theme.colors.gray10};
              font-size: 16px;
              font-weight: 400;
              margin: 0px;

              .icon {
                width: 20px;
                height: 20px;
              }
            }
          }
        }
      }
    }
  }
`
