import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, useSearchParams, Link } from 'react-router-dom'
import { Tabs } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getPlatformURL } from '@/helpers/env'
import Button from '@/components/Button'
import ProLabel from '@/components/ProLabel'
import ModulesCatalog from '@/components/ModulesCatalog'
import AccountPlaygrounds from '@/components/AccountPlaygrounds'
import AccountCustomModules from '@/components/AccountCustomModules'
import { setContentTab, setUserSearchText } from '@/store/accounts/actions'
import { updateCustomModuleWizard } from '@/store/modules/actions'
import { Container } from './styles'

const ContentTab = () => {
  const dispatch = useDispatch()
  const { accountId } = useParams()
  let [searchParams, setSearchParams] = useSearchParams()

  const tab = searchParams.get('tab')

  const { currentAccount, selectedContentTab } = useSelector((state) => state.accounts)

  const enableCatalog = currentAccount?.config?.browse?.catalog // DataWars projects
  const enablePlaygrounds = currentAccount?.config?.browse?.playgrounds
  const enableAutogradedCustomProjects = currentAccount?.config?.browse?.autograded_custom_projects

  const tabItems = [
    ...(enableCatalog
      ? [
          {
            key: 'projects',
            label: 'Full catalog',
            children: <div className="tab-content">{selectedContentTab === 'projects' && <ModulesCatalog />}</div>,
          },
        ]
      : []),
    ...(enablePlaygrounds
      ? [
          {
            key: 'customProjects',
            label: 'Custom projects',
            children: (
              <div className="tab-content">{selectedContentTab === 'customProjects' && <AccountPlaygrounds />}</div>
            ),
          },
        ]
      : []),
    ...(enableAutogradedCustomProjects
      ? [
          {
            key: 'autoGradedProjects',
            label: (
              <span style={{ display: 'flex', gap: 4 }}>
                Auto-graded projects <ProLabel hideBadge />
              </span>
            ),
            children: (
              <div className="tab-content">
                {selectedContentTab === 'autoGradedProjects' && <AccountCustomModules />}
              </div>
            ),
          },
        ]
      : []),
  ]

  const handleChangeTab = (tab) => {
    setSearchParams({ ...Object.fromEntries(searchParams.entries()), tab })
  }

  const handleOpenCustomModuleWizard = () => {
    dispatch(updateCustomModuleWizard({ isModalOpen: true }))
  }

  useEffect(() => {
    dispatch(setUserSearchText(''))
    let validTabs = []

    if (enableCatalog) {
      validTabs.push('projects')
    }

    if (enablePlaygrounds) {
      validTabs.push('customProjects')
    }

    if (enableAutogradedCustomProjects) {
      validTabs.push('autoGradedProjects')
    }

    const isValidTab = validTabs.includes(tab)

    if (tab && tab !== selectedContentTab && isValidTab) {
      dispatch(setContentTab(tab))
      return
    }

    if (!tab || !isValidTab) {
      dispatch(setContentTab(validTabs[0]))
    }
  }, [tab])

  useEffect(() => {
    return () => {
      dispatch(setUserSearchText(''))
    }
  }, [])

  return (
    <Container className="content-tab">
      <div className="header-container">
        <div className="info">
          <div className="title-container">
            <h4 className="title">Content</h4>
          </div>
        </div>

        {selectedContentTab === 'customProjects' && (
          <Link
            className="link"
            to={`${getPlatformURL(currentAccount?.slug)}custom-projects/${accountId}/create`}
            target="_blank"
          >
            <Button type="secondary" icon={<PlusOutlined />}>
              Create project
            </Button>
          </Link>
        )}

        {selectedContentTab === 'autoGradedProjects' && (
          <Button type="secondary" icon={<PlusOutlined />} onClick={handleOpenCustomModuleWizard}>
            Create project
          </Button>
        )}
      </div>

      <div className="content">
        <Tabs activeKey={selectedContentTab} items={tabItems} onChange={handleChangeTab} />
      </div>
    </Container>
  )
}

export default ContentTab
