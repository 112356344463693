import { useEffect, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { debounce } from 'lodash'
import { Input, Select } from 'antd'
import TeamItem from '@/pages/MainPage/components/TeamItem'
import ProTooltip from '@/components/ProTooltip'
import { setInviteSettings, updateAccountInviteLink } from '@/store/accounts/actions'
import { Container } from './styles'

const InviteSettings = ({ inviteType, bottomText }) => {
  const dispatch = useDispatch()

  const { currentSubscription } = useSelector((state) => state.subscriptions)
  const {
    currentAccount,
    teamsData,
    currentInviteLink: inviteLink,
    inviteSettings,
    isInviteModalOpen,
  } = useSelector((state) => state.accounts)

  const orgConfig = currentAccount?.organization?.config

  const maxMembers = currentSubscription?.available_features?.account_members?.limit
  const enrolledMembers = currentSubscription?.available_features?.account_members?.current_value
  const availableMembers = maxMembers - enrolledMembers

  const handleUpdateInviteLink = useMemo(
    () =>
      debounce(async (teamId, customText) => {
        const data = {
          teams: teamId ? [teamId] : [],
          custom_text_md: customText || '',
        }

        await dispatch(updateAccountInviteLink(currentAccount?.id, inviteLink?.id, data))
      }, 300),
    [inviteLink],
  )

  useEffect(() => {
    if (!isInviteModalOpen) return
  }, [isInviteModalOpen, inviteType])

  return (
    <Container className="invite-settings-copntainer">
      <p className="title">General invite settings</p>

      {!!teamsData?.count && (
        <Select
          className="team-select"
          value={inviteSettings?.teamId}
          placeholder="No team selected"
          notFoundContent="No teams found"
          options={teamsData?.results?.map((t) => ({
            value: t.id,
            label: <TeamItem team={t} size="xsmall" />,
          }))}
          onChange={(value) => {
            dispatch(setInviteSettings('teamId', value))

            if (inviteType === 'link') {
              handleUpdateInviteLink(value, inviteSettings?.customText)
            }
          }}
          allowClear
          virtual={false}
        />
      )}

      <Input.TextArea
        value={inviteSettings?.customText}
        placeholder="Custom invitation text"
        allowClear
        style={{
          resize: 'none',
        }}
        onChange={(evt) => {
          dispatch(setInviteSettings('customText', evt.target.value))

          if (inviteType === 'link') {
            handleUpdateInviteLink(inviteSettings?.teamId, evt.target.value)
          }
        }}
      />

      <ProTooltip
        title={!availableMembers && 'Need more licenses?'}
        content={
          <p className="text">
            Contact us at{' '}
            <a href={`mailto:${orgConfig?.sales_email}?subject=Subscription upgrade`} target="_blank">
              {orgConfig?.sales_email}
            </a>
            .
          </p>
        }
      >
        <div className="license-info">
          <p className={`text ${availableMembers ? '' : 'error'}`}>
            Available licenses: <b>{availableMembers}</b>
          </p>
        </div>
      </ProTooltip>

      {availableMembers ? (
        <p className="info-text">
          Users can join your account with this invite {inviteType === 'link' ? ' link' : ''} as long as there are
          outstanding licenses. {bottomText}
        </p>
      ) : (
        <p className="info-text error">
          Users won't be able to join your account as there are no more outstanding licenses.
        </p>
      )}
    </Container>
  )
}

export default InviteSettings
