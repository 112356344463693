import styled from 'styled-components'
import { device } from '@/themes/breakpoints'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;

  > .header-container {
    min-height: 72px;
    width: 100%;
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;
    padding: 0px 24px;

    .info {
      display: flex;
      align-items: center;
      gap: 16px;

      .title-container {
        display: flex;
        display: flex;
        align-items: center;
        gap: 4px;

        .title {
          white-space: nowrap;
          margin: 0px;

          .secondary {
            opacity: 0.5;
          }

          .link {
            color: ${(props) => props.theme.colors.gray10};
            cursor: pointer;

            &:hover {
              text-decoration: underline;
            }
          }
        }
      }
    }
  }

  > .main-content {
    height: 100%;
    width: 100%;
    overflow: auto;
  }

  @media ${device.mdDown} {
    > .header-container {
      padding: 0px 12px;

      .info {
        .title-container {
          .title {
            font-size: 16px;
          }
        }
      }
    }

    > .main-content {
      padding: 0px;
    }
  }
`
