import API from '@/services/client'
import { apiErrorHandler } from '@/helpers/errorHandlers'

const getCollections = async (queryParams) => {
  const route = 'collections'
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const getCollection = async (collectionId, queryParams) => {
  const route = `collections/${collectionId}`
  return API.get(route, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const postCollection = async (data, queryParams) => {
  const route = `collections`
  return API.post(route, data, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

const patchCollection = async (collectionId, data, queryParams) => {
  const route = `collections/${collectionId}`
  return API.patch(route, data, { params: queryParams })
    .then((response) => Promise.resolve(response.data))
    .catch((error) => Promise.reject(apiErrorHandler(error.response.data)))
}

export { getCollections, getCollection, postCollection, patchCollection }
