import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useParams, Link } from 'react-router-dom'
import { PlusOutlined, EditOutlined, SaveOutlined } from '@ant-design/icons'
import { showToast } from '@/utils/toast'
import Button from '@/components/Button'
import ContentSearchModal from '@/components/ContentSearchModal'
import CoursesList from './components/CoursesList'
import CourseDetail from './components/CourseDetail'
import CourseModal from './components/CourseModal'
import {
  updateCollection,
  setCollectionMode,
  updateCollectionWizard,
  resetCollectionsState,
} from '@/store/collections/actions'
import { Container } from './styles'

const CoursesTab = () => {
  const dispatch = useDispatch()
  const { param1: courseId } = useParams()

  const { currentAccount } = useSelector((state) => state.accounts)
  const { currentCollection, currentCollectionMode, isCollectionsLoading } = useSelector((state) => state.collections)

  const handleUpdateCollection = () => {
    const auxCollection = {
      ...currentCollection,
      content: currentCollection?.content.map((level0) => {
        return {
          ...level0,
          content: level0?.content?.map((level1) => {
            return {
              ...level1,
              content: level1?.content?.map((level2) => {
                return {
                  id: level2.id,
                  type: level2.type,
                }
              }),
            }
          }),
        }
      }),
    }

    dispatch(
      updateCollection(currentCollection?.id, auxCollection, { account_id: currentAccount?.id }, () => {
        showToast('Course updated successfully')
        dispatch(setCollectionMode('preview'))
      }),
    )
  }

  useEffect(() => {
    return () => {
      dispatch(resetCollectionsState())
    }
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Container className="courses-tab">
      <div className="header-container">
        <div className="info">
          <div className="title-container">
            <h4 className="title">
              {currentCollection ? (
                <>
                  <span className="secondary">
                    <Link className="link" to={`/accounts/${currentAccount?.id}/courses`}>
                      Courses
                    </Link>{' '}
                    /
                  </span>{' '}
                  {currentCollection?.name}
                </>
              ) : (
                'Courses'
              )}
            </h4>
          </div>
        </div>

        {currentCollection ? (
          <div className="actions">
            {currentCollectionMode === 'edit' ? (
              <Button
                type="secondary"
                icon={<SaveOutlined />}
                onClick={handleUpdateCollection}
                loading={isCollectionsLoading}
              >
                Save content
              </Button>
            ) : (
              <Button type="default" icon={<EditOutlined />} onClick={() => dispatch(setCollectionMode('edit'))}>
                Edit content
              </Button>
            )}
          </div>
        ) : (
          <Button
            type="secondary"
            icon={<PlusOutlined />}
            onClick={() => dispatch(updateCollectionWizard({ isModalOpen: true }))}
          >
            Create course
          </Button>
        )}
      </div>

      <div className="main-content">{courseId ? <CourseDetail /> : <CoursesList />}</div>

      <CourseModal />
      <ContentSearchModal />
    </Container>
  )
}

export default CoursesTab
