import dayjs from 'dayjs'
import { Tooltip, Checkbox, Radio, Button as AntdButton, Skeleton } from 'antd'
import { BulbOutlined, EyeOutlined } from '@ant-design/icons'
import { Sparkle24Filled } from '@fluentui/react-icons'
import Button from '@/components/Button'
import Input from '@/components/Input'
import HTMLBlock from '@/components/HTMLBlock'
import CodeBlock from '@/components/CodeBlock'
import ActivityWidget from '@/components/ActivityWidget'
import ActivityReactions from '@/components/Activity/components/ActivityReactions'
import { Container } from './styles'

const Activity = ({ activity, isReview }) => {
  const isActivitySubmitted = !!activity.user_status
  const answerSubmittedAt = activity.user_status?.submitted_answer_at
  const isActivityPassed = activity.user_status?.is_passed

  const solutionRequestedAt = activity.user_status?.solution_requested_at
  const isSolutionRequestedBeforeSubmission = dayjs(solutionRequestedAt).isBefore(dayjs(answerSubmittedAt))

  const hintRequestedAt = activity.user_status?.hint_requested_at
  const isHintRequestedBeforeSubmission = dayjs(hintRequestedAt).isBefore(dayjs(answerSubmittedAt))

  // multiple choice activity
  const isCheckbox = activity?.widget === 'checkbox'
  const auxAnswers = activity?.answers && [...activity?.answers]
  const options = auxAnswers
    ?.sort((x, y) => x.stick_to_bottom - y.stick_to_bottom)
    ?.map((a) => ({
      label: (
        <div
          id={`answer-${a.id}`}
          className={a?.is_correct ? 'correct' : 'incorrect'}
          dangerouslySetInnerHTML={{
            __html: a.content_html,
          }}
        />
      ),
      value: a.id,
    }))

  const activityActions = (
    <div className="activity-actions">
      {isHintRequestedBeforeSubmission && (
        <Tooltip title={'Hint was requested'}>
          <AntdButton className="hint-btn" shape="circle" size="small" icon={<BulbOutlined />} disabled />
        </Tooltip>
      )}

      {isSolutionRequestedBeforeSubmission && (
        <Tooltip title={'Solution was requested'}>
          <AntdButton className="solution-btn" shape="circle" size="small" icon={<EyeOutlined />} disabled />
        </Tooltip>
      )}
    </div>
  )

  const renderAnswer = () => {
    let answerContent

    if (activity?.activity_type === 'input')
      answerContent = (
        <Input className="activity-input" value={activity?.user_status?.submitted_answer?.text_answer} disabled />
      )

    if (activity?.activity_type === 'multiple_choice') {
      if (isCheckbox) {
        answerContent = (
          <Checkbox.Group
            className="activity-answers-group"
            options={options}
            value={activity?.user_status?.submitted_answer?.selected_answer_ids || []}
            disabled
          />
        )
      } else {
        answerContent = (
          <Radio.Group
            className="activity-answers-group"
            options={options}
            value={activity?.user_status?.submitted_answer?.selected_answer_ids?.[0]}
            disabled
          />
        )
      }
    }

    if (activity?.activity_type === 'code') {
      if (activity?.user_status?.submitted_answer?.code_answer) {
        answerContent = (
          <HTMLBlock
            content={
              <CodeBlock language={activity?.code_language}>
                {activity?.user_status?.submitted_answer?.code_answer}
              </CodeBlock>
            }
          />
        )
      }
    }

    if (
      activity?.activity_type === 'ai_validated' ||
      (activity?.activity_type === 'manually_validated' && activity?.input_required)
    ) {
      answerContent = (
        <ActivityWidget
          widgetType={activity?.widget_type}
          widgetMetadata={activity?.widget_metadata}
          answer={activity?.user_status?.submitted_answer?.text_answer}
          label={false}
          disabled
        />
      )
    }

    if (activity?.activity_type === 'latex_validated') {
      answerContent = <Input type="mathlive" value={activity?.user_status?.submitted_answer?.text_answer} disabled />
    }

    if (answerContent) {
      return (
        <div className="submitted-box">
          <p className="text">Submitted answer:</p>

          {answerContent}
        </div>
      )
    }
  }

  return (
    <Container
      id="activity-container"
      isReview={isReview}
      className={
        isActivitySubmitted
          ? isActivityPassed === null
            ? 'submitted'
            : isActivityPassed
              ? 'correct'
              : 'incorrect'
          : ''
      }
    >
      <Skeleton active loading={!activity} title={false} paragraph={{ rows: 4 }} />
      <div className="activity-main-content">
        <div id={`activity-${activity.id}`} className={`activity-main-body ${activity?.activity_type}`}>
          {activity?.activity_type === 'ai_validated' && (
            <div className="ai-graded-tag">
              <Sparkle24Filled className="icon" /> AI graded activity
            </div>
          )}

          <div className="activity-content">
            <div className="activity-body">
              <span className="activity-number">{(activity?.order || 0) + 1}</span>

              {activity?.title_html && (
                <div className="title-container" dangerouslySetInnerHTML={{ __html: activity?.title_html }} />
              )}
            </div>

            <HTMLBlock content={activity?.content_html} className={'activity-content-markdown'} />
          </div>

          {renderAnswer()}
        </div>

        <div className="review-status-block">
          {isActivitySubmitted ? (
            <Button
              type="dashed"
              size="small"
              className={`activity-status-btn ${isActivityPassed === null ? 'submitted' : isActivityPassed ? 'correct' : 'incorrect'}`}
            >
              {isActivityPassed === null ? 'Submitted' : isActivityPassed ? 'Correct' : 'Incorrect'}
            </Button>
          ) : (
            <Button type="dashed" size="small" className={'activity-status-btn not-actioned'}>
              Not actioned
            </Button>
          )}

          {activityActions}

          {isActivitySubmitted && <ActivityReactions activity={activity} />}
        </div>
      </div>

      <div className="activity-output">
        {activity?.user_status?.submitted_answer?.stdout && ['code_validated'].includes(activity?.activity_type) && (
          <pre className="info-block error">{activity?.user_status?.submitted_answer?.stdout}</pre>
        )}

        {activity?.user_status?.submitted_answer?.stderr &&
          ['code_validated', 'latex_validated'].includes(activity?.activity_type) && (
            <pre className="info-block out">{activity?.user_status?.submitted_answer?.stderr}</pre>
          )}

        {activity?.user_status?.submitted_answer?.comments && ['ai_validated'].includes(activity?.activity_type) && (
          <pre className="info-block comments">{activity?.user_status?.submitted_answer?.comments}</pre>
        )}
      </div>
    </Container>
  )
}

export default Activity
