import {
  SET_COLLECTIONS_LOADING,
  COLLECTIONS_DATA_UPDATED,
  CURRENT_COLLECTION_UPDATED,
  CURRENT_COLLECTION_MODE_UPDATED,
  LESSON_SELECTED_UPDATED,
  SET_COLLECTION_WIZARD_UPDATED,
  RESET_COLLECTION_WIZARD_STATE,
  RESET_COLLECTIONS_STATE,
  SET_COLLECTIONS_ERROR,
} from '@/store/collections'
import { getCollections, getCollection, postCollection, patchCollection } from '@/services/collections'
import { getAlgoliaModules } from '@/services/modules'
import { getCustomModules } from '@/services/customModules'
import { getPlaygrounds } from '@/services/playgrounds'
import { showToast } from '@/utils/toast'

const fetchCollections = (params) => async (dispatch) => {
  try {
    dispatch(SET_COLLECTIONS_LOADING(true))

    const collectionsData = await getCollections(params)

    dispatch(COLLECTIONS_DATA_UPDATED(collectionsData))
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTIONS_ERROR(message))
  } finally {
    dispatch(SET_COLLECTIONS_LOADING(false))
  }
}

const fetchCollection = (collectionId, params) => async (dispatch) => {
  try {
    dispatch(SET_COLLECTIONS_LOADING(true))

    const collection = await getCollection(collectionId, params)

    dispatch(CURRENT_COLLECTION_UPDATED(collection))
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTIONS_ERROR(message))
  } finally {
    dispatch(SET_COLLECTIONS_LOADING(false))
  }
}

const createCollection = (data, params, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_COLLECTIONS_LOADING(true))

    const { collections } = getState()
    const { collectionsData } = collections

    const newCollection = await postCollection(data, params)

    dispatch(
      COLLECTIONS_DATA_UPDATED({
        ...collectionsData,
        results: [newCollection, ...collectionsData.results],
      }),
    )

    if (cb) {
      cb(newCollection)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTIONS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_COLLECTIONS_LOADING(false))
  }
}

const updateCollection = (collectionId, data, params, cb) => async (dispatch, getState) => {
  try {
    dispatch(SET_COLLECTIONS_LOADING(true))

    const { collections } = getState()
    const { collectionsData } = collections

    const updatedCollection = await patchCollection(collectionId, data, params)

    dispatch(
      COLLECTIONS_DATA_UPDATED({
        ...collectionsData,
        results: collectionsData.results.map((c) => (c.id === collectionId ? updatedCollection : c)),
      }),
    )

    if (cb) {
      cb(updatedCollection)
    }
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTIONS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_COLLECTIONS_LOADING(false))
  }
}

const addCollectionContent = (item) => async (dispatch, getState) => {
  const { currentCollection, lessonSelected } = getState().collections

  if (!item || !lessonSelected) return

  let auxContent

  auxContent = currentCollection?.content?.map((level0) => {
    return {
      ...level0,
      content: level0?.content?.map((level1) => {
        if (level1?.id === lessonSelected) {
          return {
            ...level1,
            content: [...level1?.content, item],
          }
        }

        return level1
      }),
    }
  })

  const auxCollection = { ...currentCollection, content: auxContent }

  dispatch(CURRENT_COLLECTION_UPDATED(auxCollection))
  dispatch(LESSON_SELECTED_UPDATED(null))
}

const hydrateCollectionContent = (accountId, collection) => async (dispatch) => {
  try {
    dispatch(SET_COLLECTIONS_LOADING(true))

    const getContentTypeIdsFromContent = (content, contentType) => {
      // TODO: allow having content on level 0 and level 1 too
      const ids = content
        ?.map((level0) => level0?.content)
        ?.flat()
        ?.map((level1) => level1?.content)
        ?.flat()
        ?.filter((level2) => level2.type === contentType)
        ?.map((level2) => level2?.id)
        ?.filter((value, index, array) => array.indexOf(value) === index)

      return ids
    }

    const collectionModuleIds = getContentTypeIdsFromContent(collection.content, 'module')
    const collectionCustomModuleIds = getContentTypeIdsFromContent(collection.content, 'custom_module')
    const collectionPlaygroundIds = getContentTypeIdsFromContent(collection.content, 'playground')

    let modulesData = []
    if (!!collectionModuleIds?.length) {
      const filter = collectionModuleIds?.map((id) => `objectID:${id}`)?.join(' OR ')
      modulesData = await getAlgoliaModules(filter)
    }

    let customModulesData = []
    if (!!collectionCustomModuleIds?.length) {
      const params = {
        account_id: accountId,
        ids: collectionCustomModuleIds?.map((id) => id?.split('-')?.[0])?.join(','),
        page_size: 'None',
      }

      customModulesData = await getCustomModules(params)
    }

    let playgroundsData = []
    if (!!collectionPlaygroundIds?.length) {
      const params = {
        account_id: accountId,
        is_student_copy: false,
        ids: collectionPlaygroundIds?.map((id) => id?.split('-')?.[0])?.join(','),
        page_size: 'None',
      }

      playgroundsData = await getPlaygrounds(params)
    }

    const getModule = (id) => {
      const module = modulesData?.find((module) => module?.id === id)

      if (!module) return null
      return {
        ...module,
        tags: module?.tags?.map((t) => t.name),
        type: 'module',
      }
    }

    const getCustomModule = (id) => {
      const customModule = customModulesData?.find((customModule) => customModule?.id === id)

      if (!customModule) return null
      return { ...customModule, type: 'custom_module' }
    }

    const getPlayground = (id) => {
      const playground = playgroundsData?.find((playground) => playground?.id === id)

      if (!playground) return null
      return { ...playground, type: 'playground' }
    }

    const hydratatedCollection = { ...collection }

    hydratatedCollection.content = hydratatedCollection?.content?.map((level0) => {
      const auxLevel0 = { ...level0 }
      auxLevel0.content = auxLevel0?.content?.map((level1) => {
        const auxLevel1 = { ...level1 }
        auxLevel1.content = auxLevel1?.content
          ?.map((level2) => {
            if (level2?.type === 'module') {
              return getModule(level2?.id)
            }

            if (level2?.type === 'custom_module') {
              return getCustomModule(level2?.id)
            }

            if (level2?.type === 'playground') {
              return getPlayground(level2?.id)
            }

            return false
          })
          .filter((level2) => level2)

        return auxLevel1
      })
      // .filter((level2) => level2?.content?.length)

      return auxLevel0
    })
    // .filter((level1) => level1?.content?.length)

    dispatch(CURRENT_COLLECTION_UPDATED(hydratatedCollection))
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTIONS_ERROR(message))
    showToast(error.message, 'error')
  } finally {
    dispatch(SET_COLLECTIONS_LOADING(false))
  }
}

const setCollection = (data) => (dispatch) => {
  dispatch(CURRENT_COLLECTION_UPDATED(data))
}

const setCollectionMode = (mode) => (dispatch) => {
  dispatch(CURRENT_COLLECTION_MODE_UPDATED(mode))
}

const setLesson = (lessonId) => (dispatch) => {
  dispatch(LESSON_SELECTED_UPDATED(lessonId))
}

const updateCollectionWizard = (data) => async (dispatch, getState) => {
  const { collectionWizard } = getState().collections

  try {
    dispatch(SET_COLLECTION_WIZARD_UPDATED({ ...collectionWizard, ...data }))
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTION_WIZARD_UPDATED({ ...collectionWizard, error: message }))
  }
}

const resetCollectionWizard = () => async (dispatch, getState) => {
  const { customModuleWizard } = getState().modules

  try {
    dispatch(RESET_COLLECTION_WIZARD_STATE())
  } catch (error) {
    const { message } = error
    dispatch(SET_COLLECTION_WIZARD_UPDATED({ ...customModuleWizard, error: message }))
  }
}

const resetCollectionsState = () => (dispatch) => {
  dispatch(RESET_COLLECTIONS_STATE())
}

export {
  fetchCollections,
  fetchCollection,
  createCollection,
  updateCollection,
  addCollectionContent,
  hydrateCollectionContent,
  setCollection,
  setCollectionMode,
  setLesson,
  updateCollectionWizard,
  resetCollectionWizard,
  resetCollectionsState,
}
