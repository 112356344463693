import { createSlice } from '@reduxjs/toolkit'

const initialCollectionWizardState = {
  id: null,
  name: '',
  description: '',
  code: '',
  icon: null,
  isModalOpen: false,
}

const initialState = {
  collectionsData: null,
  currentCollection: null,
  currentCollectionMode: null,
  lessonSelected: null,
  collectionWizard: initialCollectionWizardState,
  isCollectionsLoading: false,
  error: null,
}

const slice = createSlice({
  name: 'collections',
  initialState,
  reducers: {
    SET_COLLECTIONS_LOADING: (state, { payload }) => {
      state.isCollectionsLoading = payload
    },
    COLLECTIONS_DATA_UPDATED: (state, { payload }) => {
      state.collectionsData = payload
      state.error = null
    },
    CURRENT_COLLECTION_UPDATED: (state, { payload }) => {
      state.currentCollection = payload
    },
    CURRENT_COLLECTION_MODE_UPDATED: (state, { payload }) => {
      state.currentCollectionMode = payload
    },
    LESSON_SELECTED_UPDATED: (state, { payload }) => {
      state.lessonSelected = payload
    },
    SET_COLLECTION_WIZARD_UPDATED: (state, { payload }) => {
      state.collectionWizard = payload
    },
    RESET_COLLECTION_WIZARD_STATE: (state) => {
      state.collectionWizard = initialCollectionWizardState
    },
    RESET_COLLECTIONS_STATE: () => initialState,
    SET_COLLECTIONS_ERROR: (state, { payload }) => {
      state.error = payload
      state.isCollectionsLoading = false
    },
  },
})

export const {
  SET_COLLECTIONS_LOADING,
  COLLECTIONS_DATA_UPDATED,
  CURRENT_COLLECTION_UPDATED,
  CURRENT_COLLECTION_MODE_UPDATED,
  LESSON_SELECTED_UPDATED,
  SET_COLLECTION_WIZARD_UPDATED,
  RESET_COLLECTION_WIZARD_STATE,
  RESET_COLLECTIONS_STATE,
  SET_COLLECTIONS_ERROR,
} = slice.actions
export default slice.reducer
