import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import { Modal as AntModal } from 'antd'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Modal = styled(AntModal)`
  max-height: calc(100% - 50px);
  width: 100% !important;
  top: 30px;
  padding: 0px 20px;

  .ant-modal-content {
    background-color: ${(props) => props.theme.colors.gray1} !important;
    padding: 24px;
  }

  .ant-modal-body {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    overflow: auto;

    .container {
      min-height: 520px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      > .header {
        display: flex;
        align-items: flex-start;
        gap: 24px;
        margin: 20px 50px 10px 20px;

        &.is-loading {
          flex-direction: column;
          gap: 0px;
        }

        .module-image {
          width: 84px;
          opacity: ${() => (localStorage.getItem('dw-theme') === 'dark' ? 0.7 : 1)};
        }

        .info {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 4px;

          .title {
            font-weight: 700;
            margin: 0px;
          }

          .subtitle {
            display: flex;
            gap: 12px;
            margin: 0px;

            .progress-bar {
              max-width: 150px;
              font-weight: 500;
            }

            .member-info {
              border-left: 1px solid ${(props) => props.theme.colors.gray4};
              padding-left: 12px;

              > span {
                color: ${(props) => props.theme.colors.gray7};
                font-weight: 400;
              }
            }
          }
        }
      }

      .select-container {
        margin: 10px 20px;

        .attempt-select {
          width: 100%;
        }
      }

      > .content {
        display: flex;
        flex-direction: column;
        gap: 20px;
        overflow: auto;

        .module-content {
          display: flex;
        }

        .section {
          display: flex;
          flex-direction: column;
          gap: 20px;
          padding: 20px 20px 0px;

          &.module-activities-section {
            max-height: calc(100vh - 310px);
            max-width: 530px;
            height: 100%;
            width: 100%;
            overflow: auto;
          }

          &.module-preview-section {
            min-height: calc(100vh - 350px);
            max-height: calc(100vh - 350px);
            height: 100%;
            width: 100%;
            background-color: ${(props) => props.theme.colors.geekblue1};
            display: flex;
            align-items: center;
            overflow: auto;
            padding: 20px !important;

            .module-preview {
              max-width: 1080px;
              background: ${(props) => props.theme.colors.gray1};
              box-shadow: 0px 2px 8px 0px rgba(0, 0, 0, 0.15);
              animation: 1s ${fadeInAnimation};
            }

            .loading-content {
              height: 100%;
              padding: 20px;
            }

            .no-preview-available {
              height: 100%;
              min-height: 300px;
              max-width: 500px;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: center;
              text-align: center;
              padding: 20px;
              margin: 0 auto 20px;
            }
          }
        }

        .tabs-container {
          padding: 0px 20px;

          .tab-item {
            display: flex;
            gap: 4px;
            position: relative;
          }

          .section {
            padding: 0px;
          }
        }
      }
    }
  }

  .ant-modal-close {
    height: 40px;
    width: 40px;
    background-color: ${(props) => props.theme.colors.gray4};
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    margin: 2px;

    .ant-modal-close-x {
      line-height: inherit;

      svg {
        color: ${(props) => props.theme.colors.gray10};
        font-size: 18px;
      }
    }
  }

  @media ${device.xlDown} {
    .ant-modal-body {
      .container {
        > .content {
          .ant-tabs {
            padding: 0px;
          }

          .module-content {
            flex-direction: column;
            gap: 20px;
          }

          .section {
            &.module-activities-section {
              max-width: initial;
              max-height: 35vh;
            }

            &.module-preview-section {
              min-height: initial;
              max-width: calc(100% - 40px);
            }
          }
        }
      }
    }
  }

  @media ${device.mdDown} {
    .ant-modal-content {
      padding: 8px;
    }

    .ant-modal-body {
      .container {
        > .header {
          margin: 14px 20px 10px;

          .module-image {
            display: none;
          }
        }
      }
    }
  }
`
