import styled, { css } from 'styled-components'

export const Container = styled.div`
  width: 48px;
  background: linear-gradient(
    84deg,
    ${(props) => props.theme.colors.base.gray10} 24.3%,
    #5479f7 67.59%,
    #89d2c2 99.33%
  );
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  align-self: flex-start;
  gap: 5px;
  box-shadow: 0px 2px 0px 0px rgba(0, 0, 0, 0.04);

  > span {
    color: ${(props) => props.theme.colors.base.gray1};
    font-size: 11px;
    font-weight: 700;
    line-height: 22px;
  }

  ${(props) =>
    props.$isBasic &&
    css`
      background: ${(props) => props.theme.colors.geekblue2};

      > span {
        color: ${(props) => props.theme.colors.geekblue4};
        font-size: 12px;
      }
    `}

  .pro-badge {
    width: 15px;
  }
`
