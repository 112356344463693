import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Pagination } from 'antd'
import ModuleBox from '@/components/ModuleBox'
import NoResults from '@/assets/images/no-results.svg'
import { Container } from './styles'

const Results = ({
  results,
  indexUiState,
  status,
  refine,
  page,
  setPage,
  disableItemActions,
  onItemClick,
  onItemClickText,
}) => {
  const { pageSize: defaultPageSize } = useSelector((state) => state.app)

  const currentSearchParameters = indexUiState?.configure

  const isLoading = !results?.params || status === 'loading' || status === 'stalled'
  const searchText = indexUiState?.query

  const [pageSize, setPageSize] = useState(defaultPageSize)

  const handleTableChange = (newPage, newPageSize) => {
    setPage(newPage)
    setPageSize(newPageSize)
  }

  useEffect(() => {
    refine({
      ...currentSearchParameters,
      page: page - 1,
      hitsPerPage: pageSize,
    })
  }, [page, pageSize])

  return (
    <Container className="catalog-search-results">
      <div className="content">
        {isLoading && (
          <div className="results-container">
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
            <div className="module-container">
              <ModuleBox isLoading border />
            </div>
          </div>
        )}

        {results?.hits?.length === 0 && !isLoading && (
          <div className="no-results-box">
            <img src={NoResults} alt="no-results" />
            <h4 className="title">No projects found</h4>
            <h5 className="text">
              {searchText && (
                <span>
                  “{searchText}” did not match any project.
                  <br />
                </span>
              )}
              Please try again
            </h5>
          </div>
        )}

        <div className="results-container">
          {results?.hits?.map((hit) => {
            const module = {
              ...hit,
              id: hit.objectID,
              tags: hit?.tags?.map((t) => t.name),
            }

            return (
              <div key={module?.id} className="module-container">
                <ModuleBox
                  module={module}
                  border
                  showSkillTrack
                  disableActions={disableItemActions}
                  onClick={onItemClick}
                  onClickText={onItemClickText}
                />
              </div>
            )
          })}

          {!!results?.hits?.length && (
            <div className="pagination-container">
              <Pagination
                className="pagination-box"
                onChange={handleTableChange}
                current={page}
                pageSize={pageSize}
                total={results?.nbHits}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} projects`}
              />
            </div>
          )}
        </div>
      </div>
    </Container>
  )
}

export default Results
