import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  isLoading: false,
  isAccountLoading: false,
  isTeamsLoading: false,
  isModuleAttemptsLoading: false,
  isPlaygroundVersionsLoading: false,
  isLicenseLoading: false,
  accountsData: null,
  currentAccount: null,
  allUsers: null,
  usersData: null,
  teamsData: null,
  currentTeam: null,
  filteredTeam: null,
  analyticsLeaderboard: null,
  projectsAnalytics: null,
  playgroundsAnalytics: null,
  attemptsAnalytics: null,
  currentAttempt: null,
  versionsAnalytics: null,
  currentVersion: null,
  activityAnalytics: null,
  selectedUsers: null,
  userSearchText: '',
  isWelcomeModalOpen: false,
  isEditAccountModalOpen: false,
  isTeamModalOpen: false,
  isAddToTeamModalOpen: false,
  isPlaygroundAnalyticsModalOpen: false,
  selectedMembersTab: 'active',
  selectedContentTab: 'projects',
  selectedAnalyticsTab: 'leaderboard',
  accountError: null,
  isProjectAnalyticsModalOpen: false,
  error: null,
  // Invites
  isInviteLoading: false,
  currentInviteLink: null,
  userInvites: null,
  inviteEmails: null,
  inviteSettings: {
    teamId: null,
    customText: null,
  },
  isInviteModalOpen: false,
}

const slice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    SET_ACCOUNTS_LOADING: (state, { payload }) => {
      state.isLoading = payload
    },
    SET_ACCOUNT_LOADING: (state, { payload }) => {
      state.isAccountLoading = payload
    },
    SET_ACCOUNT_TEAMS_LOADING: (state, { payload }) => {
      state.isTeamsLoading = payload
    },
    SET_MODULE_ATTEMPTS_LOADING: (state, { payload }) => {
      state.isModuleAttemptsLoading = payload
    },
    SET_PLAYGROUND_VERSIONS_LOADING: (state, { payload }) => {
      state.isPlaygroundVersionsLoading = payload
    },
    SET_ACCOUNTS_LICENSE_LOADING: (state, { payload }) => {
      state.isLicenseLoading = payload
    },
    ACCOUNT_ACCOUNTS_DATA_UPDATED: (state, { payload }) => {
      state.accountsData = payload
    },
    ACCOUNT_UPDATED: (state, { payload }) => {
      state.currentAccount = payload
      state.accountError = null
    },
    ACCOUNT_ALL_USERS_UPDATED: (state, { payload }) => {
      state.allUsers = payload
    },
    ACCOUNT_USERS_DATA_UPDATED: (state, { payload }) => {
      state.usersData = payload
    },
    ACCOUNT_TEAMS_UPDATED: (state, { payload }) => {
      state.teamsData = payload
    },
    ACCOUNT_TEAM_UPDATED: (state, { payload }) => {
      state.currentTeam = payload
    },
    ANALYTICS_LEADERBOARD_UPDATED: (state, { payload }) => {
      state.analyticsLeaderboard = payload
    },
    PROJECTS_ANALYTICS_UPDATED: (state, { payload }) => {
      state.projectsAnalytics = payload
    },
    PLAYGROUNDS_ANALYTICS_UPDATED: (state, { payload }) => {
      state.playgroundsAnalytics = payload
    },
    ATTEMPTS_ANALYTICS_UPDATED: (state, { payload }) => {
      state.attemptsAnalytics = payload
    },
    MODULE_ATTEMPT_UPDATED: (state, { payload }) => {
      state.currentAttempt = payload
    },
    VERSIONS_ANALYTICS_UPDATED: (state, { payload }) => {
      state.versionsAnalytics = payload
    },
    PLAYGROUND_VERSION_UPDATED: (state, { payload }) => {
      state.currentVersion = payload
    },
    ACTIVITY_ANALYTICS_UPDATED: (state, { payload }) => {
      state.activityAnalytics = payload
    },
    ACCOUNT_FILTERED_TEAM_UPDATED: (state, { payload }) => {
      state.filteredTeam = payload
    },
    ACCOUNT_SELECTED_USERS_UPDATED: (state, { payload }) => {
      state.selectedUsers = payload
    },
    ACCOUNT_USER_SEARCH_TEXT_UPDATED: (state, { payload }) => {
      state.userSearchText = payload
    },
    SET_TEAM_MODAL_OPEN: (state, { payload }) => {
      state.isTeamModalOpen = payload
    },
    SET_WELCOME_MODAL_OPEN: (state, { payload }) => {
      state.isWelcomeModalOpen = payload
    },
    SET_EDIT_ACCOUNT_MODAL_OPEN: (state, { payload }) => {
      state.isEditAccountModalOpen = payload
    },
    SET_ADD_TO_TEAM_MODAL_OPEN: (state, { payload }) => {
      state.isAddToTeamModalOpen = payload
    },
    SET_PROJECT_ANALYTICS_MODAL_OPEN: (state, { payload }) => {
      state.isProjectAnalyticsModalOpen = payload
    },
    SET_PLAYGROUND_ANALYTICS_MODAL_OPEN: (state, { payload }) => {
      state.isPlaygroundAnalyticsModalOpen = payload
    },
    SET_MEMBERS_TAB: (state, { payload }) => {
      state.selectedMembersTab = payload
    },
    SET_CONTENT_TAB: (state, { payload }) => {
      state.selectedContentTab = payload
    },
    SET_ANALYTICS_TAB: (state, { payload }) => {
      state.selectedAnalyticsTab = payload
    },
    SET_ACCOUNT_ERROR: (state, { payload }) => {
      state.accountError = payload
    },
    SET_ACCOUNTS_ERROR: (state, { payload }) => {
      state.error = payload
    },
    RESET_ACCOUNTS_STATE: () => initialState,
    // Invites
    SET_ACCOUNTS_INVITE_LOADING: (state, { payload }) => {
      state.isInviteLoading = payload
    },
    ACCOUNT_INVITE_LINK_UPDATED: (state, { payload }) => {
      state.currentInviteLink = payload
    },
    ACCOUNT_USER_INVITES_UPDATED: (state, { payload }) => {
      state.userInvites = payload
    },
    ACCOUNT_INVITE_EMAILS_UPDATED: (state, { payload }) => {
      state.inviteEmails = payload
    },
    ACCOUNT_INVITE_SETTINGS_UPDATED: (state, { payload }) => {
      state.inviteSettings = payload
    },
    SET_INVITE_MODAL_OPEN: (state, { payload }) => {
      state.isInviteModalOpen = payload
    },
  },
})

export const {
  SET_ACCOUNTS_LOADING,
  SET_ACCOUNT_LOADING,
  SET_ACCOUNT_TEAMS_LOADING,
  SET_MODULE_ATTEMPTS_LOADING,
  SET_PLAYGROUND_VERSIONS_LOADING,
  SET_ACCOUNTS_LICENSE_LOADING,
  ACCOUNT_ACCOUNTS_DATA_UPDATED,
  ACCOUNT_UPDATED,
  ACCOUNT_ALL_USERS_UPDATED,
  ACCOUNT_USERS_DATA_UPDATED,
  ACCOUNT_TEAMS_UPDATED,
  ACCOUNT_TEAM_UPDATED,
  ANALYTICS_LEADERBOARD_UPDATED,
  PROJECTS_ANALYTICS_UPDATED,
  PLAYGROUNDS_ANALYTICS_UPDATED,
  ATTEMPTS_ANALYTICS_UPDATED,
  MODULE_ATTEMPT_UPDATED,
  VERSIONS_ANALYTICS_UPDATED,
  PLAYGROUND_VERSION_UPDATED,
  ACTIVITY_ANALYTICS_UPDATED,
  ACCOUNT_FILTERED_TEAM_UPDATED,
  ACCOUNT_SELECTED_USERS_UPDATED,
  ACCOUNT_USER_SEARCH_TEXT_UPDATED,
  SET_WELCOME_MODAL_OPEN,
  SET_EDIT_ACCOUNT_MODAL_OPEN,
  SET_TEAM_MODAL_OPEN,
  SET_ADD_TO_TEAM_MODAL_OPEN,
  SET_PROJECT_ANALYTICS_MODAL_OPEN,
  SET_PLAYGROUND_ANALYTICS_MODAL_OPEN,
  SET_MEMBERS_TAB,
  SET_CONTENT_TAB,
  SET_ANALYTICS_TAB,
  SET_ACCOUNT_ERROR,
  SET_ACCOUNTS_ERROR,
  RESET_ACCOUNTS_STATE,
  // Invites
  SET_ACCOUNTS_INVITE_LOADING,
  ACCOUNT_INVITE_LINK_UPDATED,
  ACCOUNT_USER_INVITES_UPDATED,
  ACCOUNT_INVITE_EMAILS_UPDATED,
  ACCOUNT_INVITE_SETTINGS_UPDATED,
  SET_INVITE_MODAL_OPEN,
} = slice.actions
export default slice.reducer
