import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Link } from 'react-router-dom'
import dayjs from 'dayjs'
import { Modal, Button, Tooltip, Dropdown, Skeleton } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import {
  MoreVertical24Filled,
  Archive24Regular,
  Options24Regular,
  ArchiveArrowBack24Regular,
} from '@fluentui/react-icons'
import { showToast } from '@/utils/toast'
import getIconByUrl from '@/utils/getIconByUrl'
import StatusTag from '@/components/StatusTag'
import { updateCollectionWizard, updateCollection } from '@/store/collections/actions'
import LogoWhite from '@/assets/images/logo-sm-white.svg'
import { Container } from './styles'

const CollectionBox = ({ collection, isLoading }) => {
  const dispatch = useDispatch()

  const [icon, setIcon] = useState(null)

  const { currentAccount } = useSelector((state) => state.accounts)
  const { isCollectionsLoading } = useSelector((state) => state.collections)

  const archiveCollection = () => {
    Modal.confirm({
      title: 'Archive course',
      content: 'Are you sure you want to archive this course?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, archive',
      cancelText: 'No',
      onOk: () =>
        dispatch(
          updateCollection(collection?.id, { status: 'archived' }, { account_id: currentAccount?.id }, () =>
            showToast('Course archived successfully'),
          ),
        ),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isCollectionsLoading,
        loading: isCollectionsLoading,
      },
    })
  }

  const unarchiveCollection = () => {
    Modal.confirm({
      title: 'Restore course',
      content: 'Are you sure you want to restore this course as draft?',
      icon: <ExclamationCircleOutlined />,
      okText: 'Yes, restore',
      cancelText: 'No',
      onOk: () =>
        dispatch(
          updateCollection(collection?.id, { status: 'published' }, { account_id: currentAccount?.id }, () =>
            showToast('Course restored successfully'),
          ),
        ),
      okButtonProps: {
        danger: true,
        type: 'primary',
        disabled: isCollectionsLoading,
        loading: isCollectionsLoading,
      },
    })
  }

  const getStatusDate = () => {
    if (collection?.status === 'draft') return `Created on ${dayjs(collection?.created).format('lll')}`

    if (collection?.status === 'published') return `Published on ${dayjs(collection?.created).format('lll')}`

    if (collection?.status === 'archived') return `Archived on ${dayjs(collection?.status_changed).format('lll')}`
  }

  const menuItems = [
    ...(collection?.status !== 'archived'
      ? [
          {
            key: 'edit',
            label: (
              <a
                onClick={() =>
                  dispatch(
                    updateCollectionWizard({
                      isModalOpen: true,
                      id: collection?.id,
                      name: collection?.name,
                      code: collection?.code,
                      description: collection?.description_md,
                      icon: collection?.icon,
                    }),
                  )
                }
                className="menu-item"
              >
                <Options24Regular /> Settings
              </a>
            ),
          },
          {
            key: 'archive',
            label: (
              <a onClick={archiveCollection} className="menu-item">
                <Archive24Regular /> Archive
              </a>
            ),
            danger: true,
          },
        ]
      : [
          {
            key: 'restore',
            label: (
              <a onClick={unarchiveCollection} className="menu-item">
                <ArchiveArrowBack24Regular /> Restore
              </a>
            ),
            danger: true,
          },
        ]),
  ]

  useEffect(() => {
    if (!collection) return

    getIconByUrl({
      iconUrl: collection?.icon || 'fluent-Book24Regular',
      className: 'icon',
      onReady: setIcon,
    })
  }, [collection])

  if (isLoading || !collection?.name)
    return (
      <Container className="collection-box is-loading">
        <div className="main-content">
          <Skeleton className="left-side" active avatar title={false} paragraph={{ rows: 0 }} />
          <Skeleton className="right-side" active title={false} paragraph={{ rows: 2 }} />
        </div>
      </Container>
    )

  return (
    <Container className="collection-box">
      <div className="main-content">
        <div className="image-container">
          {icon}
          {collection?.is_template && (
            <div className="template-tag">
              <img className="logo" src={LogoWhite} alt="logo" />
            </div>
          )}
        </div>

        <div className="content">
          <div className="header">
            <div className="title-container">
              <Link to={`/accounts/${currentAccount?.id}/courses/${collection?.id}`}>
                <h4 className="title">
                  <span className="course-code">{collection?.code || collection?.name?.substr(0, 3)}:</span>{' '}
                  {collection?.name}
                </h4>
              </Link>

              <div className="info">
                {collection?.status === 'archived' && (
                  <Tooltip title={getStatusDate}>
                    <div className="status-tag-container">
                      <StatusTag status={collection?.status} />
                    </div>
                  </Tooltip>
                )}

                <p className="description">
                  {collection?.content_summary?.level_0 ? (
                    <span>
                      {collection?.content_summary?.level_0 || '0'} Unit
                      {collection?.content_summary?.level_0 === 1 ? '' : 's'} |{' '}
                      {collection?.content_summary?.level_1 || '0'} Lesson
                      {collection?.content_summary?.level_1 === 1 ? '' : 's'} |{' '}
                      {collection?.content_summary?.level_2 || '0'} Project
                      {collection?.content_summary?.level_2 === 1 ? '' : 's'}
                    </span>
                  ) : (
                    <span>No content</span>
                  )}
                </p>
              </div>
            </div>

            <Tooltip title={`Last edited on ${dayjs(collection?.modified).format('lll')}`}>
              <div className="info-box">
                <p className="metric-name">Last edited:</p>
                <p className="value">{dayjs(collection?.modified).fromNow()}</p>
              </div>
            </Tooltip>

            <Dropdown
              overlayClassName={'dropdown-menu'}
              trigger={['click']}
              menu={{
                items: menuItems,
              }}
            >
              {menuItems?.length ? (
                <Button className="open-options-button" type="text" shape="circle" icon={<MoreVertical24Filled />} />
              ) : (
                <></>
              )}
            </Dropdown>
          </div>
        </div>
      </div>
    </Container>
  )
}

export default CollectionBox
