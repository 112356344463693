import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { isDataWarsHostName } from '@/helpers/env'
import Layout1 from '@/layouts/Layout1'
import SuperAccountOwnerAccountsTable from './components/SuperAccountOwnerAccountsTable'
import AccountOwnerAccountsTable from './components/AccountOwnerAccountsTable'
import EditAccountModal from './components/EditAccountModal'
import { fetchAccounts } from '@/store/accounts/actions'
import { Container } from './styles'

const AccountsPage = () => {
  const dispatch = useDispatch()

  const { pageSize } = useSelector((state) => state.app)
  const { userProfile } = useSelector((state) => state.users)

  const isSuperAccountOwner = userProfile?.permissions?.includes('teams.super_account_owner')
  const isAccountOwner = userProfile?.accounts?.some((a) => a?.role === 'owner')

  if (!isSuperAccountOwner && !isAccountOwner) {
    return window.location.replace('/create-account')
  }

  useEffect(() => {
    if (!userProfile || isSuperAccountOwner) return

    dispatch(fetchAccounts({ page_size: pageSize }))
  }, [])

  return (
    <Layout1 navbar>
      <Container className="accounts-page-container">
        <div className="main-content">
          {isSuperAccountOwner ? <SuperAccountOwnerAccountsTable /> : <AccountOwnerAccountsTable />}
        </div>

        <EditAccountModal />
      </Container>
    </Layout1>
  )
}

export default AccountsPage
