import styled, { keyframes } from 'styled-components'
import { fadeIn } from 'react-animations'
import CustomProjectsEmptyState from '@/assets/images/empty-state-custom-projects.png'
import { device } from '@/themes/breakpoints'

const fadeInAnimation = keyframes`${fadeIn}`

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 20px;

  > .header {
    background-color: ${(props) => props.theme.colors.gray1};
    display: flex;
    align-items: center;
    justify-content: space-between;
    overflow: auto hidden;
    padding: 12px 24px;

    .search-container {
      display: flex;
      justify-content: space-between;
      gap: 8px;
      flex: 1 1 100%;

      .user-search {
        min-width: 200px;
        max-width: 300px;
        width: 100%;

        .ant-input-search-button {
          cursor: default;

          &:hover {
            border-color: ${(props) => props.theme.colors.gray5};
          }

          > div {
            display: none;
          }
        }
      }

      .actions {
        display: flex;
        align-items: center;
        gap: 12px;
      }
    }
  }

  > .body {
    max-height: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: auto;

    .no-results-box {
      height: 382px;
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-align: center;
      padding: 20px;
      margin-top: 10px;

      & > img {
        user-select: none;
        pointer-events: none;
      }

      .title {
        margin-bottom: 6px;
      }

      .text {
        color: ${(props) => props.theme.colors.gray7};
        font-weight: 500;
      }
    }

    .empty-state-container {
      width: 100%;
      height: 100%;
      background-color: ${(props) => props.theme.colors.gray1};
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      overflow: auto;
      animation: 1s ${fadeInAnimation};

      .empty-state-content {
        height: 100%;
        width: 100%;
        background: url(${CustomProjectsEmptyState}) no-repeat center center;
        background-size: cover;

        > .header {
          height: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          text-align: center;
          padding: 24px 36px;

          .container {
            max-width: 460px;

            .title-container {
              display: flex;
              flex-direction: column;
              align-items: center;
              gap: 4px;

              .icon {
                min-width: 24px;
                font-size: 24px;
                color: ${(props) => props.theme.colors.geekblue5};
              }

              .title {
                margin: 0px 0px 12px;

                .beta {
                  color: ${(props) => props.theme.colors.cyan6};
                  font-size: 16px;
                  letter-spacing: 1.5px;
                  text-transform: uppercase;
                  padding: 0px 3px;
                }
              }
            }

            .text {
              color: ${(props) => props.theme.colors.gray7};
              margin: 0px 0px 20px;
            }

            .link {
              color: ${(props) => props.theme.colors.gray7};
              text-decoration: underline;
            }

            > button {
              margin-top: 18px;
            }
          }
        }
      }
    }

    > .content {
      max-width: 1440px;
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      gap: 10px;
      position: relative;

      > .custom-module-box-container {
        flex: 0 calc(50% - 10px);
      }
    }

    .pagination-container {
      width: 100%;
      bottom: 0px;
      padding: 20px 0px 0px;
      z-index: 1;

      .ant-pagination {
        /* display: flex;
        justify-content: flex-end; */
      }
    }
  }

  @media ${device.xlDown} {
    > .body {
      margin: 0px;

      > .content {
        flex-direction: column;
        flex-wrap: nowrap;
        gap: 16px;

        > .custom-module-box-container {
          max-width: 100%;
          flex: 1;
          padding: 0px;
        }
      }
    }
  }
`
